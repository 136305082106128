import { Component, OnInit, Inject, ViewChild,ViewEncapsulation } from '@angular/core';
import { CompanyService } from '../../company.service';
import { Companies } from '../../companies';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalDirective } from 'angular-bootstrap-md';

import { Observable } from 'rxjs';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CompaniesComponent implements OnInit {
  @ViewChild('basicModal', { static : false }) 
  public showModalOnClick: ModalDirective;
  isLoading = true;
  company:any = new Companies();
  companies: Companies[] = [];
  searchText = '';
  iconFile: any;
  iconUrl: string;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  constructor(public snackBar: SnackbarService, public _fuseConfigService : FuseConfigService,private commpanyService: CompanyService, public dialog: MatDialog) { 
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
             toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }
  displayedColumns: string[] = ['icon', 'name', 'action'];

  ngOnInit() {
    this.getCompanies();
  }

  // viewData(company) {
  //   this.company = company;
  //   this.showModalOnClick.show();
  // }
  openDialog(): void {
    const dialogRef = this.dialog.open(CompaniesDialogComponent, {
      width: '550px',
      data: { company: this.company }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.company = result.company;
        this.commpanyService.addCompany(this.company).subscribe(company => {
          this.reset();
          this.getCompanies();
          this.isLoading = false;
        });
        // this.iconFile = result.icon;
        // const id = Math.random().toString(36).substring(2);
        // this.ref = this.afStorage.ref(id);
        // this.ref.put(this.iconFile).then((snapshot) => {
        //   this.downloadURL = this.ref.getDownloadURL();
        //   this.downloadURL.subscribe(url => {
        //     this.company.icon = url;
        //     // save to DB
        //     this.commpanyService.addCompany(this.company).subscribe(company => {
        //       this.reset();
        //       this.getCompanies();
        //       this.isLoading = false;
        //     });
        //   });
        // });
      }
    });
  }
  viewData(company): void {
    this.company = company
    const dialogRef = this.dialog.open(CompanyDetailsComponent, {
      width: '550px',
      data: company //{ company: company }
    });

    dialogRef.afterClosed().subscribe(result => {
       
    });
  }

  reset() {
    this.company = new Companies();
  }

  openToEdit(co) {
    const dialogRef = this.dialog.open(CompaniesDialogComponent, {
      width: '550px',
      data: co
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.company = result.company;
        this.commpanyService.editCompany(this.company).subscribe(company => {
          this.reset();
          this.getCompanies();
          this.isLoading = false;
        });
        // this.iconFile = result.icon;
        // const id = Math.random().toString(36).substring(2);
        // this.ref = this.afStorage.ref(id);
        // if (this.iconFile) {
        //   this.ref.put(this.iconFile).then((snapshot) => {
        //     this.downloadURL = this.ref.getDownloadURL();
        //     this.downloadURL.subscribe(url => {
        //       this.company.icon = url;
        //       // save to DB
        //       this.commpanyService.editCompany(this.company).subscribe(company => {
        //         this.reset();
        //         this.getCompanies();
        //         this.isLoading = false;
        //       });
        //     });
        //   });
        // } else {
        //   this.commpanyService.editCompany(this.company).subscribe(company => {
        //     this.reset();
        //     this.getCompanies();
        //     this.isLoading = false;
        //   });
        // }
      }
    });
  }

  getCompanies() {
    this.isLoading = true;
    this.commpanyService.getCompanies().subscribe(result => {
      this.companies = result;
      this.isLoading = false;
    });
  }

  remove(company) {
    if (confirm('Are you sure you want to delete this company ')) {
      this.isLoading = true;
      this.commpanyService.deleteCompany(company).subscribe(() => this.getCompanies());
      this.getCompanies();
      this.isLoading = false;
    }
  }

  search() {
    this.isLoading = true;
    this.commpanyService.searchCompany(this.searchText).subscribe(result => {
      this.companies = result;
      this.isLoading = false;
    });
  }

  saveCompany() {
    if (!this.company._id) {
      this.commpanyService.addCompany(this.company).subscribe(company => {
        this.reset();
        this.getCompanies();
      });
    } else {
      this.commpanyService.editCompany(this.company).subscribe(company => {
        this.reset();
        this.getCompanies();
      });
    }
  }
  keyPressed(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.search();
    }
  }

}

@Component({
  selector: 'app-companies-dialog',
  templateUrl: 'companies-Dialog.html',
  styleUrls: ['companies-Dialog.css']

})
export class CompaniesDialogComponent {

  iconFile: any;
  constructor(
    public dialogRef: MatDialogRef<CompaniesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public company: Companies,public snackBar: SnackbarService,) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.company.name) {
      return false;
    }
    // if (!this.iconFile && !this.company._id) {
    //   alert('Please choose company icon');
    //   return false;
    // }
    this.dialogRef.close({ company: this.company, icon: this.iconFile });
  }

  upload(event) {
    this.iconFile = event.target.files[0];


  }
}

// VIEW DETAILS

@Component({
  selector: 'app-company-details',
  templateUrl: 'company-details.html',
  styleUrls: ['company-details.css']

})
export class CompanyDetailsComponent {

  iconFile: any;
  constructor(
    public dialogRef: MatDialogRef<CompanyDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public company: Companies,public snackBar: SnackbarService,) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.company.name) {
      return false;
    }
    // if (!this.iconFile && !this.company._id) {
    //   alert('Please choose company icon');
    //   return false;
    // }
    this.dialogRef.close({ company: this.company, icon: this.iconFile });
  }

  upload(event) {
    this.iconFile = event.target.files[0];


  }
}
