import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { User } from '../user';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('navbarid',{static : false})
  private navbar: any;
  userName: string = '';
  isLoggedIn = false;
  userRole = '';
  iconPath: string = '../../assets/empty.png';
  loggedInUser = new User();
  version: any;
  
  constructor(private authenticationService: AuthenticationService, private router: Router, private ngZone: NgZone, private headerService: HeaderService) { }

  ngOnInit() { 
    this.headerService.change.subscribe(() => {
      this.handleHeader();
    });

    this.handleHeader();

    // this.authenticationService.check().subscribe((result) => {
    //   if (result === null) {
    //     this.isLoggedIn = false;
    //     this.userRole = '';
    //     this.iconPath = '../../assets/logo.png';
    //     this.ngZone.run(() => this.router.navigate(['login'])).then();
    //   } else {
    //     this.isLoggedIn = true;
    //     this.userService.getUserByEmail(result.email).subscribe((user) => {
    //       this.userName = user.name;
    //       this.userRole = user.role;
    //       // wait a second, then get the company logo if exists
    //       setTimeout(() => {
    //         if (localStorage.getItem('USER_COMPANY_KEY')) {
    //           this.userService.getCompanyBykey(localStorage.getItem('USER_COMPANY_KEY')).subscribe((company: Companies) => {
    //             this.iconPath = company.icon;
    //           });
    //         }
    //       }, 1000);
    //     });
    //   }
    // });
  }

  handleHeader(){
    this.navbar.hide(); 
    console.log(this.version);
      if (localStorage.getItem('LOGGED_IN_USER')) {
        this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
        this.isLoggedIn = true;
        this.userName = this.loggedInUser.name;
        this.userRole = this.loggedInUser.role;
        this.iconPath = '../../assets/logo.png';
        // setTimeout(() => {
        //   if (localStorage.getItem('USER_COMPANY_KEY')) {
        //     this.userService.getCompanyBykey(localStorage.getItem('USER_COMPANY_KEY')).subscribe((company: Companies) => {
        //       this.iconPath = company.icon;
        //     });
        //   }
        // }, 1000);
      } else {
        this.isLoggedIn = false;
        this.userRole = '';
        this.iconPath = '../../assets/logo.png';
        this.ngZone.run(() => this.router.navigate(['login'])).then();
      }
  }

  logout() {
    this.authenticationService.logout();
    this.isLoggedIn = false;
    this.userRole = '';
    this.iconPath = '../../assets/logo.png';
    this.ngZone.run(() => this.router.navigate(['login'])).then();
  }

  hideNavbar(e: any) {
    e.hide();
  }

}
