export class Survey {
  _id: string;
  rvpId : string
  salesRepId: string;
  clientInitials: string;
  clientLocation: object;
  language: string;
  long: number;
  lat: number;
  questions: any;
  createdAt: Date;
  zip: string;
}
