import { ChangeDetectionStrategy, ChangeDetectorRef,NgZone, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { HeaderService } from 'app/header/header.service';
import { User } from 'app/user';
import { PwaService } from 'app/pwa.service';

 


@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;
    activityReports:boolean=true;
    assetsSummary:boolean=false;
    availableAssets:boolean=false; 
    lifeReports:boolean=false;
    recruitReports:boolean=false;
    display:boolean=false;
    loggedInUser = new User();

    // Private
    private _unsubscribeAll: Subject<any>;
    isOnline: boolean = true;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private headerService : HeaderService,
        private pwa : PwaService,
        private ngZone : NgZone,
        private router : Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.pwa.onlineStatus.subscribe((status)=>{
            console.log('PWA NAVIGATION', status)
            this.isOnline = status;
        })
 
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
         if (localStorage.getItem('LOGGED_IN_USER')) {
            this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
         }
    }

    navigateTo(page){
        this.ngZone.run(() => this.router.navigate([page])).then();
    };

    activeSection(value){
        // if(value == 'salesreps') {
        //     this.navigateTo('salesreps');
        // } else {
        //     this.navigateTo('home');
        // } 
        this[value]=true; 
        this.headerService.activeSection.emit(value);
    };

    resetOtherSelections(value){
        for(let i in value){  this[value[i]] = false;}
    }
}
