import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service'; 
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'


@Component({
  selector: 'app-survey7',
  templateUrl: './survey7.component.html',
  styleUrls: ['./survey7.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey7Component implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.check();
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.check();
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  check() {
    if ((this.questions['employment'].selfemployed || this.questions['employment'].businessowner) && this.questions['worksponsoredplan'].answer === '1') {

    } else if ((this.questions['employmentspouse'].selfemployedspouse || this.questions['employmentspouse'].businessownerspouse) && this.questions['worksponsoredplanspouse'].answer === '1') {

    } else {
      if (!localStorage.getItem('PREV_URL') || localStorage.getItem('PREV_URL') !== 'survey8') {
        this.next();
      } else {
        this.back();
      } 
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'survey7');
    this.ngZone.run(() => this.router.navigate(['survey6'])).then();
  }

  next() {
    if ((this.questions.employment.selfemployed || this.questions.employment.businessowner) && this.questions.worksponsoredplan.answer === '1' && !this.questions.businessownerplantype.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione los planes de jubilación que utiliza para usted o sus empleados':'Please select retirement plans that you use for you or your employees';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', `Please select retirement plans that you use for you or your employees`);
    } else if ((this.questions.employmentspouse.selfemployedspouse || this.questions.employmentspouse.businessownerspouse) && this.questions.worksponsoredplanspouse.answer === '1' && !this.questions.businessownerplantypespouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione los planes de jubilación que su cónyuge utiliza para sí mismo o para sus empleados':'Please select retirement plans that your spouse use for himself/herself or his/her employees';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', `Please select retirement plans that your spouse use for himself/herself or his/her employees`);
    } else if (this.questions.employment.businessowner && this.questions.worksponsoredplan.answer === '1' && this.questions.businessownerplantype.answer === '401k' && !this.questions.planassets.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si el balance de su 401k es más de 1 millón de dólares':'Please select if your total 401k assets are over $1 Million';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', `Please select if your total 401k assets are over $1 Million`);
    } else if (this.questions.employment.businessowner && this.questions.worksponsoredplan.answer === '1' && this.questions.businessownerplantype.answer === '401k' && this.questions.planassets.answer === '1' && !this.questions.participationrate.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione si el porcentaje de participación de los empleados es menos del 50%.':'Please select if your employee participation rate is less than 50%?';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', `Please select if your employee participation rate is less than 50%?`);
    } else if (this.questions.employmentspouse.businessownerspouse && this.questions.worksponsoredplanspouse.answer === '1' && this.questions.businessownerplantypespouse.answer === '401kspouse' && !this.questions.planassetsspouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si los balance total del 401k de su cónyuge son más de $1Millón":"Please select if your spouse's total 401k assets are over $1Million";
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', `Please select if your spouse's total 401k assets are over $1Million`);
    } else if (this.questions.employmentspouse.businessownerspouse && this.questions.worksponsoredplanspouse.answer === '1' && this.questions.businessownerplantypespouse.answer === '401kspouse' && this.questions.planassetsspouse.answer === '1' && !this.questions.participationratespouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si el porcentaje de participación de su cónyuge es menos del 50%.":"Please select if your spouse's employee participation rate is less than 50%";
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', `Please select if your spouse's employee participation rate is less than 50%`);
    }
    else { 
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'survey7');

      this.ngZone.run(() => this.router.navigate(['survey8'])).then();
    }

  }
}
