import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, public AS : AuthenticationService ) { }

  getAllSurveys(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/surveys`, this.AS.addAuth());
  }

  submitSurvey(survey, url = ''): Observable<any> {
    if(url != ''){
      return this.http.post(`${this.apiUrl}/api/surveys/withcode`, survey);
    } else {
      return this.http.post(`${this.apiUrl}/api/surveys`, survey, this.AS.addAuth());
    }
  }

  getSalesRepSurveys(salesRepId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/surveys/salesrep/${salesRepId}`, this.AS.addAuth());
  }

  getSurvey(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/variables/${id}`, this.AS.addAuth());
  }

  // For RVP reports // 
   
  getRvpSurveysCount(rvpId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/surveys/rvp/${rvpId}`, this.AS.addAuth());
  }

  getRVPSurveys(rvpId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/surveys/byrvp/${rvpId}`, this.AS.addAuth());
   
  }
  getZipBasedLocation(zipcode: string,region): Observable<any> {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=`+zipcode+`&key=AIzaSyBq1ZOesFBg8m-hHQyjJdRUG8xqz-fMieU&region=`+region);
  }
 
  getRvpDialyStat(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/surveys/rvp/stats`, data, this.AS.addAuth());
  }

  getSRDialyStat(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/surveys/sr/stats`, data, this.AS.addAuth());
  }

  getUsagesReports(data) : Observable<any> {
    return this.http.post(`${this.apiUrl}/api/surveys/usagereport`, data, this.AS.addAuth());
  }


   
   
}
