import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Variable } from '../../variable';
import { VariablesService } from '../../variables.service';



// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class VariablesComponent implements OnInit {

  variable = new Variable();
  variables: Variable[] = [];
  searchText = '';
  isLoading = true;

  constructor(public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService,public dialog: MatDialog, private variablesService: VariablesService) {
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
   }

  displayedColumns: string[] = ['name', 'description', 'value', 'action'];

  ngOnInit() {
    this.getVariables();
  }
  openDialog(): void {
    this.variable = new Variable();
    const dialogRef = this.dialog.open(VariablesDialogComponent, {
      width: '550px',
      data: { variable: this.variable, variables: this.variables }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.variable = result;
      if (this.variable) {
        this.isLoading = true;
        this.variablesService.addVariable(this.variable).subscribe(u => {
          this.isLoading = false;
          this.reset();
          this.getVariables();
        });
      }
    });
  }

  addallVariables(){
   var all = [
     {"_id":"5cc4f804f182f822d8d30f78","name":"401k_o50_amt","description":"401K - over 50","value":25000,"createdAt":"2019-04-28T00:47:00.023Z"},
     {"_id":"5cc4f7eef182f822d8d30f77","name":"401k_u50_amt","description":"401K - under 50","value":19000,"createdAt":"2019-04-28T00:46:38.124Z"},
     {"_id":"5cc4fa46f182f822d8d30f88","name":"agi_high_amt","description":"household Adjusted Gross Income (AGI) - High","value":203000,"createdAt":"2019-04-28T00:56:38.412Z"},
     {"_id":"5cc4fa2ff182f822d8d30f87","name":"agi_low_amt","description":"Household Adjusted Gross Income (AGI) - Low","value":193000,"createdAt":"2019-04-28T00:56:15.266Z"},
     {"_id":"5cc4f8cbf182f822d8d30f7e","name":"cov_mar_high_amt","description":"Coverdell Married Filing Jointly - High","value":220000,"createdAt":"2019-04-28T00:50:19.228Z"},
     {"_id":"5cc4f870f182f822d8d30f7d","name":"cov_mar_low_amt","description":"Coverdell Married Filing Jointly - Low","value":190000,"createdAt":"2019-04-28T00:48:48.306Z"},
     {"_id":"5cc4f856f182f822d8d30f7c","name":"cov_sing_high_amt","description":"Coverdell Single Filer - High","value":110000,"createdAt":"2019-04-28T00:48:22.974Z"},
     {"_id":"5cc4f841f182f822d8d30f7b","name":"cov_sing_low_amt","description":"Coverdell Single Filer - Low","value":95000,"createdAt":"2019-04-28T00:48:01.787Z"},
     {"_id":"5cc4f818f182f822d8d30f79","name":"covd_amt","description":"Coverdell","value":2000,"createdAt":"2019-04-28T00:47:20.393Z"},
     {"_id":"5cc4f82ef182f822d8d30f7a","name":"gift_lim_amt","description":"Gifting limits","value":15000,"createdAt":"2019-04-28T00:47:42.502Z"},
     {"_id":"5cc4f759f182f822d8d30f70","name":"rira_o50_amt","description":"Roth IRA - over 50","value":7000,"createdAt":"2019-04-28T00:44:09.846Z"},
     {"_id":"5cc4f749f182f822d8d30f6f","name":"rira_u50_amt","description":"Roth IRA - under 50","value":6000,"createdAt":"2019-04-28T00:43:53.041Z"},
     {"_id":null,"name":"roth_mar_jnt_low_amt","description":"Roth Married Filing Jointly phase out - Low","value":193000,"createdAt":"2019-04-28T00:51:57.239Z"},
     {"_id":null,"name":"roth_sing_hoh_low_amt","description":"Roth single filer / Head of Household - Low","value":122000,"createdAt":"2019-04-28T00:51:13.107Z"},
     {"_id":null,"name":"sepira_amt","description":"SEP IRA - Cash","value":56000,"createdAt":"2019-04-28T00:45:11.388Z"},
     {"_id":null,"name":"sepira_perc","description":"SEP IRA - Percentage","value":25,"createdAt":"2019-04-28T00:45:34.597Z"},
     {"_id":null,"name":"sira_o50_amt","description":"SIMPLE IRA - over 50","value":16000,"createdAt":"2019-04-28T00:46:11.862Z"},
     {"_id":null,"name":"sira_u50_amt","description":"SIMPLE IRA - under 50","value":13000,"createdAt":"2019-04-28T00:45:53.796Z"},
     {"_id":null,"name":"tira_mar_high_amt","description":"Married Filing Jointly phase out - High","value":123000,"createdAt":"2019-04-28T00:55:32.735Z"},
     {"_id":null,"name":"tira_mar_low_amt","description":"Married Filing Jointly phase out - Low","value":103000,"createdAt":"2019-04-28T00:55:01.463Z"},
     {"_id":null,"name":"tira_o50_amt","description":"Traditional IRA - over 50","value":7000,"createdAt":"2019-04-28T00:44:51.087Z"},
     {"_id": null,"name":"tira_sing_hoh_high_amt","description":"Single filer / Head of Household - High","value":74000,"createdAt":"2019-04-28T00:52:59.744Z"},
     {"_id": null,"name":"tira_sing_hoh_low_amt","description":"Single filer / Head of Household - Low","value":64000,"createdAt":"2019-04-28T00:52:41.009Z"},
     {"_id": null,"name":"tira_u50_amt","description":"Traditional IRA - under 50","value":6000,"createdAt":"2019-04-28T00:44:26.591Z"}
    ]

    for(let i in all){
      all[i]._id = null; 
        this.variablesService.addVariable(all[i]).subscribe((res)=>{

        }) 
    }
  }

  openToEdit(variable: Variable) {
    const dialogRef = this.dialog.open(VariablesDialogComponent, {
      width: '550px',
      data: variable
    });

    dialogRef.afterClosed().subscribe(result => {
      this.variable = result;
      if (this.variable) {
        this.isLoading = true;
        this.variablesService.editVariable(this.variable).subscribe(c => {
          this.reset();
          this.getVariables();
          this.isLoading = false;
        });
      }
    });
  }

  getVariables() {
    this.isLoading = true;
    this.variablesService.getVariables().subscribe(result => {
      this.variables = result;
      this.isLoading = false;
    });
  }

  remove(variable: Variable) {
    if (confirm('Are you sure you want to delete this variable ')) {
      this.isLoading = true;
      this.variablesService.deleteVariable(variable).subscribe((res) => {
        this.getVariables();
      });
    }
  }

  reset() {
    this.variable = new Variable();
  }

  search() {
    this.isLoading = true;
    this.variablesService.searchVariable(this.searchText).subscribe(result => {
      this.variables = result;
      this.isLoading = false;
    });
  }

  saveVariable() {
    if (!this.variable.name) {
      alert('Please enter tax variable name');
      return false;
    }
    if (!this.variable.description) {
      alert('Please enter tax variable description');
      return false;
    }
    if (!this.variable.value) {
      alert('Please enter tx variable value');
      return false;
    }
    if (!this.variable._id) {
      this.variablesService.addVariable(this.variable).subscribe(variable => {
        this.reset();
        this.getVariables();
      });
    } else {
      if (this.variable) {
        this.variablesService.editVariable(this.variable).subscribe(variable => {
          this.reset();
          this.getVariables();
        });
      }

    }
  }

}

@Component({
  selector: 'app-variables-dialog',
  templateUrl: 'variables-Dialog.html',
})
export class VariablesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<VariablesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public variable: Variable) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.variable.name) {
      alert('Please enter variable name');
      return false;
    }
    if (!this.variable.description) {
      alert('Please enter variable description');
      return false;
    }
    if (!this.variable.value) {
      alert('Please enter variable number');
      return false;
    }
    this.dialogRef.close(this.variable);
  }

}