import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { UserService } from '../../user.service';
import { CompanyService } from '../../company.service';
import { User } from '../../user';
import { Companies } from '../../companies';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface Role {
  value: string;
  viewValue: string;
}

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'

@Component({
  selector: 'app-user',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UsersComponent implements OnInit {
  isLoading = true;
  companies: Companies[] = [];
  user = new User();
  users: User[] = [];
  searchText = '';
  testUsersList: any[];
  treeView:any=0;
  constructor(public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService, private userService: UserService, public dialog: MatDialog, private companyService: CompanyService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
   }
  displayedColumns: string[] = ['image','name', 'role', 'email', 'companyKey', 'action'];

  ngOnInit() {
    this.getCompanies();
  }

  openDialog(): void {
    this.user = new User();

    // if(this.searchText != undefined && this.searchText !=''){
    //   this.user.name=this.searchText;
    // }

    const dialogRef = this.dialog.open(UsersDialogComponent, {
      width: '550px',
      data: { user: this.user, users: this.users, companies: this.companies, companyRVPs: [] }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.user = result;
      if (this.user) {
        this.isLoading = true;
        this.userService.addUser(this.user).subscribe(u => {
          this.reset();
          this.getUsers();
          this.isLoading = false;
        },(e)=>{
          this.isLoading = false;
          if(e.error){ 
            this.snackBar.openSnackBar(e.error.message, '','error');
          } 
        });
      }
0    });
 0 }

  reset() {
    this.user = new User();
  }

  openToEdit(u) {
    const dialogRef = this.dialog.open(UsersDialogComponent, {
      width: '550px',
      data: {
        user: u, users: this.users, companies: this.companies,
        companyRVPs: this.getCompanyRVP(u.companyKey),
        companyCAs : this.getCompanyCA(u.companyKey)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.user = result;
      if (this.user) {
        this.isLoading = true;
        this.user.password = undefined;
        this.userService.editUser(this.user).subscribe(c => {
          this.user.password = c.password;
          this.reset();
          this.getUsers();
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          this.snackBar.openSnackBar("Code is already used.", 'Okay', 'error');
          this.reset();
          this.getUsers();
        });
      }
    });
  }

  openToResetPassword(u) {
    let userToEdit = u;
    userToEdit.password = undefined;
    const dialogRef = this.dialog.open(ResetDialogComponent, {
      width: '550px',
      data: {
        user: userToEdit
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.user = result;
      if (this.user) {
        this.isLoading = true;
        this.userService.editUser(this.user).subscribe(c => {
          this.user.password = c.password;
          this.reset();
          this.getUsers();
          this.isLoading = false;
        });
      }
    });
  }

  getCompanies() {
    this.isLoading = true;
    this.companyService.getCompanies().subscribe(result => {
      this.companies = result;
      this.getUsers();
    });
  }

  getCompanyByKey(key: string) {
    for (let i = 0; i < this.companies.length; i++) {
      if (key === this.companies[i]._id) {
        return this.companies[i].name;
      }
    }
  }

  getUsers() {
    this.isLoading = true;
    this.userService.getUsers().subscribe(result => {
      this.users = result;
      this.isLoading = false;
    });

    // new Tree View Users
    this.userService.allUsers().subscribe(results => { 
      var source = [];
      for (let i in results) {
        if (results[i]._id) {
          var index = source.findIndex(x => x.companyInfo._id == results[i].companyInfo._id);
          if (index == -1) {
            source.push({
              companyInfo: results[i].companyInfo,
              ca: JSON.parse(JSON.stringify([{ 
                caInfo : results[i].caInfo,
                rvp : results[i].rvpInfo
              }]))
            });
          } else {
            // source.push({
            //   companyInfo: results[i].companyInfo,
            //   ca: JSON.parse(JSON.stringify([{ 
            //     caInfo : results[i].caInfo,
            //     rvp : results[i].rvpInfo
            //   }]))
            // });
            source[index].ca.push({ 
              caInfo : results[i].caInfo,
              rvp : results[i].rvpInfo
            })
          }
        }
      }

      this.testUsersList = source; 
    });
    }

  remove(user) {
    if (confirm('Are you sure you want to delete this user?')) {
      this.isLoading = true;
      this.userService.deleteUser(user).subscribe(() => {
        this.getUsers();
        this.snackBar.openSnackBar("User has been deleted successfully!", 'Okay','success')
      });
      this.getUsers();
      this.isLoading = false;
    }
  }

  search() {
    this.isLoading = true;
    this.userService.searchUser(this.searchText).subscribe(result => {
      this.users = result;
      this.isLoading = false;
    });
  }

  saveUser() {
    if (!this.user._id) {
      this.userService.addUser(this.user).subscribe(u => {
        this.reset();
        this.getUsers();
      },(e)=>{
        // alert('Oops! We have encountered an issue. Please try again later.')
        this.snackBar.openSnackBar("Oops! We have encountered an issue. Please try again later.", 'Okay', 'error');
        this.isLoading = false;
      });
    } else {
      this.userService.editUser(this.user).subscribe(u => {
        this.reset();
        this.getUsers();
      });
    }
  }

  keyPressed(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.search();
    }
  }

  getCompanyRVP(companyKey: string) {
    const companyRvps = [];
    this.users.forEach((u) => {
      if (u.companyKey === companyKey && u.role === 'RVP') {
        companyRvps.push(u);
      }
    });
    return companyRvps;
  }

  getCompanyCA(companyKey: string) {
    const companyCAs = []
    this.users.forEach((u) => { 
      if(u.companyKey === companyKey && u.role === 'CA'){
         companyCAs.push(u)
      }
    });
    return companyCAs;
  }
  
  

}

@Component({
  selector: 'app-user-dialog',
  templateUrl: 'user-dialog.html',
  styleUrls: ['user-dialog.css']

})
export class UsersDialogComponent implements OnInit {
  companyRvps: User[] = [];
  companyCAs: User[] = []
  roles: Role[] = [
    { value: 'SA', viewValue: 'Super Admin (SA)' },
    { value: 'CA', viewValue: 'Company Admin (CA)' },
    { value: 'RVP', viewValue: 'Regional Vice President (RVP)' },
    { value: 'SR', viewValue: 'Sales Rep (SR)' }
  ];
  ngOnInit() {

  }
  constructor(
    public dialogRef: MatDialogRef<UsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public snackBar: SnackbarService,) {
    this.companyRvps = data.companyRVPs;
    this.companyCAs = data.companyCAs
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

  validate() {
    if (!this.data.user.name) {
      return false;
    }
    if (!this.data.user.email) {
      return false;
    }
    if (!this.data.user.password) {
      return false;
    }
    if (!this.data.user.role) {
      return false;
    }
    if (this.data.user.role !== 'SA' && !this.data.user.companyKey) {
      this.snackBar.openSnackBar('Please select company', 'Close', 'error')  
      return false;
    }
    if (this.data.user.role === 'SR' && !this.data.user.rvp) { 
      this.snackBar.openSnackBar('Please select RVP', 'Close', 'error')  
      return false;
    }
    if (this.data.user.role === 'RVP' && !this.data.user.ca) { 
      this.snackBar.openSnackBar('Please select CA', 'Close', 'error')  
      return false;
    }
    if(this.data.user.role !== 'RVP'){
       delete this.data.user.ca
    }
    if(this.data.user.role !== 'SR'){
      delete this.data.user.rvp
   }

    this.dialogRef.close(this.data.user);
  }

  getCompanyRVP() {
    
    //reset selected values 
    this.companyRvps = [];
    this.companyCAs = []
    this.data.user.rvp = '';
    this.data.user.ca = '';

    this.data.users.forEach((u) => {
      if (u.companyKey === this.data.user.companyKey && u.role === 'RVP') {
        this.companyRvps.push(u);
      }
      if(u.companyKey === this.data.user.companyKey && u.role === 'CA'){
        this.companyCAs.push(u)
      }
    });
  }
}

@Component({
  selector: 'app-reset-dialog',
  templateUrl: 'reset-dialog.html',
  styleUrls: ['reset-dialog.css']

})
export class ResetDialogComponent implements OnInit {

  ngOnInit() {

  }
  constructor(
    public dialogRef: MatDialogRef<UsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public snackBar : SnackbarService) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  validate() {
    if (!this.data.user.password) { 
      this.snackBar.openSnackBar('Please enter new password', 'Close', 'error');  
      return false;
    }
    if (!this.data.user.confirm) { 
      this.snackBar.openSnackBar('Please confirm new password', 'Close', 'error')  
      return false;
    }
    if (this.data.user.password !== this.data.user.confirm) { 
      this.snackBar.openSnackBar("Password and confirm password don't match", 'Close', 'error')  
      return false;
    }
    this.dialogRef.close(this.data.user);
  }
}
