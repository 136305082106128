import * as JsStore from 'jsstore';
import * as workerPath from 'file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js';
import { IDataBase, DATA_TYPE, ITable } from 'jsstore';

// This will ensure that we are using only one instance. 
// Otherwise due to multiple instance multiple worker will be created.
export const idbCon = new JsStore.Instance(new Worker(workerPath));
export const dbname = 'offlineSurveys';


const getDatabase = () => {
  const tblStudent: ITable = {
    name: 'Surveys',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true
      }, 
      salesRepId: { 
        dataType: DATA_TYPE.String
      },
      rvpId: { 
        dataType: DATA_TYPE.String
      }, 
      clientInitials: { 
        dataType: DATA_TYPE.String
      }, 
      clientLocation: { 
        dataType: DATA_TYPE.Object
      },  
      language: { 
        dataType: DATA_TYPE.String
      }, 
      long: { 
        dataType: DATA_TYPE.Number
      },
      lat: { 
        dataType: DATA_TYPE.Number
      },  
      questions: { 
        dataType: DATA_TYPE.Object
      },
      createdAt: { 
        dataType: DATA_TYPE.DateTime
      }, 
      zip: { 
        dataType: DATA_TYPE.String
      }
    //   gender: {
    //     dataType: DATA_TYPE.String,
    //     default: 'male'
    //   },
    //   country: {
    //     notNull: true,
    //     dataType: DATA_TYPE.String
    //   },
    //   city: {
    //     dataType: DATA_TYPE.String,
    //     notNull: true
    //   }
    }
  };
  const dataBase: IDataBase = {
    name: dbname,
    tables: [tblStudent]
  };
  return dataBase;
};

export const initJsStore = () => {
    console.log('initialising db connection ...')
  const dataBase = getDatabase();
  idbCon.initDb(dataBase);
};