import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Question } from '../../questions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../user';
import { Router } from '@angular/router';
import { CompanyService } from '../../company.service';
import { Companies } from '../../companies';


// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'


@Component({
  selector: 'app-company-questions',
  templateUrl: './company-questions.component.html',
  styleUrls: ['./company-questions.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CompanyQuestionsComponent implements OnInit {
  isLoading = true;
  question = new Question();
  questions: Question[] = [];
  searchText = '';
  constructor(public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService,private questionService: QuestionService, private companyService: CompanyService, public dialog: MatDialog, private router: Router) { 
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
          toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
    
  }
  displayedColumns: string[] = ['name', 'englishText', 'spainText', 'action'];
  loggedInUser = new User();
  company = new Companies();

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
    if (this.loggedInUser.companyKey) {
      this.companyService.getCompany(this.loggedInUser.companyKey).subscribe((comp) => {
        this.company = comp;
        this.getQuestions();
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  reset() {
    this.question = new Question();
  }

  openToEdit(q) {
    const dialogRef = this.dialog.open(CompanyQuestionsDialogComponent, {
      width: '550px',
      data: q
    });

    dialogRef.afterClosed().subscribe(result => {
      this.question = result;
      if (this.question) {
        this.isLoading = true;
        let found = false;
        if (this.company.questions && this.company.questions.length > 0) {
          for (let i = 0; i < this.company.questions.length; i++) {
            if (this.company.questions[i] && this.company.questions[i]['name'] === this.question.name) {
              found = true;
            }
          }
        }
        if (found) {
          this.companyService.editCompanyQuestion(this.question, this.loggedInUser.companyKey).subscribe(c => {
            this.reset();
            this.getQuestions();
            this.isLoading = false;
          });
        } else {
          this.company.questions.push({
            name: this.question.name,
            englishText: this.question.englishText,
            spainText: this.question.spainText
          });
          this.companyService.addCompanyQuestion(this.question, this.loggedInUser.companyKey).subscribe(c => {
            this.reset();
            this.getQuestions();
            this.isLoading = false;
          });
        }
      }
    });
  }

  getQuestions() {
    this.isLoading = true;
    this.questionService.getCompanyQuestions(this.loggedInUser.companyKey).subscribe(result => {
      this.questions = result;
      this.isLoading = false;
    });
  }

  search() {
    this.isLoading = true;
    this.questionService.searchCompanyQuestion(this.searchText, this.loggedInUser.companyKey).subscribe(result => {
      this.questions = result;
      this.isLoading = false;
    });
  }

  keyPressed(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.search();
    }
  }
}

@Component({
  selector: 'app-company-question-dialog',
  templateUrl: 'companyQuestions-dialog.html',
  styleUrls: ['companyQuestions-dialog.css']

})
export class CompanyQuestionsDialogComponent implements OnInit {
  ngOnInit() {

  }
  constructor(
    public dialogRef: MatDialogRef<CompanyQuestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public question: Question) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.question.name) {
      return false;
    }
    if (!this.question.englishText) {
      return false;
    }
    if (!this.question.spainText) {
      return false;
    }
    this.dialogRef.close(this.question);
  }
}

