export class Companies {
  _id: string;
  name: string;
  website?: string;
  contactPerson?: string;
  contactEmail?: string;
  contactPhone?: string;
  icon: string;
  color: string;
  questions: any[];
  cvariables: any[];
}
