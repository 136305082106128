import { Component, OnDestroy, Inject,OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, elementAt } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
// import { navigation } from 'app/navigation/navigation';

//using project's navigation
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication.service';
import { User } from '../../../user';
import { HeaderService } from '../../../header/header.service';
import { SnackbarService } from 'app/snackbar.service'; 
import { environment } from 'environments/environment'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { VersionCheckService } from '../../../version-check.service'
@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    //project
    userName: string = '';
    isLoggedIn = false;
    userRole = '';
    iconPath: string = '../../assets/empty.png';
    loggedInUser = new User();
	surveyMode:any = false;
	remoteSurveyMode: any = false;
	isRemotePage : any = false;
    surveyOption:any = false;
    version:any = '1.0.0';

    //end

    // Private
    private _unsubscribeAll: Subject<any>;
  isHidden: any=true;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        //project
        private authenticationService: AuthenticationService, 
        private router: Router, private ngZone: NgZone, 
        private headerService: HeaderService,
        public dialog: MatDialog,
        private _matDialog : MatDialog,
        private _version : VersionCheckService
    )
    {

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];
       
        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'sp',
                title: 'Spanish',
                flag : 'es'
            }
        ];

        // this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    checkUpdate() {
      this._version.checkVersion2(); 
    }

	checkPage() {
		if(window.location.pathname=='/remote') {
			this.isRemotePage = true;
		} else {
			this.isRemotePage = false;
		}
	}

    /**
     * On init
     */
    ngOnInit(): void
    {	
		this.checkPage();

    this.selectedLanguage = localStorage.getItem('LANG');
		this.router.events.subscribe(x=>{
			this.checkPage();
		})
    	  this.version = environment.version 
        // // Subscribe to the config changes
        // this._fuseConfigService.config
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((settings) => {
        //         this.horizontalNavbar = settings.layout.navbar.position === 'top';
        //         this.rightNavbar = settings.layout.navbar.position === 'right';
        //         this.hiddenNavbar = settings.layout.navbar.hidden === true;
        //     });

        // Set the selected language from default languages
        
        //this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
        
        if(localStorage.getItem('SURVEY_MODE')){  
          this.surveyMode = JSON.parse(localStorage.getItem('SURVEY_MODE')); 
        } 
		this.remoteSurveyMode = JSON.parse(localStorage.getItem('REMOTE_SURVEY')); 
        this.headerService.change.subscribe((surveyMode) => {  
          // console.log('called change',surveyMode)
            this.surveyMode = surveyMode;  
            this.surveyOption = {
              newSurvey : surveyMode, //false
              continueSurvey : surveyMode //false
            };
            localStorage.setItem('SURVEY_MODE', JSON.stringify(this.surveyMode))
            this.handleHeader();
		  });
		  
		  this.headerService.remote.subscribe((surveyMode) => {  
			// console.log('called change',surveyMode)
			  this.remoteSurveyMode = surveyMode;  
			  localStorage.setItem('REMOTE_SURVEY', JSON.stringify(surveyMode))
			  this.handleHeader();
		});
      
          this.handleHeader();
          
        this.headerService.reporting.subscribe((data) => { 
          this.surveyOption.newSurvey = data;
          // console.log('called new',this.surveyOption)
        });

        this.headerService.continueSurvey.subscribe((data)=>{
          // console.log('called continue',this.surveyOption)
          this.surveyOption.continueSurvey = data;
        })
        
          // this.authenticationService.check().subscribe((result) => {
          //   if (result === null) {
          //     this.isLoggedIn = false;
          //     this.userRole = '';
          //     this.iconPath = '../../assets/logo.png';
          //     this.ngZone.run(() => this.router.navigate(['login'])).then();
          //   } else {
          //     this.isLoggedIn = true;
          //     this.userService.getUserByEmail(result.email).subscribe((user) => {
          //       this.userName = user.name;
          //       this.userRole = user.role;
          //       // wait a second, then get the company logo if exists
          //       setTimeout(() => {
          //         if (localStorage.getItem('USER_COMPANY_KEY')) {
          //           this.userService.getCompanyBykey(localStorage.getItem('USER_COMPANY_KEY')).subscribe((company: Companies) => {
          //             this.iconPath = company.icon;
          //           });
          //         }
          //       }, 1000);
          //     });
          //   }
          // });
          this.headerService.gearBox.subscribe((isHidden)=>{
              this.isHidden = isHidden;
          })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

       

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }


  enterSurveyMode(continueSurvey){ 
    this.headerService.handleSurveys(continueSurvey); 
  };

  handleHeader(){
    if(window.location.pathname=='/remote') {
      this.iconPath = '../../assets/logo.png';
    } else {
		if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
			this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
			this.isLoggedIn = true;
			this.userName = this.loggedInUser.name;
			this.userRole = this.loggedInUser.role;
			this.iconPath = '../../assets/logo.png';
		} else {
			this.isLoggedIn = false;
			this.userRole = '';
			this.iconPath = '../../assets/logo.png';
			this.ngZone.run(() => this.router.navigate(['login'])).then();
		}
    }
  }

  logout() {
    this.headerService.bustCache.emit();
    this.authenticationService.logout();
    this.isLoggedIn = false;
    this.userRole = '';
    this.iconPath = '../../assets/logo.png';
    this.ngZone.run(() => this.router.navigate(['login'])).then();
  }

  navigateTo(page){
    this.ngZone.run(() => this.router.navigate([page])).then();
  }

  	exitSurvey(): void { 
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '550px',
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result == true){
				this.surveyMode = false;
				localStorage.setItem('SURVEY_MODE', 'false');
				this.headerService.surveyMode(false);
				this.navigateTo('home');
			}
		});

	}  
	remoteSurvey() {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    if(localStorage.getItem('REMOTE_USER') != null) {
      this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to exit the survey? <br/>Your results will be lost.`;
    } else {
      this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to exit the survey?`;
    }
    this.confirmDialogRef.componentInstance.confirmButtonText = 'Yes';
    this.confirmDialogRef.componentInstance.confirmCancelButton = 'No';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.surveyMode = false;
        localStorage.setItem('SURVEY_MODE', 'false');
        this.headerService.surveyMode(false);
        this.headerService.remoteSurvey(false);
        localStorage.setItem('REMOTE_SURVEY','false');
        this.remoteSurveyMode = false;
        window.location.href = 'http://www.google.com';
        this.handleHeader();
      }
    });
	}
}


@Component({
    selector: 'survey-exit-dialog',
    templateUrl: 'confirmation-dialog.html',
    styleUrls: ['confirmation-dialog.css']
  
  })
  export class ConfirmationDialogComponent implements OnInit {
    
    selectedLanguage = '';
    constructor(
      public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public user: User, public snacker : SnackbarService) { }

      ngOnInit() {
        setTimeout(()=>{
          var el = <any>document.getElementById('otpInput').firstElementChild;
          if(el){
            <HTMLInputElement>el.firstElementChild.focus()
            el.firstElementChild.setSelectionRange(0,999)
          } 
        },500)

        this.selectedLanguage = localStorage.getItem('LANG');
        
      }
  
    // pincode:any;
    loggedInUser = new User();
    onNoClick(): void {
      this.dialogRef.close();
    }
    validate(pin) {
      
       if(pin.length < 4){
         return false
       }
        this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')) 
        // if(!this.pincode){
        //     this.snacker.openSnackBar('Please enter your Pincode to exit.', '', 'error');
        //     return false
        // }
        if(pin != this.loggedInUser.pincode){

            var _msgres= this.selectedLanguage=='SP'?'El código pin no coincide!':'Pincode does not match!';
            this.snacker.openSnackBar(_msgres, '', 'error');
            return false
        }
      this.dialogRef.close(true);
    }
  }
