import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey16',
  templateUrl: './survey16.component.html',
  styleUrls: ['./survey16.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey16Component implements OnInit {

  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
     layout: {
       navbar: {
         hidden: true
       },
       toolbar: {
         hidden: false, position : 'above'
       },
       footer: {
         hidden: true
       },
       sidepanel: {
         hidden: true
       }
     }
   }
  }
  
  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'debt');
    this.ngZone.run(() => this.router.navigate(['investments-cs'])).then();
  }

  next() {
    if (!this.questions.debt.mortgage && !this.questions.debt.creditcard1 && !this.questions.debt.creditcard2 && !this.questions.debt.creditcard3
      && !this.questions.debt.carloan1 && !this.questions.debt.carloan2 && !this.questions.debt.studentloan1 && !this.questions.debt.studentloan2
      && !this.questions.debt.departmentstore1 && !this.questions.debt.departmentstore2 && !this.questions.debt.other && !this.questions.debt.none) {
        var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione las deudas del hogar que tiene":"Please select household debts you have";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'debt');
      this.ngZone.run(() => this.router.navigate(['taxrefund'])).then();
    }

  }

}
