import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-life-security',
  templateUrl: './life-security.component.html',
  styleUrls: ['./life-security.component.scss']
})
export class LifeSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
