import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey14',
  templateUrl: './survey14.component.html',
  styleUrls: ['./survey14.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey14Component implements OnInit {

  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = '';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
     layout: {
       navbar: {
         hidden: true
       },
       toolbar: {
         hidden: false, position : 'above'
       },
       footer: {
         hidden: true
       },
       sidepanel: {
         hidden: true
       }
     }
   }
  }
  
  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.check();
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.check();
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  check() {
    if (this.questions['investments'].mutualfunds) {

    } else if (this.questions['investmentsspouse'].mutualfundscdspouse) {

    } else {
      if (!localStorage.getItem('PREV_URL') || localStorage.getItem('PREV_URL') === 'investments-cd') {
        this.next();
      } else {
        this.back();
      }
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'investments-mf');
    this.ngZone.run(() => this.router.navigate(['investments-cd'])).then();
  }

  next() {
    if (this.questions.investments.mutualfunds && !(this.questions.mutualfundbalance.answer)) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Indique el balance aproximado de sus fondos de inversién":"Please enter the approximate balance of your Mutual Funds";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (this.questions.investmentsspouse.mutualfundscdspouse && !(this.questions.mutualfundbalancespouse.answer)) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Indique el balance aproximado de los fondos de inversién de su cényuge":"Please enter the approximate balance of your spouse's Mutual Funds";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'investments-mf');
      this.ngZone.run(() => this.router.navigate(['investments-cs'])).then();
    }

  }

}
