import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Survey } from '../survey';
import { SurveyService } from 'app/survey.service';
import { PwaService } from 'app/pwa.service';

@Injectable({
  providedIn: 'root'
})
export class OfflinesurveyService extends BaseService {

  constructor(private surveyService : SurveyService, private pwaService : PwaService) { 
    super();
    this.pwaService.onlineStatus.subscribe((status)=>{
        if(status == true){
          // User is back Online, check for any Offline surveys to move them to remote DB
          this.checkLocalDbCollection()
        }
    })
  }

  getSurveys() {
    return this.connection.select<Survey>({
      from: 'Surveys'
    });
  }

  addSurveys(survey: Survey) {
    return this.connection.insert<Survey>({
      into: 'Surveys',
      return: true, // as id is autoincrement, so we would like to get the inserted value
      values: [survey]
    });
  }

  deleteSurvey(surveyId: number) {
    return this.connection.remove({
      from: 'Surveys',
      where: {
        id: surveyId
      }
    });
  }

  clearSurveyTable(){
    return this.connection.clear('Surveys');
  }

  updateSurveys(surveyId: number, updateValue: Survey) {
    return this.connection.update({
      in: 'Surveys',
      where: {
        id: surveyId
      },
      set: updateValue
    });
  }

  getSurvey(surveyId: number) {
    return this.connection.select<Survey>({
      from: 'Surveys',
      where: {
        id: surveyId
      }
    });
  }


  async checkLocalDbCollection(){ 
    try {
       const allSurveys = await this.getSurveys() as Survey[];
       console.log('All Surveys => ',allSurveys)  
       if(allSurveys.length > 0){
         // Save to Remote DB
         console.log('Save to Remote DB');
        this.surveyService.submitSurvey(allSurveys).subscribe((res) => {
             // Remove local collection
             const cleanSurveys = this.clearSurveyTable(); 
         })
       } 
     }
     catch (error) {
       console.error(error);
     }
 }



}