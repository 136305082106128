import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cvariable } from './cvariables';
import { HttpClient } from '@angular/common/http';
import { CompanyService } from './company.service';
import { environment } from '../environments/environment'; 
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class CvariablesService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private companyService: CompanyService, public AS : AuthenticationService ) { }

  getCvariables(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/cvariables`, this.AS.addAuth());
  }
 
  getCvariableswithcode(code): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/cvariables/withcode`, {
      code : code
    });
  }

  searchCvariables(searchText): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/cvariables?name=${searchText}`, this.AS.addAuth());
  }

  addCvariable(cvariables: Cvariable): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/cvariables`, cvariables, this.AS.addAuth());
  }

  editCvariable(cvariable: Cvariable): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/cvariables/${cvariable._id}`, cvariable, this.AS.addAuth());
  }

  deleteCvariable(cvariable: Cvariable): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/cvariable/${cvariable._id}`, this.AS.addAuth());
  }

  getCompanyCVariables(companyId: string): Observable<any > {
    return new Observable((observer) => {
      this.companyService.getCompany(companyId).subscribe((company) => {
        this.getCvariables().subscribe((cvariables) => {
          for (let x = 0; x < cvariables.length; x++) {

            if(company != null && company.cvariables != null){
              for (let i = 0; i < company.cvariables.length; i++) {
                const companyCVariable = company.cvariables[i];
                 if (companyCVariable.name === cvariables[x].name) {
                    cvariables[x].value = companyCVariable.value;
                 }
              }
            }


          }
          observer.next(cvariables);
        });
      });
    });
  }

  getCompanyCVariablesWithCode(companyId: string, code : string): Observable<any > {
    return new Observable((observer) => {
      this.companyService.getCompanywithCode(companyId, code).subscribe((company) => {
        this.getCvariableswithcode(code).subscribe((cvariables) => {
          for (let x = 0; x < cvariables.length; x++) {
            for (let i = 0; i < company.cvariables.length; i++) {
              const companyCVariable = company.cvariables[i];
              if (companyCVariable.name === cvariables[x].name) {
                cvariables[x].value = companyCVariable.value;
              }
            }
          }
          observer.next(cvariables);
        });
      });
    });
  }

  searchCompanyCVariables(searchText: string, companyId: string): Observable<any> {
    return new Observable((observer) => {
      this.companyService.getCompany(companyId).subscribe((company) => {
        this.searchCvariables(searchText).subscribe((cvariables) => {
          for (let x = 0; x < cvariables.length; x++) {
            for (let i = 0; i < company.cvariables.length; i++) {
              const companyCVariable = company.cvariables[i];
              if (companyCVariable.name === cvariables[x].name) {
                cvariables[x].value = companyCVariable.value;
              }
            }
          }
          observer.next(cvariables);
        });
      });
    });
  }

}
