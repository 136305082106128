import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey8',
  templateUrl: './survey8.component.html',
  styleUrls: ['./survey8.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey8Component implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser = new User();

  constructor(public params: ParamService, public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService, private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: false, position: 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
  }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();

      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if (this.questions.length == 0) {
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        } else {
          this.check();
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
          this.check();
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  check() {
    if ((this.questions.employment.employee && (this.questions.worksponsoredplan.answer === '1')) || (this.questions.employmentspouse.employeespouse && this.questions.worksponsoredplanspouse.answer === '1')) {
     
    } else {
      if (localStorage.getItem('PREV_URL') === 'contribution') {
        this.back();
      } else { 
        this.next(1);
      }
    } 
  }

  back() {
    localStorage.setItem('PREV_URL', 'survey8');
    this.ngZone.run(() => this.router.navigate(['survey7'])).then();
    // survey7
  }

  next(bit) {
    if(bit==1) {
        localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
        localStorage.setItem('PREV_URL', 'survey8'); 
        this.ngZone.run(() => this.router.navigate(['contribution'])).then();
    }

    // return false
    // if (!this.questions.lifeinsurance.term && !this.questions.lifeinsurance.group && !this.questions.lifeinsurance.cashvalue && !this.questions.lifeinsurance.none) {
    //   this.snackBar.openSnackBar(`Please select type of life insurance you have`, '', 'error');
    // } 
    // else if (
    //   (this.questions.lifeinsurance.term || this.questions.lifeinsurance.group || this.questions.lifeinsurance.cashvalue) && this.questions.lifeinsurance.none) {
    //   this.snackBar.openSnackBar(`Please unselect 'None' for your life insurance`, '', 'error');
    // } 
    // else if (this.questions.filing.answer === 'MFJ' && !this.questions.lifeinsurancespouse.term && !this.questions.lifeinsurancespouse.group && !this.questions.lifeinsurancespouse.cashvalue && !this.questions.lifeinsurancespouse.none) {
    //   this.snackBar.openSnackBar(`Please select type of life insurance your spouse have`, '', 'error');
    // }
    // else if ( this.questions.filing.answer === 'MFJ' &&
    //   (this.questions.lifeinsurancespouse.term || this.questions.lifeinsurancespouse.group || this.questions.lifeinsurancespouse.cashvalue) && this.questions.lifeinsurancespouse.none) {
    //   this.snackBar.openSnackBar(`Please unselect 'None' for your spouse's life insurance`, '', 'error');
    // }
   
    // else if (this.questions.lifeinsurance.cashvalue && !this.questions.cashvaluecoverageyou.answer) {
    //   this.snackBar.openSnackBar(`Please enter how much coverage you have with your insurance with the savings account`, '', 'error');
    // } else if (this.questions.filing.answer === 'MFJ' && this.questions.lifeinsurancespouse.cashvalue && !this.questions.cashvaluecoveragespouse.answer) {
    //   this.snackBar.openSnackBar(`Please enter how much coverage your spouse has with his/her insurance with the savings account?`, '', 'error');;
    // } else if (this.questions.lifeinsurance.term && !this.questions.termcoverageyou.answer) {
    //   this.snackBar.openSnackBar(`Please enter how much coverage you have with your Term insurance`, '', 'error');
    // } else if (this.questions.lifeinsurancespouse.term && this.questions.filing.answer === 'MFJ' && !this.questions.termcoveragespouse.answer) {
    //   this.snackBar.openSnackBar(`Please enter how much coverage your spouse has with his/her Term insurance`, '', 'error');
    // } else if (this.questions.lifeinsurance.group && !this.questions.groupcoverageyou.answer) {
    //   this.snackBar.openSnackBar(`Please enter how much coverage you have with your Group (Work) insurance`, '', 'error');
    // } else if (this.questions.filing.answer === 'MFJ' && this.questions.lifeinsurancespouse.group && !this.questions.groupcoveragespouse.answer) {
    //   this.snackBar.openSnackBar(`Please enter how much coverage your spouse has with his/her Group (Work) insurance`, '', 'error');
    // }

    if (this.questions.employment.employee && this.questions.worksponsoredplan.answer === '1' && !this.questions.plantype.plantype401k && !this.questions.plantype.plantype403b
      && !this.questions.plantype.plantype457 && !this.questions.plantype.plantypesimple && !this.questions.plantype.plantypeprofitsharing &&
      !this.questions.plantype.plantypepension && !this.questions.plantype.plantypetsp && !this.questions.plantype.plantypenone &&
      !this.questions.plantype.plantypeother && !this.questions.plantype.plantypenoidea) {
        var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione el tipo de plan de jubilación que tiene con su empleador':'Please select type of retirement plan you have with your current employer';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
    } else if (this.questions.employment.employee && this.questions.worksponsoredplan.answer === '1' &&
      ( this.questions.plantype.plantype401k || this.questions.plantype.plantype403b
        || this.questions.plantype.plantype457 || this.questions.plantype.plantypesimple || this.questions.plantype.plantypeprofitsharing || this.questions.plantype.plantypepension || this.questions.plantype.plantypetsp || this.questions.plantype.plantypeother || this.questions.plantype.plantypenoidea
        )
      && this.questions.plantype.plantypenone
    ) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, no marque "Ninguno" para su plan de jubilacién.':'Please unselect "None" for your retirement plan.';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
    } 
    else if (this.questions.employmentspouse.employeespouse && this.questions.worksponsoredplanspouse.answer === '1' && !this.questions.plantypespouse.plantype401k &&
      !this.questions.plantypespouse.plantype403b && !this.questions.plantypespouse.plantype457 && !this.questions.plantypespouse.plantypesimple
      && !this.questions.plantypespouse.plantypeprofitsharing && !this.questions.plantypespouse.plantypepension && !this.questions.plantypespouse.plantypetsp
      && !this.questions.plantypespouse.plantypenone && !this.questions.plantypespouse.plantypeother && !this.questions.plantypespouse.plantypenoidea) {
        var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione el tipo de plan de jubilación que su cónyuge tiene con su empleador':'Please select type of retirement plan your spouse has with his/her current employer';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
    } else if (
      this.questions.employmentspouse.employeespouse && this.questions.worksponsoredplanspouse.answer === '1'
       && 
       (this.questions.plantypespouse.plantype401k || this.questions.plantypespouse.plantype403b || this.questions.plantypespouse.plantype457 || this.questions.plantypespouse.plantypesimple
        || this.questions.plantypespouse.plantypeprofitsharing || this.questions.plantypespouse.plantypepension && !this.questions.plantypespouse.plantypetsp
        || this.questions.plantypespouse.plantypeother || this.questions.plantypespouse.plantypenoidea)
   && this.questions.plantypespouse.plantypenone
   ) { 
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, no marque "Ninguno" para el plan de jubilacién de su cényuge.':'Please unselect "None" for your Spouse\'s retirement plan.';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
  }


    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'survey8');

      // this.ngZone.run(() => this.router.navigate(['contribution'])).then();
      this.ngZone.run(() => this.router.navigate(['contribution'])).then();
    }

  }
}
