import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey15',
  templateUrl: './survey15.component.html',
  styleUrls: ['./survey15.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey15Component implements OnInit {

  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = '';
  clientName = '';
  loggedInUser = new User();
  question1 = false;
  question2 = false;

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
     layout: {
       navbar: {
         hidden: true
       },
       toolbar: {
         hidden: false, position : 'above'
       },
       footer: {
         hidden: true
       },
       sidepanel: {
         hidden: true
       }
     }
   }
  }
  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.check();
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.check();
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  check() {
    // if (!this.questions['investments'] || this.questions['investments'].rothira || this.questions['investmentsspouse'].rothiraspouse ||
    //   this.questions['investments'].traditionalira || this.questions['investmentsspouse'].traditionaliraspouse ||
    //   this.questions['investments'].annuity || this.questions['investmentsspouse'].annuityspouse) {
      this.question1 = true;
    // }
    if (this.questions['investments'].rothira || this.questions['investments'].traditionalira || this.questions['investments'].trust ||
      this.questions['lifeinsurance'].term || this.questions['lifeinsurance'].group || this.questions['lifeinsurance'].cashvalue) {
      this.question2 = true;
    }
    if (!this.question1 && !this.question2) {
      if (!localStorage.getItem('PREV_URL') || localStorage.getItem('PREV_URL') === 'investments-mf') {
        this.next();
      } else {
        this.back();
      }
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'investments-cs');
    this.ngZone.run(() => this.router.navigate(['investments-cd'])).then();
  }

  next() {
    if (this.question1 && !(this.questions.savingsaccountbalance.answer)) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, indique el saldo aproximado de su cuenta de ahorro y/o cuenta de cheques":"Please enter the approximate balance of your savings and/or checking account";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (this.question2 && !this.questions.beneficiariesupdated.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Seleccione si sus beneficiarios están al dia en todas sus cuentas":"Please select if your beneficiaries are up-to-date on all your accounts";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'investments-cs');
      this.ngZone.run(() => this.router.navigate(['life'])).then();
    }

  }

}
