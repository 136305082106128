import { Component, OnInit,ViewChild, Inject,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Question } from '../../questions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalDirective } from 'angular-bootstrap-md';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { RequestCacheWithMap } from 'app/interceptors/request-cache.service';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations

})
export class QuestionsComponent implements OnInit {
  @ViewChild('confirmModal', { static : false}) public confirmModal: ModalDirective;

  isLoading = true;
  question = new Question(); 
  questions: Question[] = [];
  searchText = '';
  constructor(public requestCacheService : RequestCacheWithMap, public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService,private questionService: QuestionService, public dialog: MatDialog) {
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
   }
  displayedColumns: string[] = ['name', 'englishText', 'spainText', 'action'];

  ngOnInit() {
    this.getQuestions();
  }
  openDialog(): void {
    this.question = new Question();
    const dialogRef = this.dialog.open(QuestionsDialogComponent, {
      width: '550px',
      data: { question: this.question }
    });

    dialogRef.afterClosed().subscribe(result => { 
      this.question = result;
      if (this.question) {
        
        this.isLoading = true;
        this.questionService.addQuestion(this.question).subscribe(u => {
          console.log(u);
          this.reset(); 
          this.getQuestions();
          this.isLoading = false;
        },(e)=>{
          console.log(e,'error')
        });
      }
    });
  }

  reset() {
    this.question = new Question();
  }

  openToEdit(q) {
    
    const dialogRef = this.dialog.open(QuestionsDialogComponent, {
      width: '550px',
      data: q
    });

    dialogRef.afterClosed().subscribe(result => {
      this.question = result;
      if (this.question) {
        this.isLoading = true;
        this.questionService.editQuestion(this.question).subscribe(c => {
          this.reset();
          this.getQuestions();
          this.isLoading = false;
        });
      }
    });
  }

  getQuestions() {
    this.isLoading = true;
    this.questionService.getQuestions().subscribe(result => {
      this.questions = result;
      this.isLoading = false;
    });
  }

  remove(question) {
    if (confirm('Are you sure you want to delete this question ')) {
      this.isLoading = true;
      this.questionService.deleteQuestion(question).subscribe(() => this.getQuestions());
      this.getQuestions();
      this.isLoading = false;
    }
  }

  search() {
    this.isLoading = true;
    this.questionService.searchQuestion(this.searchText).subscribe(result => {
      this.questions = result;
      this.isLoading = false;
    });
  }
  
  keyPressed(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.search();
    }
  }
 
}
@Component({
  selector: 'app-question-dialog',
  templateUrl: 'question-dialog.html',
  styleUrls: ['question-dialog.css']

})
export class QuestionsDialogComponent implements OnInit {
  ngOnInit() {

  }
  constructor(
    public dialogRef: MatDialogRef<QuestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public question: Question) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.question.name) {
      return false;
    }
    if (!this.question.englishText) {
      return false;
    }
    if (!this.question.spainText) {
      return false;
    }
    this.dialogRef.close(this.question);
  } 
}

