import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service'; 
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
 
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey6',
  templateUrl: './survey6.component.html',
  styleUrls: ['./survey6.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey6Component implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        console.log(this.questions);
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.check();
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.check();
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }

  }

  check() {
    
    if ((this.questions['employment'].selfemployed || this.questions['employment'].businessowner) && this.questions['worksponsoredplan'].answer === '0') {

    } else if ((this.questions['employmentspouse'].selfemployedspouse || this.questions['employmentspouse'].businessownerspouse) && this.questions['worksponsoredplanspouse'].answer === '0') {

    } else if (this.questions['employment'].businessowner) {

    } else if (this.questions['employmentspouse'].businessownerspouse) {

    } else {
      if (localStorage.getItem('PREV_URL') === 'survey7') {
        this.back();
      } else { 
        this.next(1);
      }
      
    }
  }
  

  back() {
    // localStorage.setItem('PREV_URL', 'survey6');  
    console.log("BACK")
    localStorage.setItem('PREV_URL', 'survey6');
    this.ngZone.run(() => this.router.navigate(['survey5'])).then();
                                            
  }

  next(bit) { 
    if (bit == 0 && (this.questions.employment.selfemployed || this.questions.employment.businessowner) && this.questions.worksponsoredplan.answer === '0' && !this.questions.numberofemployees.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, indique el numero de empleados que tiene':'Please select how many employees you have';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')

    } else if (bit == 0 && (this.questions.employmentspouse.selfemployedspouse || this.questions.employmentspouse.businessownerspouse) && this.questions.worksponsoredplanspouse.answer === '0' && !this.questions.numberofemployeesspouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, indique el numero de empleados que tiene su cényuge':'Please select how many employees your spouse has';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
      
    } else if (bit == 0 && (this.questions.employment.selfemployed || this.questions.employment.businessowner) && !this.questions.independentcontractors.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Indique cuántos contratistas independientes trabajan para usted':'Please enter how many independent contractors work for you';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
      
    } else if (bit == 0 && (this.questions.employmentspouse.selfemployedspouse || this.questions.employmentspouse.businessownerspouse) && !this.questions.independentcontractorsspouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Indique cuántos contratistas independientes trabajan para su cónyuge':'Please enter how many independent contractors work for your spouse';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
      
    } else if (bit == 0 && this.questions.employment.businessowner && !this.questions.businesspartners.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione si tiene socios empresariales':'Please select if you have business partners';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
      
    } else if (bit == 0 && this.questions.employmentspouse.businessownerspouse && !this.questions.businesspartnersspouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione si su cónyuge tiene socios empresariales':'Please select if your spouse has business partners';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
      
    } else if (bit == 0 && this.questions.employment.businessowner && this.questions.businesspartners.answer === '1' && !this.questions.buysellinsurance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione en caso de que su socio comercial fallezca, ¿tiene un seguro de vida para comprar el negocio de su socio?":"Please select in the event of your business partner's death, do you have life insurance on him/her in order to buy out their ownership?";
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
      
    } else if (bit == 0 && this.questions.employmentspouse.businessownerspouse && this.questions.businesspartnersspouse.answer === '1' && !this.questions.buysellinsurancespouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione en caso de que el socio comercial de su cónyuge fallezca, ¿tiene un seguro de vida para comprar el negocio de su socio?":"Please select in the event of your spouse's business partner's death, does he/she have life insurance on him/her in order to buy out their ownership?";
      this.snackBar.openSnackBar(spoust_Msg,'', 'error')
     
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'survey6'); 
      this.ngZone.run(() => this.router.navigate(['survey7'])).then();
    }
  }
}
