import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Variable } from './variable';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service'


@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient, public AS : AuthenticationService ) { }

  getVariables(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/variables`);
  }

  searchVariable(searchText): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/variables?name=${searchText}`, this.AS.addAuth());
  }

  addVariable(variable: Variable): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/variables`, variable, this.AS.addAuth());
  }

  editVariable(variable: Variable): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/variables/${variable._id}`, variable, this.AS.addAuth());
  }

  deleteVariable(variable: Variable): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/variables/${variable._id}`, this.AS.addAuth());
  }

}
