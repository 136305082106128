import { Pipe } from '@angular/core'; 
@Pipe({
  name: 'format'
})

export class FormatPipe {  
  transform(amount: string ): string {
    if(amount){  
      let formattedAmount = amount.replace (/,/g, "")
      if(formattedAmount){ 
        formattedAmount = formattedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }  
      return formattedAmount;
    }
  }
}