import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey-contribution',
  templateUrl: './survey-contribution.component.html',
  styleUrls: ['./survey-contribution.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SurveyContributionComponent implements OnInit {

  questions;
  showError: boolean = false;
  isLoading: boolean = true;
  selectedLanguage: string = '';
  clientName: string = '';
  loggedInUser = new User();

  constructor(public params: ParamService, public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService, private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: false, position: 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
  }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();

      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if (this.questions.length == 0) {
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.check();
        this.isLoading = false;
      }
      else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.check();
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  check() {
    console.log(this.questions);
    if (
      (
        (this.questions['employment'].employee && this.questions['plantype'].plantype401k)
        ||
        (this.questions['employment'].businessowner && this.questions['businessownerplantype'].answer == "401k"))
      && this.questions['worksponsoredplan'].answer === '1'
    )
    // (($employee && $plantype401k)|| ($businessowner == '$businessownerplantype == "401k")) && $worksponsoredplan === '1' 
    {
      console.log('Client');
    } else if (
     ( (this.questions['employmentspouse'].employeespouse && this.questions.plantypespouse.plantype401k)
      
      ||
      ( this.questions.businessownerplantypespouse.answer == '401kspouse' && this.questions['employmentspouse'].businessownerspouse) )
      
      && this.questions['worksponsoredplanspouse'].answer === '1'
    ) {
      console.log('Spouse')
    } else {                                                                       
      if (!localStorage.getItem('PREV_URL') || localStorage.getItem('PREV_URL') === 'survey9') {
        this.back();
        
      } else {
        
        this.next();
      } 
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'contribution');
    this.ngZone.run(() => this.router.navigate(['survey8'])).then();
  }

  next() {
    if (
    //   (this.questions.employment.employee || this.questions.employment.businessowner)
    //   &&
    //   this.questions.worksponsoredplan.answer === '1'
    //   && 
    //   this.questions.businessownerplantype.answer == "401k"
    //   &&
    //   !this.questions.contributing.answer
    // )  
      (
        (this.questions['employment'].employee && this.questions['plantype'].plantype401k)
        ||
        (this.questions['employment'].businessowner && this.questions['businessownerplantype'].answer == "401k")
      )
      && this.questions['worksponsoredplan'].answer === '1'
      &&
      !this.questions.contributing.answer
    ) 
    {
      var msg= this.selectedLanguage=='SP'?"Por favor, indique esta contribuyendo en su plan de retiro":"Please select if you are contributing to your plan";
      this.snackBar.openSnackBar(msg, '', 'error');
    } else if (
     ( (this.questions.employmentspouse.employeespouse && this.questions.plantypespouse.plantype401k) || (this.questions.employmentspouse.businessownerspouse && this.questions.businessownerplantypespouse.answer == '401kspouse')) && this.questions.worksponsoredplanspouse.answer === '1'  && !this.questions.contributingspouse.answer) {
      var msg= this.selectedLanguage=='SP'?"Por favor, indique si su conyuge esta contribuyendo en el plan de retiro":"Please select if your spouse is contributing to his/her plan";
      this.snackBar.openSnackBar(msg, '', 'error');
   
    } else if (((this.questions.employment.employee && this.questions.plantype.plantype401k) || (this.questions.employment.businessowner && this.questions.businessownerplantype.answer == "401k")) && this.questions.worksponsoredplan.answer === '1' && !this.questions.employermatch.answer) {
      var msg= this.selectedLanguage=='SP'?"Por favor, seleccione si su empleador ofrece una contrapartida (Match)":"Please select if your employer provides a match";
      this.snackBar.openSnackBar(msg, '', 'error');
    } else if (( (this.questions.employmentspouse.employeespouse && this.questions.plantypespouse.plantype401k) || (this.questions.employmentspouse.businessownerspouse && this.questions.businessownerplantypespouse.answer == '401kspouse')) && this.questions.worksponsoredplanspouse.answer === '1' && !this.questions.employermatchspouse.answer) {
      var msg= this.selectedLanguage=='SP'?"Por favor, indique si el empleador de su cónyuge ofrece un aporte(match) en el 401k":"Please select if your spouse\'s employer provides a match";
      this.snackBar.openSnackBar(msg, '', 'error');
    } else if (((this.questions.employment.employee && this.questions.plantype.plantype401k) || (this.questions.employment.businessowner && this.questions.businessownerplantype.answer == "401k")) && this.questions.worksponsoredplan.answer === '1' && this.questions.contributing.answer === '1' && this.questions.employermatch.answer === '1' && !this.questions.contributionamount.answer) {
      var msg= this.selectedLanguage=='SP'?"Por favor, indique cuanto esta contribuyendo a su plan de retiro":"Please select how much you are contributing";
      this.snackBar.openSnackBar(msg, '', 'error');
    } else if (( (this.questions.employmentspouse.employeespouse && this.questions.plantypespouse.plantype401k) || (this.questions.employmentspouse.businessownerspouse && this.questions.businessownerplantypespouse.answer == '401kspouse')) && this.questions.worksponsoredplanspouse.answer === '1' && this.questions.contributingspouse.answer === '1' && this.questions.employermatchspouse.answer === '1' && !this.questions.contributionamountspouse.answer) {
      var msg= this.selectedLanguage=='SP'?"Por favor, seleccione la cantidad que aporta su cónyuge":"Please select how much your spouse is contributing";
      this.snackBar.openSnackBar(msg, '', 'error');
    }
    else { 


      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions)); 
      localStorage.setItem('PREV_URL', 'contribution');
      this.ngZone.run(() => this.router.navigate(['survey9'])).then();
    }

  }
}
