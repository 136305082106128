import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey18',
  templateUrl: './survey18.component.html',
  styleUrls: ['./survey18.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey18Component implements OnInit {

  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = '';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
     layout: {
       navbar: {
         hidden: true
       },
       toolbar: {
         hidden: false, position : 'above'
       },
       footer: {
         hidden: true
       },
       sidepanel: {
         hidden: true
       }
     }
   }
  }
  
  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'inheritance');
    this.ngZone.run(() => this.router.navigate(['taxrefund'])).then();
  }

  next() { 
    if (!this.questions.inheritance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si su familia está en proceso de recibir una herencia":"Please select if your household is in the process of receiving an inheritance";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (this.questions.inheritance.answer === '0' && !this.questions.upcominginheritance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si su hogar espera recibir una herencia en los préximos 2 afios":"Please select if your household expects to receive an inheritance in the next 2 years";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (((this.questions.inheritance.answer === '1' || this.questions.upcominginheritance.answer === '1') && !(this.questions.inheritanceamount.answer))) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Indique el monto aproximado que espera recibir de la herencia":"Please enter the approximate expected amount of the inheritance";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'inheritance');
      this.ngZone.run(() => this.router.navigate(['misc'])).then();
    }

  }

}
