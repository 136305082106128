import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router'; 
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service'; 
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey1',
  templateUrl: './survey1.component.html',
  styleUrls: ['./survey1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey1Component implements OnInit {

  questions;
  showError: boolean = false;
  isLoading: boolean = true;
  selectedLanguage: string='';
  clientName: string = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        let lng = +pos.coords.longitude;
        let lat = +pos.coords.latitude;
        localStorage.setItem('USER_LNG', lng.toString());
        localStorage.setItem('USER_LAT', lat.toString()); 
        if(localStorage.getItem('CLIENT_LOC') == ''){ 
           let loc = { lat:lat, lng :lng}
           localStorage.setItem('CLIENT_LOC', JSON.stringify(loc));
        }
      });
    } 
  }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {

      this.params.checkSurveyMode(); // redirect user to Home 

      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
    

      this.selectedLanguage = localStorage.getItem('LANG');
      localStorage.setItem('LANG', this.selectedLanguage);
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
      
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        
        this.isLoading = false;
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        } 
      }
      else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }



  validAge(event, user) {
    if (user) {
      if (this.questions.age.answer < 0) {
        this.questions.age.answer = 0
        event.preventDefault();
      } else if (this.questions.age.answer > 99) {
        this.questions.age.answer = 99;
        event.preventDefault();
      }
    } else {
      if (this.questions.spouseage.answer < 0) {
        this.questions.spouseage.answer = 0
        event.preventDefault();
      } else if (this.questions.spouseage.answer > 99) {
        this.questions.spouseage.answer = 99;
        event.preventDefault();
      }
    }
  }

  keyPress(event){  
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }   
  }

  back() {
    this.ngZone.run(() => this.router.navigate(['home'])).then();
  }

  next() {
    var numberPattern = /\d+/g; 
    if (!this.questions.filing.answer) {
      var filing_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione cómo declara sus impuestos':'Please select how do you file your taxes';
      this.snackBar.openSnackBar(filing_Msg, '', 'error');
      
     // this.notifierService.notify('error', 'Please select how do you file your taxes');
    } else if (!this.questions.age.answer) {
      var ageMsg= this.selectedLanguage=='SP'?'Por favor ponga su edad':'Please enter your age';
      this.snackBar.openSnackBar(ageMsg, '', 'error');
    //  this.notifierService.notify('error', 'Please enter your age');
    } else if (this.questions.filing.answer === 'single' && !this.questions.dependent.answer) {
      var filing_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si reclama en sus impuestos a un hijo o padre que viva con usted':'Please select if you claim a dependent child or parent living with you';
      this.snackBar.openSnackBar(filing_Msg, '', 'error');
     // this.notifierService.notify('error', 'Please select if you claim a dependent child or parent living with you');
    } else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && !this.questions.spouseage.answer) {
      var filing_Msg= this.selectedLanguage=='SP'?'Por favor, ponga la edad de su cényuge':'Please enter your spouse(s) age';
      this.snackBar.openSnackBar(filing_Msg, '', 'error');
     // this.notifierService.notify('error', 'Please enter your spouse\'s age');
    } else if (this.questions.filing.answer === 'single' && this.questions.dependent.answer === '1' && !this.questions.expenses.answer) {
      var filing_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si usted paga personalmente más del 51% de todos los gastos del hogar':'Please select if you personally pay more than 51% of the entire household expenses';
      this.snackBar.openSnackBar(filing_Msg, '', 'error');
     // this.notifierService.notify('error', 'Please select if you personally pay more than 51% of the entire household expenses');
    } else {
      if(this.questions.spouseage.answer){ 
        if(this.questions.spouseage.answer == '49' && !this.questions.spouseageturnfifty.answer){
          var filing_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si su cónyuge cumple 50 años este año':'Please select if your spouse turn 50 this calendar year';
          this.snackBar.openSnackBar(filing_Msg, '', 'error');
          return false
        }
        if(!this.questions.spouseage.answer.match( numberPattern)){
          var ageAlert_Msg= this.selectedLanguage=='SP'?'Por favor, indique la edad correcta de su cényuge':'Please enter a valid age for your spouse';
          this.snackBar.openSnackBar(ageAlert_Msg, '', 'error');
          return false
        }
      }
      if(!this.questions.age.answer.match( numberPattern)){
        var AgeAlert_Msg= this.selectedLanguage=='SP'?'Por favor, indique una edad correcta.':'Please enter a valid age';
        this.snackBar.openSnackBar(AgeAlert_Msg, '', 'error');
        return false
      }
      if(this.questions.age.answer == '49' && !this.questions.ageturnfifty.answer){
        var ageAns_Msg= this.selectedLanguage=='SP'?'Por favor seleccione si usted cumple 50 este año':'Please select if you turn 50 this calendar year';
        this.snackBar.openSnackBar(ageAns_Msg, '', 'error');
        return false
      }
      if(this.questions.age.answer < 16) {
        var age_Msg= this.selectedLanguage=='SP'?'Por favor, indique una edad correcta.':'Please enter a valid age';
        this.snackBar.openSnackBar(age_Msg, '', 'error');
        return false
      }
      if(this.questions.spouseage.answer < 16) {
        var spous_Msg= this.selectedLanguage=='SP'?'Por favor, indique la edad correcta de su cényuge':'Please enter a valid age for your spouse';
        this.snackBar.openSnackBar(spous_Msg, '', 'error');
        return false
      }
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      this.ngZone.run(() => this.router.navigate(['employment'])).then();
    }
  }
}
