import {HttpEvent, HttpInterceptor,HttpResponse, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {TokenStorage} from '../auth/token.storage';
import { Injectable } from '@angular/core';
import { startWith, tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';

import { RequestCache } from './request-cache.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

	constructor(public cache: RequestCache) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = new TokenStorage();
		const tokenVal = token.getToken();
		const clonedRequest = req.clone({
			headers: req.headers
			// .set('Authorization', tokenVal ? `Bearer ${ tokenVal}` : '')
		});
		var loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
		if (loggedInUser) {
			if (loggedInUser.role != 'SA') {
				//set cache for Offline
				const cachedResponse = this.cache.get(req);
				// console.log(cachedResponse, 'cachedResponse');
				return cachedResponse
					? Observable.of(cachedResponse)
					: this.sendRequest(req, next, this.cache);
			} else {
				return next.handle(req);
			} 
		}
		else {
			return next.handle(req);
		}
	}
 
	sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
	}
	

}