import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey5',
  templateUrl: './survey5.component.html',
  styleUrls: ['./survey5.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey5Component implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode(); // redirect user to Home

      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.isLoading = false;
      }
      else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }
  format(d){
     
  }
  back() {
    this.ngZone.run(() => this.router.navigate(['children'])).then();
  }

  next() {
    if (!this.questions.previousemployeraccount.answer) { 
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si  tiene alguna cuenta de jubilación en una empresa anterior':'Please select if you currently have any retirement accounts still held with a previous employer';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      //this.notifierService.notify('error', 'Please select if you currently have any retirement accounts still held with a previous employer');
    } else if (this.questions.previousemployeraccount.answer === '1' && !this.questions.previousemployeraccountbalance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, indique el saldo aproximado de la cuenta de jubilación que todavía tiene en su anterior empresa':'Please enter the approximate balance of the retirement account still held with your previous employer';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      //this.notifierService.notify('error', 'Please enter the approximate balance of the retirement account still held with your previous employer');
    } else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && !this.questions.previousemployeraccountspouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si su cónyuge tiene  alguna cuenta de jubilación en una empresa anterior':'Please select if your spouse currently have any retirement accounts still held with a previous employer';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      //this.notifierService.notify('error', 'Please select if your spouse currently have any retirement accounts still held with a previous employer');
    } else if (this.questions.previousemployeraccountspouse.answer === '1' && !this.questions.previousemployeraccountbalancespouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Indique el balance aproximado de la cuenta de jubilación de su cónyuge que aún mantiene con su anterior empleador':'Please enter the approximate balance of your spouse\'s retirement account still held with his/her previous employer';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', 'Please enter the approximate balance of your spouse\'s retirement account still held with his/her previous employer');
    } else if ((this.questions.employment.employee || this.questions.employment.selfemployed || this.questions.employment.businessowner) && !this.questions.worksponsoredplan.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione si tiene un plan de jubilación patrocinado por su empleador':'Please select if you have a work-sponsored retirement plan';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', 'Please select if you have a work-sponsored retirement plan');
    } else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && (this.questions.employmentspouse.employeespouse || this.questions.employmentspouse.selfemployedspouse || this.questions.employmentspouse.businessownerspouse) && !this.questions.worksponsoredplanspouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione si su cónyuge tiene un plan de jubilación patrocinado su empleador':'Please select if your spouse have a work-sponsored retirement plan';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', 'Please select if your spouse have a work-sponsored retirement plan');
    }
    else if ((this.questions.employment.employee || this.questions.employment.selfemployed || this.questions.employment.businessowner) && this.questions.worksponsoredplan.answer === '1' && !this.questions.worksponsoredplanbalance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'indique el saldo de su plan de jubilación patrocinado por su empleador':'Please enter the balance of your work sponsored retirement plan.';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', 'Please select if you have a work-sponsored retirement plan');
    }
    else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && (this.questions.employmentspouse.employeespouse || this.questions.employmentspouse.selfemployedspouse || this.questions.employmentspouse.businessownerspouse) && this.questions.worksponsoredplanspouse.answer ==='1' && !this.questions.worksponsoredplanspousebalance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Indique el saldo del plan de jubilación patrocinado por el empleador de su cónyuge.':'Please enter the balance of your spouse,s work sponsored retirement plan.';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      // this.notifierService.notify('error', 'Please select if your spouse have a work-sponsored retirement plan');
    } 
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'survey5');  
      // this.ngZone.run(() => this.router.navigate(['contribution'])).then(); 
      this.ngZone.run(() => this.router.navigate(['survey6'])).then()
    }

  }
}
