import { Pipe } from '@angular/core';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'timezone'
})

export class TimezonePipe {  
  transform(date: string,data:string, time: string): string {
    
    let timezone =  localStorage.getItem('TIME_ZONE') ? JSON.parse(localStorage.getItem('TIME_ZONE')) : 'America/Chicago'
    let newZoneDate = ""; 
    if (date) {
        newZoneDate = moment(date).tz(timezone).format('MM/DD/YY') // hh:mm a 
        if(time){
          newZoneDate = moment(date).tz(timezone).format('MM/DD/YY hh:mm a') 
        } 
    } 
     return newZoneDate;
  }
}