import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
 
  
  constructor(public snackBar: MatSnackBar,) { }
 
  openSnackBar(message: string, action: string, type: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: [type]
    });
  }
}
