import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { CvariablesService } from '../../cvariables.service';
import { VariablesService } from '../../variables.service';
import { Variable } from '../../variable';
import { Cvariable } from '../../cvariables';
import { SurveyService } from '../../survey.service';
import { Survey } from '../../survey';
import { HeaderService } from '../../header/header.service';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'
import { LocationStrategy } from '@angular/common';

// Offline Survey 
import { OfflinesurveyService } from '../../offline-db-services/offline-survey.service';



@Component({
  selector: 'app-survey-complete',
  templateUrl: './survey-complete.component.html',
  styleUrls: ['./survey-complete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SurveyCompleteComponent implements OnInit {

  cvariables = [];
  variables = [];
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser : any = new User();

  processing = false;
  doneProcessing = false;
  doneAll = false;
  finalStep = false;
  retirementDone = false;
  collegeDone = false;
  debtDone = false;
  insuranceDone = false;
  progress = 3;

  // Alert DB names
  alert_tax_filing_status = false;
  alert_tax_refund = false;
  alert_upcoming_retirement = false;
  alert_upcoming_retirement_spouse = false;
  alert_esa = false;
  alert_will = false;
  alert_will_update = false;
  alert_no_health_insurance = false;
  alert_no_ltc_insurance = false;
  alert_rollover = false;
  alert_rollover_spouse = false;
  alert_roth_none = false;
  alert_roth_none_spouse = false;
  alert_roth_confirm_aa = false;
  alert_roth_confirm_aa_spouse = false;
  alert_ira_confirm_aa = false;
  alert_ira_confirm_aa_spouse = false;
  alert_increase_my_income = false;
  alert_savings_account = false;
  alert_cd = false;
  alert_cd_spouse = false;
  alert_annuity = false;
  alert_annuity_spouse = false;
  alert_bene_update = false;
  alert_debt_elim_plan = false;
  alert_401k_not_contributing = false;
  alert_401k_not_contributing_spouse = false;
  alert_401k_under_contributing = false;
  alert_401k_under_contributing_spouse = false;
  alert_insurance_buy_sell = false;
  alert_insurance_buy_sell_spouse = false;
  alert_work_no_retirement_plan = false;
  alert_work_no_retirement_plan_spouse = false;
  alert_sep_sira = false;
  alert_sep_sira_spouse = false;
  alert_sira_business = false;
  alert_sira_business_spouse = false;
  alert_business_401k = false;
  alert_business_401k_spouse = false;
  alert_roth_underfunding_single_u50 = false;
  alert_roth_underfunding_single_o50 = false;
  alert_roth_underfunding_mfj_u50 = false;
  alert_roth_underfunding_mfj_u50_spouse = false;
  alert_roth_underfunding_mfj_o50 = false;
  alert_roth_underfunding_mfj_o50_spouse = false;
  alert_roth_overfunding = false;
  alert_roth_overfunding_spouse = false;
  alert_roth_overfunding_mfs = false;
  alert_roth_overfunding_mfs_spouse = false; // New alert 
  alert_inservice_distro = false;
  alert_inservice_distro_spouse = false;
  alert_req_min_distro = false;
  alert_req_min_distro_spouse = false;
  alert_fiduciary = false;
  alert_fiduciary_spouse = false;
  alert_401k_high_fees = false;
  alert_401k_high_fees_spouse = false;
  alert_financial_wellness_seminar = false;
  alert_insurance_group = false;
  alert_insurance_savings_acct = false;
  alert_insurance_none = false;
  alert_insurance_under = false;
  alert_insurance_under_spouse = false;
  alert_inheritance = false;
  alert_inheritance_upcoming = false;
  
  // NEW ALERTS 
  alert_ira_underfunding_mfj_single_hoh = false  
  alert_ira_underfunding_mfj_single_hoh_spouse = false  
  alert_ira_overfunding_mfj_single_hoh = false; 
  alert_ira_overfunding_mfj_single_hoh_spouse = false;  
  alert_ira_underfunding_mfs = false 
  alert_ira_underfunding_mfs_spouse = false  
  alert_ira_overfunding_mfs = false  
  alert_ira_overfunding_mfs_spouse = false  
  alert_roth_underfunding_mfs = false;  
  alert_roth_underfunding_mfs_spouse = false;  
  alert_combined_ira_underfunding = false;  
  alert_combined_ira_underfunding_spouse = false; 
  alert_combined_ira_overfunding = false; 
  alert_combined_ira_overfunding_spouse = false;  
  alert_insurance_spouse_none = false;
  alert_insurance_savings_spouse_acct = false;
  alert_insurance_spouse_group = false;
  alert_no_idt_protection = false;
  alert_yes_idt_protection = false;
  alert_no_compare_prices_ins = false;

  liability_insurance_for_your_business_yes = false;
  liability_insurance_for_your_business_no = false;
  liability_insurance_for_your_business_spouse_yes = false;
  liability_insurance_for_your_business_spouse_no = false;
  homeowner_insurance_premium_by_smart_security_system_yes = false;
  homeowner_insurance_premium_by_smart_security_system_no = false;
  // Ends

  //  Offline survey related
  exception: Boolean = false;

  constructor(public offLineSurvey: OfflinesurveyService, public location: LocationStrategy, private headerService: HeaderService, public params: ParamService, public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService, private questionService: QuestionService, private router: Router, private ngZone: NgZone, private cvariablesService: CvariablesService, private variablesService: VariablesService, private surveyService: SurveyService) {


    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: false, position: 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }

  }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();

      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      console.log('this one');
      
      if(localStorage.getItem('REMOTE_USER') != null) {
        this.cvariablesService.getCompanyCVariablesWithCode(this.loggedInUser.companyKey, this.loggedInUser.remoteCode).subscribe((res) => {
          this.exception = false;
          this.cvariables = res;
          this.variablesService.getVariables().subscribe((res2) => {
            this.variables = res2;
            if (localStorage.getItem('QUESTIONS')) {
              this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
              if (this.questions.length == 0) {
                this.ngZone.run(() => this.router.navigate(['home'])).then();
              }
              this.amountsToParse(false);
              this.isLoading = false;
            }
            else {
              this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
                this.questions = result;
                this.amountsToParse(false);
              });
            }
          });
        }, (e) => {
          this.isLoading = false;
          this.exception = true;
        });
      } else {
        this.cvariablesService.getCompanyCVariables(this.loggedInUser.companyKey).subscribe((res) => {
          this.exception = false;
          this.cvariables = res;
          this.variablesService.getVariables().subscribe((res2) => {
  
            this.variables = res2;
            if (localStorage.getItem('QUESTIONS')) {
              this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
              if (this.questions.length == 0) {
                this.ngZone.run(() => this.router.navigate(['home'])).then();
              }
              this.amountsToParse(false);
              this.isLoading = false;
            }
            else {
              this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
                this.questions = result;
                this.amountsToParse(false);
              });
  
            }
          });
        }, (e) => {
          this.isLoading = false;
          this.exception = true;
        });
      }


    } else {
      this.router.navigate(['login']);
    }
  }

  checkAlerts() {

    

    /* --------------------------------- ROTH IRA RELATED ALERTS -------------------------------------- */

    /*
     * Alert : No Roth IRA
     * Alert Name : alert_roth_none
     * Variables : 
     * filing (single / headofhousehold / MFJ )
     * agimarried
     * agisingle
     * employment.retired 
     * investments.rothira 
     */
    if (
      (
        ((this.questions.filing.answer === 'single' || this.questions.filing.answer === 'headofhousehold') && this.questions.agisingle.answer != 0)
        ||
        (this.questions.filing.answer === 'MFJ' && this.questions.agimarried.answer != 0)
      )
      && this.questions.employment.retired != '1'
      // && this.questions.employmentspouse.retiredspouse != '1'
      && this.questions.investments.rothira != true
    ) {
      this.alert_roth_none = true
    }
    // ends
    //---------------------------------------

    /*
     * Alert : No Roth IRA - Spouse
     * Alert Name : alert_roth_none_spouse
     * Variables : 
     * filing ( MFJ ) (spouse)
     * agimarried
     * agisingle
     * employmentspouse.retiredspouse 
     * investmentsspouse.rothiraspouse 
     */
    if (
      (this.questions.filing.answer === 'MFJ')
      && this.questions.agimarried.answer != 0
      // && this.questions.employment.retired != '1'
      && this.questions.employmentspouse.retiredspouse != '1'
      && this.questions.investmentsspouse.rothiraspouse != true
    ) {
      this.alert_roth_none_spouse = true;
    }
    // ends
    //--------------------------------------

    /*
    * Alert : Roth IRA - Confirm appropriate allocation
    * Alert Name : alert_roth_confirm_aa
    * Variables : 
    * investments.rothira
    // * agimarried
    // * agisingle 
    // * age
    * rothbalance
    * var_roth_ira_amt_you 
    */
    if (
      this.questions.investments.rothira
      &&
      // !this.questions.agimarried.answer
      // &&
      // !this.questions.agisingle.answer
      // && Changed to similar logic to that of Traditional IRA
      parseInt(this.questions.rothbalance.answer) > this.getCVariableValueByName('var_roth_ira_amt_you')
    ) {
      this.alert_roth_confirm_aa = true;
    }
    // ends
    //--------------------------------------


    /*
    * Alert : Roth IRA - Confirm appropriate allocation - Spouse
    * Alert Name : alert_roth_confirm_aa_spouse
    * Variables : 
    * investmentsspouse.rothiraspouse
    // * agimarried
    // * agisingle 
    // * spouseage
    * rothbalancespouse
    * var_roth_ira_amt_spouse 
    */
    if (
      this.questions.investmentsspouse.rothiraspouse
      &&
      // !this.questions.agimarried.answer
      // &&
      // !this.questions.agisingle.answer
      // &&  Changed to similar logic to that of Traditional IRA
      parseInt(this.questions.rothbalancespouse.answer) > this.getCVariableValueByName('var_roth_ira_amt_spouse')
    ) {
      this.alert_roth_confirm_aa_spouse = true;
    }
    // ends
    //-------------------------------------- 


    /*
    * Alert : Underfunding Your Roth IRA
    * Alert Name : alert_roth_underfunding_single_u50
    * Variables : 
    * filing (single / headofhousehold)
    * investments.rothira  
    * age 
    * annualcontributionroth
    * rira_u50_amt 
    * income
    * roth_sing_hoh_low_amt
    * agisingle ( 1 || !=1 )
    */
    if (
      (this.questions.filing.answer === 'single' || this.questions.filing.answer === 'headofhousehold')
      && this.questions.investments.rothira == true && parseInt(this.questions.age.answer) < 50 && parseInt(this.questions.annualcontributionroth.answer) < this.getVariableValueByName('rira_u50_amt')
      && (
        (parseInt(this.questions.income.answer) <= this.getVariableValueByName('roth_sing_hoh_low_amt') && this.questions.agisingle.answer !== '1')
        ||
        (parseInt(this.questions.income.answer) > this.getVariableValueByName('roth_sing_hoh_low_amt') && this.questions.agisingle.answer === '1')
      )
    ) {
      this.alert_roth_underfunding_single_u50 = true;
    }
    // Ends
    //--------------------------------------


    /*
    * Alert : Underfunding Your Roth IRA
    * Alert Name : alert_roth_underfunding_single_u50
    * Variables : 
    * filing (single / headofhousehold)
    * investments.rothira  
    * age 
    * annualcontributionroth
    * rira_o50_amt 
    * income
    * roth_sing_hoh_low_amt
    * agisingle ( 1 || !=1 )
    */
    if (parseInt(this.questions.age.answer) >= 50
      && this.questions.investments.rothira
      && (this.questions.filing.answer === 'single' || this.questions.filing.answer === 'headofhousehold')
      && parseFloat(this.questions.annualcontributionroth.answer) < this.getVariableValueByName('rira_o50_amt')
      && (
        (parseFloat(this.questions.income.answer) <= this.getVariableValueByName('roth_sing_hoh_low_amt') && this.questions.agisingle.answer !== '1')
        ||
        (parseFloat(this.questions.income.answer) > this.getVariableValueByName('roth_sing_hoh_low_amt') && this.questions.agisingle.answer == '1'))
    ) {
      this.alert_roth_underfunding_single_o50 = true;
    }
    // Ends
    //--------------------------------------


  /*
   * Alert :  Overfunding Your Roth IRA
   * Alert Name : alert_roth_overfunding_mfs 
   * Variables : 
   * age
   * agisingle
   * investments.rothira
   * roth_sing_hoh_low_amt
   * calcCombinedIncome = (income + spouseincome)
   * calcCombinediraContribution = (rothIra + TraditionIra)
   * filing ( MFS )
   */
    if (this.questions.filing.answer == 'MFS' && this.questions.investments.rothira) {

      if (parseInt(this.questions.age.answer) < 50) {
        if (
          this.calcCombinedIncome() <= this.getVariableValueByName('roth_sing_hoh_low_amt')
          && !this.questions.agisingle.answer &&
          this.calcCombinediraContribution() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs = true;
        } else if (
          this.calcCombinedIncome() > this.getVariableValueByName('roth_sing_hoh_low_amt')
          && this.questions.agisingle.answer &&
          this.calcCombinediraContribution() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs = true;
        }
      }

      if (parseInt(this.questions.age.answer) >= 50) {
        if (
          this.calcCombinedIncome() <= this.getVariableValueByName('roth_sing_hoh_low_amt')
          && !this.questions.agisingle.answer &&
          this.calcCombinediraContribution() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs = true;
        }
        else if (
          this.calcCombinedIncome() > this.getVariableValueByName('roth_sing_hoh_low_amt')
          && this.questions.agisingle.answer
          &&
          this.calcCombinediraContribution() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs = true;
        }
      }
    }
    // MFS overfunding ends
    //--------------------------------------  
 


   //  MFS overfunding spouse starts
   /*
   * Alert :  Overfunding Your Roth IRA - Spouse
   * Alert Name : alert_roth_overfunding_mfs_spouse 
   * Variables : 
   * spouseage
   * agisingle
   * investmentsspouse.rothiraspouse
   * roth_sing_hoh_low_amt
   * calcCombinedIncome = (income + spouseincome)
   * calcCombinediraContribution = (rothIra + TraditionIra)
   * filing ( MFS )
   */
    if (
      this.questions.investmentsspouse.rothiraspouse && this.questions.filing.answer === 'MFS'
    ) {
      if (parseInt(this.questions.spouseage.answer) < 50) {
        if (
          this.calcCombinedIncome() <= this.getVariableValueByName('roth_sing_hoh_low_amt')
          && !this.questions.agisingle.answer &&
          this.calcCombinediraContributionSpouse() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs_spouse = true;
        } else if (
          this.calcCombinedIncome() > this.getVariableValueByName('roth_sing_hoh_low_amt')
          && this.questions.agisingle.answer &&
          this.calcCombinediraContributionSpouse() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs_spouse = true;
        }
      }
      if (parseInt(this.questions.spouseage.answer) >= 50) {
        if (
          this.calcCombinedIncome() <= this.getVariableValueByName('roth_sing_hoh_low_amt')
          && !this.questions.agisingle.answer &&
          this.calcCombinediraContributionSpouse() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs_spouse = true;
        }
        else if (
          this.calcCombinedIncome() > this.getVariableValueByName('roth_sing_hoh_low_amt')
          && this.questions.agisingle.answer
          &&
          this.calcCombinediraContributionSpouse() > 0
        ) {
          // clean
          this.alert_roth_overfunding_mfs_spouse = true;
        }
      }
    }
    // Ends
    //--------------------------------------

  
  
    /*
    * Alert :  Underfunding Your Roth IRA
    * Alert Name : alert_roth_underfunding_mfj_u50 
    * Variables : 
    * filing ( MFJ )
    * agisingle
    * age
    * investments.rothira
    * !investments.traditionalira
    * annualcontributionroth
    * roth_sing_hoh_low_amt
    * calcCombinedIncome = (income + spouseincome)
    * agi_low_amt
    * agimarried
    */
    if (
      this.questions.filing.answer === 'MFJ' &&
      parseInt(this.questions.age.answer) < 50 &&
      this.questions.investments.rothira && !this.questions.investments.traditionalira
      &&
      parseFloat(this.questions.annualcontributionroth.answer) //this.calcCombinediraContribution() //

      < this.getVariableValueByName('rira_u50_amt') &&
      (
        (this.calcCombinedIncome() <= this.getVariableValueByName('agi_low_amt') && this.questions.agimarried.answer !== '1')
        ||
        (this.calcCombinedIncome() > this.getVariableValueByName('agi_low_amt') && this.questions.agimarried.answer === '1')
      )
    ) { 
      this.alert_roth_underfunding_mfj_u50 = true;
    }
    // Ends
    //--------------------------------------

     
    if (
      this.questions.filing.answer === 'MFJ' &&
      parseInt(this.questions.spouseage.answer) < 50 &&
      this.questions.investmentsspouse.rothiraspouse && !this.questions.investmentsspouse.traditionaliraspouse
      && parseFloat(this.questions.annualcontributionrothspouse.answer) //this.calcCombinediraContributionSpouse()// 

      < this.getVariableValueByName('rira_u50_amt') &&
      (
        (this.calcCombinedIncome() <= this.getVariableValueByName('agi_low_amt') && this.questions.agimarried.answer !== '1')
        ||
        (this.calcCombinedIncome() > this.getVariableValueByName('agi_low_amt') && this.questions.agimarried.answer === '1')
      )
    ) {
      this.alert_roth_underfunding_mfj_u50_spouse = true;
    }

    // Ends
    //--------------------------------------

    if (
      this.questions.filing.answer === 'MFJ' &&
      parseInt(this.questions.age.answer) >= 50 &&
      this.questions.investments.rothira && !this.questions.investments.traditionalira
      &&
      parseFloat(this.questions.annualcontributionroth.answer) // this.calcCombinediraContribution() //

      < this.getVariableValueByName('rira_o50_amt') &&
      (
        (this.calcCombinedIncome() <= this.getVariableValueByName('roth_mar_jnt_low_amt') && this.questions.agimarried.answer !== '1')
        ||
        (this.calcCombinedIncome() > this.getVariableValueByName('roth_mar_jnt_low_amt') && this.questions.agimarried.answer === '1')
      )
    ) {
      this.alert_roth_underfunding_mfj_o50 = true;
    }
    // Ends
    //--------------------------------------


    if (
      this.questions.filing.answer === 'MFJ' &&
      parseInt(this.questions.spouseage.answer) >= 50 &&
      this.questions.investmentsspouse.rothiraspouse && !this.questions.investmentsspouse.traditionaliraspouse &&
      parseFloat(this.questions.annualcontributionrothspouse.answer) //this.calcCombinediraContributionSpouse() //
      < this.getVariableValueByName('rira_o50_amt') &&
      (
        (this.calcCombinedIncome() <= this.getVariableValueByName('roth_mar_jnt_low_amt') && this.questions.agimarried.answer !== '1')
        ||
        (this.calcCombinedIncome() > this.getVariableValueByName('roth_mar_jnt_low_amt') && this.questions.agimarried.answer === '1')
      )
    ) {

      this.alert_roth_underfunding_mfj_o50_spouse = true;
    }
    // Ends
    //--------------------------------------


    if (
      this.questions.filing.answer != 'MFS' &&
      (
        (
          this.questions.investments.rothira
          && parseInt(this.questions.age.answer) < 50
          &&
          (
            (
              (this.calcCombinediraContribution() > this.getVariableValueByName('rira_u50_amt'))
              &&
              parseInt(this.questions.annualcontributionroth.answer) > this.getVariableValueByName('rira_u50_amt')
            )
            ||
            (
              (this.questions.agisingle.answer == '0' || this.questions.agimarried.answer == '0')
              && parseInt(this.questions.annualcontributionroth.answer) > 0
            )
          )
        )
        ||
        (this.questions.investments.rothira
          &&
          parseInt(this.questions.age.answer) >= 50
          &&
          (
            (
              (this.calcCombinediraContribution() > this.getVariableValueByName('rira_o50_amt'))
              &&
              parseInt(this.questions.annualcontributionroth.answer) > this.getVariableValueByName('rira_o50_amt')
            )
            ||
            (
              // for single filer                         // for married filer
              (this.questions.agisingle.answer == '0' || this.questions.agimarried.answer == '0') &&
              parseInt(this.questions.annualcontributionroth.answer) > 0
            )
          )
        ))
    ) {
      this.alert_roth_overfunding = true;
    }
    // Ends
    //--------------------------------------

    if (
      this.questions.filing.answer != 'MFS'
      &&
      (this.questions.investmentsspouse.rothiraspouse && !this.questions.investmentsspouse.traditionaliraspouse
        && parseInt(this.questions.spouseage.answer) < 50
        &&
        (
          (
            (this.calcCombinediraContributionSpouse() > this.getVariableValueByName('rira_u50_amt'))
            &&
            parseInt(this.questions.annualcontributionrothspouse.answer) > this.getVariableValueByName('rira_u50_amt')
          )
          ||
          (
            this.questions.agimarried.answer == '0' && (parseInt(this.questions.annualcontributionrothspouse.answer) > 0)
          )
        )
      )
      ||
      (this.questions.investmentsspouse.rothiraspouse && !this.questions.investmentsspouse.traditionaliraspouse
        &&
        parseInt(this.questions.spouseage.answer) >= 50
        &&
        (
          (
            // (this.calcCombinediraContributionSpouse() > this.getVariableValueByName('rira_o50_amt'))
            // &&
            parseInt(this.questions.annualcontributionrothspouse.answer) > this.getVariableValueByName('rira_o50_amt')
          )
          ||
          (
            this.questions.agimarried.answer == '0' &&
            parseInt(this.questions.annualcontributionrothspouse.answer) > 0
          )
        )
      )
    ) {

      this.alert_roth_overfunding_spouse = true;
    }
    // Ends
    //--------------------------------------


    /* --------------------------------- ROTH IRA RELATED ALERTS ENDS -------------------------------------- */

    /* -------------------------------------- 401(k) RELATED ALERTS ----------------------------------------- */

    /*
    * Alert : 401(k): Not Contributing
    * Alert Name : alert_401k_not_contributing
    * Variables : 
    * worksponsoredplan.answer
    * plantype.plantype401k  (in case of Employee)
    * businessownerplantype.answer (401k) (in case of Businessowner)
    * contributing.answer 
    */
    if (this.questions.worksponsoredplan.answer === '1'
      &&
      (this.questions.plantype.plantype401k == true || this.questions.businessownerplantype.answer == '401k')
      && this.questions.contributing.answer === '0') {
      this.alert_401k_not_contributing = true;
    }
    // Ends
    //--------------------------------------



    /*
     * Alert : 401(k): Not Contributing - Spouse
     * Alert Name : alert_401k_not_contributing_spouse
     * Variables : 
     * worksponsoredplanspouse.answer
     * plantypespouse.plantype401k  (in case of Employee)
     * businessownerplantypespouse.answer (401kspouse) (in case of Businessowner)
     * contributingspouse.answer 
     */
    if (this.questions.worksponsoredplanspouse.answer === '1'
      &&
      (this.questions.plantypespouse.plantype401k == true || this.questions.businessownerplantypespouse.answer == '401kspouse')
      && this.questions.contributingspouse.answer === '0') {
      this.alert_401k_not_contributing_spouse = true;
    }
    // Ends
    //--------------------------------------


    /*
     * Alert : 401(k): Under Contributing for Employer Match
     * Alert Name : alert_401k_under_contributing
     * Variables : 
     * worksponsoredplan
     * plantype.plantype401k  (in case of Employee)
     * businessownerplantype.answer (401k) (in case of Businessowner)
     * employermatch 
     * contributionamount
     */
    if (this.questions.worksponsoredplan.answer === '1'
      &&
      (this.questions.plantype.plantype401k == true || this.questions.businessownerplantype.answer == '401k')
      && this.questions.contributing.answer === '1'
      && this.questions.employermatch.answer === '1'
      && this.questions.contributionamount.answer === 'lessthanmatch'
    ) {
      this.alert_401k_under_contributing = true;
    }
    // Ends
    //--------------------------------------


    /*
     * Alert : 401(k): Under Contributing for Employer Match - Spouse
     * Alert Name : alert_401k_under_contributing_spouse
     * Variables : 
     * worksponsoredplanspouse
     * plantypespouse.plantype401kk  (in case of Employee)
     * businessownerplantypespouse.answer (401kspouse) (in case of Businessowner)
     * employermatchspouse 
     * contributionamountspouse
     */
    if (this.questions.worksponsoredplanspouse.answer === '1'
      &&
      (this.questions.plantypespouse.plantype401k == true || this.questions.businessownerplantypespouse.answer == '401kspouse')
      && this.questions.contributingspouse.answer === '1'
      && this.questions.employermatchspouse.answer === '1'
      && this.questions.contributionamountspouse.answer === 'lessthanmatch'
    ) {
      this.alert_401k_under_contributing_spouse = true;
    }
    // Ends
    //-------------------------------------- 

    /*
    * Alert : Fiduciary: You may be personally legally liable.
    * Alert Name : alert_fiduciary
    * Variables : 
    * employment.businessowner
    * businessownerplantype.answer (401k)
    */
    if (this.questions.employment.businessowner && this.questions.businessownerplantype.answer == "401k") {
      this.alert_fiduciary = true;
    }
    // Ends
    //--------------------------------------


    /*
    * Alert : Fiduciary: Your spouse may be personally legally liable.
    * Alert Name : alert_fiduciary_spouse
    * Variables : 
    * employmentspouse.businessownerspouse
    * businessownerplantypespouse.answer (401kspouse)
    */
    if (this.questions.employmentspouse.businessownerspouse && this.questions.businessownerplantypespouse.answer == "401k") {
      this.alert_fiduciary_spouse = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.planassets.answer === '1') {
      this.alert_401k_high_fees = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.planassetsspouse.answer === '1') {
      this.alert_401k_high_fees_spouse = true;
    }
    // Ends
    //--------------------------------------


    /*
    * Alert : 401(k) retirement plan for your business
    * Alert Name : alert_business_401k
    * Variables : 
    * employment.businessowner
    * worksponsoredplan
    * numberofemployeesspouse ( onehundredplus )
    */
    if (this.questions.employment.businessowner && this.questions.worksponsoredplan.answer === '0' && this.questions.numberofemployeesspouse.answer === 'onehundredplus') {
      this.alert_business_401k = true;
    }
    // Ends
    //--------------------------------------


    /*
    * Alert : 401(k) retirement plan for your spouse's business
    * Alert Name : alert_business_401k_spouse
    * Variables : 
    * employmentspouse.businessownerspouse
    * worksponsoredplanspouse
    * numberofemployeesspouse ( onehundredplus )
    */
    if (this.questions.employmentspouse.businessownerspouse && this.questions.worksponsoredplanspouse.answer === '0' && this.questions.numberofemployeesspouse.answer === 'onehundredplus') {
      this.alert_business_401k_spouse = true;
    }
    // Ends
    //--------------------------------------

    /* -------------------------------------- 401(k) RELATED ALERTS ENDS ----------------------------------------- */


    /* -------------------------------------- RETIREMENT / Insurance RELATED ALERTS ----------------------------------------- */


    if (this.questions.lifeinsurance.group) {
      this.alert_insurance_group = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.lifeinsurance.cashvalue) {
      this.alert_insurance_savings_acct = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.lifeinsurance.none) {
      this.alert_insurance_none = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.lifeinsurancespouse.group) {
      this.alert_insurance_spouse_group = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.lifeinsurancespouse.cashvalue) {
      this.alert_insurance_savings_spouse_acct = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.lifeinsurancespouse.none) {
      this.alert_insurance_spouse_none = true;
    }
    // Ends
    //--------------------------------------



    if (this.questions.participationrate.answer === '1') {
      this.alert_financial_wellness_seminar = true;
    }
    // Ends
    //-------------------------------------- 


    /*
    * Alert : In-Service Distribution
    * Alert Name : alert_inservice_distro
    * Variables : 
    * age > 59
    * employment.employee
    * plantype.plantype401k 
    * plantype.plantype403b 
    * plantype.plantype457
    */
    if (this.questions.employment.employee && (this.questions.plantype.plantype401k || this.questions.plantype.plantype403b || this.questions.plantype.plantype457) && parseInt(this.questions.age.answer) > 59) {
      this.alert_inservice_distro = true;
    }
    // Ends
    //--------------------------------------



    /*
    * Alert : In-Service Distribution - Spouse
    * Alert Name : alert_inservice_distro_spouse
    * Variables : 
    * age > 59
    * employmentspouse.employeespouse
    * plantypespouse.plantype401k 
    * plantypespouse.plantype403b 
    * plantypespouse.plantype457
    */
    if (this.questions.employmentspouse.employeespouse && (this.questions.plantypespouse.plantype401k || this.questions.plantypespouse.plantype403b || this.questions.plantypespouse.plantype457) && parseInt(this.questions.spouseage.answer) > 59) {
      this.alert_inservice_distro_spouse = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.employment.businessowner && this.questions.businesspartners.answer === '1' && this.questions.buysellinsurance.answer === '0') {
      this.alert_insurance_buy_sell = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.employmentspouse.businessownerspouse && this.questions.businesspartnersspouse.answer === '1' && this.questions.buysellinsurancespouse.answer === '0') {
      this.alert_insurance_buy_sell_spouse = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.employment.businessowner && this.questions.worksponsoredplanspouse.answer === '0') {
      this.alert_work_no_retirement_plan = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.employmentspouse.businessownerspouse && this.questions.worksponsoredplanspouse.answer === '0') {
      this.alert_work_no_retirement_plan_spouse = true;
    }
    // Ends
    //--------------------------------------


    /*
    * Alert : Explore the options of a SEP or a SIMPLE Retirement Plan
    * Alert Name : alert_sep_sira
    * Variables : 
    * employment.selfemployed
    * worksponsoredplan
    * numberofemployees (zero || onetoninetynine)
    */
    if ((this.questions.employment.selfemployed && this.questions.worksponsoredplan.answer === '0' && this.questions.numberofemployees.answer === 'zero') || this.questions.numberofemployees.answer === 'onetoninetynine') {
      this.alert_sep_sira = true;
    }
    // Ends
    //--------------------------------------

    /*
    * Alert : Explore the options of a SEP or a SIMPLE Retirement Plan for your spouse
    * Alert Name : alert_sep_sira_spouse
    * Variables : 
    * employmentspouse.selfemployedspouse
    * worksponsoredplanspouse
    * numberofemployeesspouse (zero || onetoninetynine)
    */
    if ((this.questions.employmentspouse.selfemployedspouse && this.questions.worksponsoredplanspouse.answer === '0' && this.questions.numberofemployeesspouse.answer === 'zero') || this.questions.numberofemployeesspouse.answer === 'onetoninetynine') {
      this.alert_sep_sira_spouse = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.employment.businessowner && this.questions.worksponsoredplan.answer === '0' && this.questions.numberofemployees.answer === 'onetoninetynine') {
      this.alert_sira_business = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.employmentspouse.businessownerspouse && this.questions.worksponsoredplanspouse.answer === '0' && this.questions.numberofemployeesspouse.answer === 'onetoninetynine') {
      this.alert_sira_business_spouse = true;
    }
    // Ends
    //--------------------------------------

    if (this.calculateCoverage() < (this.getCVariableValueByName('var_insurance_multiplier') * parseFloat(this.questions.income.answer))) {
      this.alert_insurance_under = true;
    }
    // Ends
    //--------------------------------------


    if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFJ') && this.calculateSpouseCoverage() < (this.getCVariableValueByName('var_insurance_multiplier') * parseFloat(this.questions.spouseincome.answer))) {
      this.alert_insurance_under_spouse = true;
    }
    // Ends
    //--------------------------------------

    /* -------------------------------------- RETIREMENT / INSRUANCE RELATED ALERTS ENDS ----------------------------------------- */


    /* --------------------------------------------------- OTHER ALERTS  --------------------------------------------------------- */

    if(this.questions.compare_prices_ins.answer === '0'){
      this.alert_no_compare_prices_ins = true;
    }
    if (this.questions.shortfall.increasemyincome == true) {
      this.alert_increase_my_income = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.idt_protection.answer === '0') {
      this.alert_no_idt_protection = true;
    } else {
      this.alert_yes_idt_protection = true;
    }

    console.log('this.questions',this.questions);

    // if(this.questions.employment.businessowner && this.questions.liability_insurance_for_your_business.answer == '0') {
    //   this.liability_insurance_for_your_business_no = true;
    // }

    // if(this.questions.employment.businessowner && this.questions.liability_insurance_for_your_business.answer == '1') {
    //   this.liability_insurance_for_your_business_yes = true;
    // }

    // if((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && this.questions.employmentspouse.businessownerspouse && this.questions.liability_insurance_for_your_business_spouse.answer == '0') {
    //   this.liability_insurance_for_your_business_spouse_no = true;
    // }

    // if((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && this.questions.employmentspouse.businessownerspouse && this.questions.liability_insurance_for_your_business_spouse.answer == '1') {
    //   this.liability_insurance_for_your_business_spouse_yes = true;
    // }

    // if(this.questions.debt.mortgage && this.questions.homeowner_insurance_premium_by_smart_security_system.answer == '0') {
    //   this.homeowner_insurance_premium_by_smart_security_system_no = true
    // }

    // if(this.questions.debt.mortgage && this.questions.homeowner_insurance_premium_by_smart_security_system.answer == '1') {
    //   this.homeowner_insurance_premium_by_smart_security_system_yes = true
    // }

    // Ends
    //--------------------------------------

    if (this.questions.filing.answer === 'single' && this.questions.dependent.answer === '1' && this.questions.expenses.answer === '1') {
      this.alert_tax_filing_status = true;
    }
    // Ends
    //--------------------------------------

    if (parseFloat(this.questions.taxrefundamount.answer) > this.getCVariableValueByName('var_tax_refund_amt')) {
      this.alert_tax_refund = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.retiring.answer === '1') {
      this.alert_upcoming_retirement = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.retiringspouse.answer === '1') {
      this.alert_upcoming_retirement_spouse = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.children.answer === '1' && this.questions.esatype.none) {
      this.alert_esa = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.will.answer === '0') {
      this.alert_will = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.will.answer === '1' && this.questions.willupdate.answer === '0') {
      this.alert_will_update = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.healthinsurance.answer === '0') {
      this.alert_no_health_insurance = true;
    }
    // Ends
    //--------------------------------------  

    // if (this.questions.ltc.answer === '0') {
    //   this.alert_no_ltc_insurance = true;
    // }
    // Ends
    //--------------------------------------

    if (this.questions.previousemployeraccount.answer === '1') {
      this.alert_rollover = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.previousemployeraccountspouse.answer === '1') {
      this.alert_rollover_spouse = true;
    }
    // Ends
    //--------------------------------------

    if (parseFloat(this.questions.savingsaccountbalance.answer) > this.getCVariableValueByName('var_savings_account_balance')) {
      this.alert_savings_account = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.investments.cd) {
      this.alert_cd = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.investmentsspouse.cdspouse) {
      this.alert_cd_spouse = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.investments.annuity) {
      this.alert_annuity = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.investmentsspouse.annuityspouse) {
      this.alert_annuity_spouse = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.beneficiariesupdated.answer === '0') {
      this.alert_bene_update = true;
    }
    // Ends
    //--------------------------------------

    if (this.getNumberOfDebts() >= this.getCVariableValueByName('var_number_of_debts')) {
      this.alert_debt_elim_plan = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.inheritance.answer === '1') {
      this.alert_inheritance = true;
    }
    // Ends
    //--------------------------------------


    if (this.questions.upcominginheritance.answer === '1') {
      this.alert_inheritance_upcoming = true;
    }
    // Ends
    //--------------------------------------

    if (parseInt(this.questions.age.answer) > 72) {
      this.alert_req_min_distro = true;
    }
    // Ends
    //-------------------------------------- 

    if (parseInt(this.questions.spouseage.answer) > 72) {
      this.alert_req_min_distro_spouse = true;
    }
    // Ends
    //--------------------------------------

    /* --------------------------------------------------- OTHER ALERTS END ------------------------------------------------------- */

    /* --------------------------------------------- TRADITIONAL IRA RELATED ALERTS ----------------------------------------------- */


    if (this.questions.investments.traditionalira && parseFloat(this.questions.traditionalbalance.answer) > this.getCVariableValueByName('var_trad_ira_amt_you')) {
      this.alert_ira_confirm_aa = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.investmentsspouse.traditionaliraspouse && parseFloat(this.questions.traditionalbalancespouse.answer) > this.getCVariableValueByName('var_trad_ira_amt_spouse')) {
      this.alert_ira_confirm_aa_spouse = true;
    }
    // Ends
    //--------------------------------------



    /*
    * Alert : "Underfunding Your Traditional IRA" && "Overfunding Your Traditional IRA"
    * Alert Name : alert_ira_underfunding_mfj_single_hoh and alert_ira_overfunding_mfj_single_hoh
    * Variables : 
    * age
    * annualcontributiontraditional (tira_u50_amt || tira_o50_amt )
    * filing (MFJ / headofhousehold / single )
    * investments.traditionalira
    * !this.questions.investments.rothira
    */
    if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'headofhousehold' || this.questions.filing.answer === 'single') && this.questions.investments.traditionalira && !this.questions.investments.rothira) {

      //  UNDERFUNDING 
      if (
        (parseInt(this.questions.age.answer) < 50 && parseFloat(this.questions.annualcontributiontraditional.answer) < this.getVariableValueByName('tira_u50_amt'))
        ||
        (parseInt(this.questions.age.answer) >= 50 && parseFloat(this.questions.annualcontributiontraditional.answer) < this.getVariableValueByName('tira_o50_amt'))) {
        this.alert_ira_underfunding_mfj_single_hoh = true;
      };

      // OVERFUNDING 
      if (
        (parseInt(this.questions.age.answer) < 50 && parseFloat(this.questions.annualcontributiontraditional.answer) > this.getVariableValueByName('tira_u50_amt'))
        ||
        (parseInt(this.questions.age.answer) >= 50 && parseFloat(this.questions.annualcontributiontraditional.answer) > this.getVariableValueByName('tira_o50_amt'))) {
        this.alert_ira_overfunding_mfj_single_hoh = true;
      };
    }
    // Ends
    //--------------------------------------



    /*
    * Alert : "Underfunding Your Traditional IRA - Spouse" && "Overfunding Your Traditional IRA - Spouse"
    * Alert Name : alert_ira_underfunding_mfj_single_hoh_spouse and alert_ira_overfunding_mfj_single_hoh_spouse
    * Variables : 
    * spouseage
    * annualcontributiontraditionalspouse (tira_u50_amt || tira_o50_amt )
    * filing ( MFJ )
    * investmentsspouse.traditionaliraspouse
    * !investmentsspouse.rothiraspouse
    */
    if (this.questions.filing.answer === 'MFJ' && this.questions.investmentsspouse.traditionaliraspouse && !this.questions.investmentsspouse.rothiraspouse) {
      // underfunding spouse
      if (
        (parseInt(this.questions.spouseage.answer) < 50 && parseFloat(this.questions.annualcontributiontraditionalspouse.answer) < this.getVariableValueByName('tira_u50_amt'))
        ||
        (parseInt(this.questions.spouseage.answer) >= 50 && parseFloat(this.questions.annualcontributiontraditionalspouse.answer) < this.getVariableValueByName('tira_o50_amt'))) {
        this.alert_ira_underfunding_mfj_single_hoh_spouse = true;
      }

      // overfunding spouse
      if (
        (parseInt(this.questions.spouseage.answer) < 50 && parseFloat(this.questions.annualcontributiontraditionalspouse.answer) > this.getVariableValueByName('tira_u50_amt'))
        ||
        (parseInt(this.questions.spouseage.answer) >= 50 && parseFloat(this.questions.annualcontributiontraditionalspouse.answer) > this.getVariableValueByName('tira_o50_amt'))) {
        this.alert_ira_overfunding_mfj_single_hoh_spouse = true;
      }
    }
    // Ends
    //--------------------------------------



    if (this.questions.filing.answer === 'MFS' && this.questions.investments.traditionalira && !this.questions.investments.rothira && parseFloat(this.questions.annualcontributiontraditional.answer) > 0) {
      this.alert_ira_overfunding_mfs = true;
    }
    // Ends
    //--------------------------------------

    if (this.questions.filing.answer === 'MFS' && this.questions.investmentsspouse.traditionaliraspouse && !this.questions.investmentsspouse.rothiraspouse && parseFloat(this.questions.annualcontributiontraditionalspouse.answer) > 0) {
      this.alert_ira_overfunding_mfs_spouse = true;
    }
    // Ends
    //-------------------------------------- 

    /* --------------------------------------------- TRADITIONAL IRA RELATED ALERTS ----------------------------------------------- */
  }





  manageCombinedIRA() {
    if (this.questions.investments.traditionalira && this.questions.investments.rothira) {

      // Single + HoH + MFJ
      if (this.questions.annualcontributiontraditional.answer > 0 && this.questions.annualcontributionroth.answer > 0) {
        if ((parseInt(this.questions.age.answer) < 50 && this.calcCombinediraContribution() < this.getVariableValueByName('rira_u50_amt')) ||
          (parseInt(this.questions.age.answer) >= 50 && this.calcCombinediraContribution() < this.getVariableValueByName('rira_o50_amt'))
        ) {
          console.log("COMBINED IRA IS UNDERFUNDED")
          //test case 
          if (
            // for single filer                         // for married filer
            (this.questions.agisingle.answer == '0' || this.questions.agimarried.answer == '0') &&
            parseInt(this.questions.annualcontributionroth.answer) > 0
          ) {
            // agi == no, rothcontribution > 0 ==> Overfunding Roth
            console.log('alert_combined_ira_underfunding, but should be overfunding')
            this.alert_combined_ira_overfunding = true;
          } else {
            this.alert_combined_ira_underfunding = true;
          }
        }
      }


      if (this.questions.annualcontributiontraditional.answer > 0 && this.questions.annualcontributionroth.answer > 0) {
        if ((parseInt(this.questions.age.answer) < 50 && this.calcCombinediraContribution() > this.getVariableValueByName('rira_u50_amt')) ||
          (parseInt(this.questions.age.answer) >= 50 && this.calcCombinediraContribution() > this.getVariableValueByName('rira_o50_amt'))
        ) {
          console.log("COMBINED IRA IS OVERFUNDED")
          this.alert_combined_ira_overfunding = true;
        }
      }

      if (this.questions.filing.answer == 'MFS' && this.questions.annualcontributiontraditional.answer > 0 && this.questions.annualcontributionroth.answer > 0) {
        if (this.calcCombinediraContribution() > 0) {
          console.log("COMBINED IRA IS OVERFUNDED MFS")
          this.alert_combined_ira_overfunding = true;
        }
      }

    }

    // spouse MFJ
    if (this.questions.investmentsspouse.traditionaliraspouse && this.questions.investmentsspouse.rothiraspouse) {
      if (this.questions.annualcontributiontraditionalspouse.answer > 0 && this.questions.annualcontributionrothspouse.answer > 0) {
        if ((parseInt(this.questions.spouseage.answer) < 50 && this.calcCombinediraContributionSpouse() < this.getVariableValueByName('rira_u50_amt')) ||
          (parseInt(this.questions.spouseage.answer) >= 50 && this.calcCombinediraContributionSpouse() < this.getVariableValueByName('rira_o50_amt'))
        ) {
          console.log("COMBINED IRA SPOUSE IS UNDERFUNDED")
          //test case 
          if (
            // for married filer
            this.questions.agimarried.answer == '0' && parseInt(this.questions.annualcontributionrothspouse.answer) > 0
          ) {
            // agi == no, rothcontribution > 0 ==> Overfunding Roth
            console.log('alert_combined_ira_underfunding, but should be overfunding')
            this.alert_combined_ira_overfunding_spouse = true;
          } else {
            this.alert_combined_ira_underfunding_spouse = true;
          }

        }

        if (this.questions.annualcontributiontraditionalspouse.answer > 0 && this.questions.annualcontributionrothspouse.answer > 0) {
          if ((parseInt(this.questions.spouseage.answer) < 50 && this.calcCombinediraContribution() > this.getVariableValueByName('rira_u50_amt')) ||
            (parseInt(this.questions.spouseage.answer) >= 50 && this.calcCombinediraContribution() > this.getVariableValueByName('rira_o50_amt'))
          ) {
            console.log("COMBINED IRA IS OVERFUNDED spouse")
            this.alert_combined_ira_overfunding_spouse = true;
          }
        }

        if (this.questions.filing.answer == 'MFS' && this.questions.annualcontributiontraditionalspouse.answer > 0 && this.questions.annualcontributionrothspouse.answer > 0) {
          if (this.calcCombinediraContribution() > 0) {
            console.log("COMBINED IRA IS OVERFUNDED MFS")
            this.alert_combined_ira_overfunding_spouse = true;
          }
        }
      }
    }
  }


  amountsToParse(bit) {
    if (bit == true) {
      if (localStorage.getItem('SUBMITTED')) {
        // Survey already submitted, just rerender the alerts without submitting the survey for db entry
        this.fakeFinalise();

      } else {
        // this.fakeFinalise();
        this.finalize();
      }
    } else {
      var data = this.questions;
      var items = [
        'annualcontributionroth',
        'annualcontributionrothspouse', 'annualcontributiontraditional',
        'annualcontributiontraditionalspouse', 'taxrefundamount',
        'annuitybalance', 'annuitybalancespouse', 'cashvaluecoveragespouse', 'cashvaluecoverageyou',
        'cdbalance', 'cdbalancespouse', 'groupcoveragespouse', 'groupcoverageyou',
        'income', 'spouseincome', 'inheritanceamount', 'mutualfundbalance', 'mutualfundbalancespouse', 'previousemployeraccountbalance', 'previousemployeraccountbalancespouse',
        'rothbalance', 'rothbalancespouse', 'savingsaccountbalance',
        'traditionalbalance', 'traditionalbalancespouse', 'termcoveragespouse',
        'termcoverageyou', 'independentcontractors', 'independentcontractorsspouse'
      ]
      for (let i in items) {
        if (data[items[i]].answer) {
          data[items[i]].answer = this.params.formattedCurrencyToNumber(data[items[i]].answer);
        }
      }
      this.questions = data;
      this.checkAlerts();
      this.manageCombinedIRA(); 
    }
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, location.href);
    })
    localStorage.setItem("SUBMITTED", '1')
  }

  finalize() {
    // window.onpopstate = function (e) { window.history.forward(1); }
    this.preventBackButton();
    this.processing = true;
    let survey = new Survey();
    // survey.salesRepId = this.loggedInUser.role == "RVP" ? null : this.loggedInUser._id;
    survey.salesRepId = this.loggedInUser.role == "RVP" ? this.loggedInUser._id : this.loggedInUser._id;
    survey.clientInitials = this.clientName;
    survey.language = this.selectedLanguage;
    survey.questions = this.questions;
    survey.lat = parseFloat(localStorage.getItem('USER_LAT'));
    survey.long = parseFloat(localStorage.getItem('USER_LNG'));
    survey.zip = localStorage.getItem('CLIENT_ZIP');
    survey.createdAt = new Date();
    // added later for reports
    survey.clientLocation = JSON.parse(localStorage.getItem('CLIENT_LOC'));
    survey.rvpId = this.loggedInUser.role == "RVP" ? this.loggedInUser._id : this.loggedInUser.rvp;
    if (!navigator.onLine) {
      console.log('Offline Mode : Add Survey in local db')
      this.addSurvey(survey);
      window.setTimeout(() => {
        this.retirementDone = true;
        this.progress = 25;
      }, 500);
  
      window.setTimeout(() => {
        this.collegeDone = true;
        this.progress = 50;
      }, 1500);
  
      window.setTimeout(() => {
        this.debtDone = true;
        this.progress = 75;
      }, 2500);
  
      window.setTimeout(() => {
        this.insuranceDone = true;
        this.progress = 100;
      }, 3500);
  
      window.setTimeout(() => {
        this.processing = false;
       this.doneProcessing = true;
      }, 4000);
      return;
    }
    let data : any = [survey];
    let url  = '';
    if(localStorage.getItem('REMOTE_USER') != null) {
      url = 'withcode';
      data = {
        code : this.loggedInUser.remoteCode,
        data : [survey]
      }
    }
    this.surveyService.submitSurvey(data, url).subscribe((res) => {
      window.setTimeout(() => {
        this.retirementDone = true;
        this.progress = 25;
      }, 500);
  
      window.setTimeout(() => {
        this.collegeDone = true;
        this.progress = 50;
      }, 1500);
  
      window.setTimeout(() => {
        this.debtDone = true;
        this.progress = 75;
      }, 2500);
  
      window.setTimeout(() => {
        this.insuranceDone = true;
        this.progress = 100;
      }, 3500);
  
      window.setTimeout(() => {
        this.processing = false;
       this.doneProcessing = true;
      }, 4000);
      localStorage.removeItem('REMOTE_USER');
      localStorage.setItem('REMOTE_SURVEY','false');
      //----------------------------------Add By ACAP------------------------------------------//
      localStorage.removeItem('QUESTIONS')
      
    });

  }

  fakeFinalise() {
    console.log('here')
    // this.preventBackButton();
    this.processing = true;
    window.setTimeout(() => {
      this.retirementDone = true;
      this.progress = 25;
    }, 500);

    window.setTimeout(() => {
      this.collegeDone = true;
      this.progress = 50;
    }, 1500);

    window.setTimeout(() => {
      this.debtDone = true;
      this.progress = 75;
    }, 2500);

    window.setTimeout(() => {
      this.insuranceDone = true;
      this.progress = 100;
    }, 3500);

    window.setTimeout(() => {
      this.processing = false;
     this.doneProcessing = true;
    }, 4000);

  }

  allDone() {
    // if(window.confirm('Submit and end the survey?')){
    this.submit()
    //  }

    // this.doneAll = true;
  }
 
  next() {
    this.doneAll = false;
    this.finalStep = true;
  }

  submit() {
    // this.headerService.surveyMode(true);
    localStorage.setItem('CLIENT_INITIALS', '');
    localStorage.removeItem('SUBMITTED');
    // localStorage.setItem('preventBack', '1')
    // this.router.navigate(['home']);
  }

  getCVariableValueByName(name: string) {
    let result: Cvariable;
    for (let i = 0; i < this.cvariables.length; i++) {
      const element = this.cvariables[i];
      if (element.name === name) {
        result = element;
      }
    }
    return result.value;
  }

  getVariableValueByName(name: string) {
    let result: Variable;
    for (let i = 0; i < this.variables.length; i++) {
      const element = this.variables[i];
      if (element.name === name) {
        result = element;
      }
    }
    console.log('name',name);
    return result.value;
  }

  getNumberOfDebts() {
    let debts = 0;
    if (this.questions.debt.mortgage) debts++;
    if (this.questions.debt.creditcard1) debts++;
    if (this.questions.debt.creditcard2) debts++;
    if (this.questions.debt.creditcard3) debts++;
    if (this.questions.debt.carloan1) debts++;
    if (this.questions.debt.carloan2) debts++;
    if (this.questions.debt.studentloan1) debts++;
    if (this.questions.debt.studentloan2) debts++;
    if (this.questions.debt.departmentstore1) debts++;
    if (this.questions.debt.departmentstore2) debts++;
    if (this.questions.debt.other) debts++;
    return debts;
  }

  calculateCoverage() {
    let coverage = 0;
    if (!isNaN(this.questions.cashvaluecoverageyou.answer)) {
      coverage += parseFloat(this.questions.cashvaluecoverageyou.answer);
    }
    if (!isNaN(this.questions.termcoverageyou.answer)) {
      coverage += parseFloat(this.questions.termcoverageyou.answer);
    }
    if (!isNaN(this.questions.groupcoverageyou.answer)) {
      coverage += parseFloat(this.questions.groupcoverageyou.answer);
    }
    return coverage;
  }

  calculateSpouseCoverage() {
    let coverage = 0;
    if (!isNaN(this.questions.cashvaluecoveragespouse.answer)) {
      coverage += parseFloat(this.questions.cashvaluecoveragespouse.answer);
    }
    if (!isNaN(this.questions.termcoveragespouse.answer)) {
      coverage += parseFloat(this.questions.termcoveragespouse.answer);
    }
    if (!isNaN(this.questions.groupcoveragespouse.answer)) {
      coverage += parseFloat(this.questions.groupcoveragespouse.answer);
    }
    return coverage;
  }

  calcCombinedIncome() {
    let income = 0;
    if (this.questions.income.answer) {
      income += parseFloat(this.questions.income.answer);
    }
    if (this.questions.spouseincome.answer) {
      income += parseFloat(this.questions.spouseincome.answer);
    }
    return income;
  }

  calcCombinediraContribution() {
    let contribution = 0;
    if (this.questions.annualcontributionroth.answer) {
      contribution += parseFloat(this.questions.annualcontributionroth.answer);
    }

    if (this.questions.investments.traditionalira && this.questions.annualcontributiontraditional.answer) {
      contribution += parseFloat(this.questions.annualcontributiontraditional.answer);
    }
    return contribution;
  }

  calcCombinediraContributionSpouse() {
    let contribution = 0;
    if (this.questions.annualcontributionrothspouse.answer) {
      contribution += parseFloat(this.questions.annualcontributionrothspouse.answer);
    }
    if (this.questions.investmentsspouse.traditionaliraspouse && this.questions.annualcontributiontraditionalspouse.answer) {
      contribution += parseFloat(this.questions.annualcontributiontraditionalspouse.answer);
    }
    return contribution;
  }



  // Offline Surveys
  async getSurveys() {
    try {
      const allSurveys = await this.offLineSurvey.getSurveys() as Survey[];
      console.log('All Surveys => ', allSurveys)
    }
    catch (error) {
      console.error(error);
    }
  }

  async addSurvey(newSurvey) {
    try {
      const addedSurveys = await this.offLineSurvey.addSurveys(newSurvey) as Survey[];
      console.log(addedSurveys)
      if (addedSurveys.length > 0) {
        console.log('Successfully added');
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  async deleteSurvey(studentId) {
    try {
      const noOfrowsDeleted = await this.offLineSurvey.deleteSurvey(studentId);
      if (noOfrowsDeleted > 0) {
        console.log('Successfully deleted');
      }
    }
    catch (error) {
      console.error(error);
    }
  }

}
