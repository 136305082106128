import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Question } from './questions';
import { CompanyService } from './company.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private companyService: CompanyService, public AS : AuthenticationService ) { }

  getQuestions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/questions`, this.AS.addAuth());
  }

  getCompanyQuestions(companyId: string): Observable<any> {
    return new Observable((observer) => {
      this.companyService.getCompany(companyId).subscribe((company) => {
        this.getQuestions().subscribe((questions) => {
          for (let x = 0; x < questions.length; x++) {
            for (let i = 0; i < company.questions.length; i++) {
              const companyQuestion = company.questions[i];
              if (companyQuestion.name === questions[x].name) {
                questions[x].englishText = companyQuestion.englishText;
                questions[x].spainText = companyQuestion.spainText;
              }
            }
          }
          observer.next(questions);
        });
      });
    });
  }

  getQuestionsMap(companyId: string): Observable<any> {
    console.log('companyId', companyId)
    return new Observable((observer) => {
      this.companyService.getCompany(companyId).subscribe((company) => {
        this.getQuestions().subscribe((questions) => {
          const questionsMap = {};
          for (let x = 0; x < questions.length; x++) {
            
            if(company != null && company.questions != null){
              for (let i = 0; i < company.questions.length; i++) {
                const companyQuestion = company.questions[i];
                if (companyQuestion.name === questions[x].name) {
                  questions[x].englishText = companyQuestion.englishText;
                  questions[x].spainText = companyQuestion.spainText;
                }
              }
            }



            questionsMap[questions[x].name] = {
              name: questions[x].name,
              englishText: questions[x].englishText,
              spanishText: questions[x].spainText
            };
          }
          observer.next(questionsMap);
        });
      });
    });
  }

  searchQuestion(searchText): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/questions?name=${searchText}`, this.AS.addAuth());
  }

  searchCompanyQuestion(searchText: string, companyId: string): Observable<any> {
    return new Observable((observer) => {
      this.companyService.getCompany(companyId).subscribe((company) => {
        this.searchQuestion(searchText).subscribe((questions) => {
          for (let x = 0; x < questions.length; x++) {
            for (let i = 0; i < company.questions.length; i++) {
              const companyQuestion = company.questions[i];
              if (companyQuestion.name === questions[x].name) {
                questions[x].englishText = companyQuestion.englishText;
                questions[x].spainText = companyQuestion.spainText;
              }
            }
          }
          observer.next(questions);
        });
      });
    });
  }

  deleteQuestion(question: Question): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/questions/${question._id}`, this.AS.addAuth());
  }

  addQuestion(question: Question): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/questions`, question, this.AS.addAuth());
  }

  editQuestion(question: Question): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/questions/${question._id}`, question, this.AS.addAuth());
  }

  editCompanyQuestion(question: Question, companyId: string): Observable<any> {
    return this.companyService.editCompanyQuestion(question, companyId);
  }

  addMultipleQuestions(question){
    return this.http.post(`${this.apiUrl}/api/addMultipleQuestions`, question, this.AS.addAuth());
  }
}
