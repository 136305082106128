import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Companies } from './companies';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Question } from './questions';
import { Variable } from './variable';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  apiUrl = environment.apiUrl; 
  constructor(private http: HttpClient, public AS : AuthenticationService) { }

 

  getCompanies(): Observable<any> {
   
    return this.http.get(`${this.apiUrl}/api/companies`, this.AS.addAuth());
  }

  getCompany(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/companies/${id}`, this.AS.addAuth());
  }

  getCompanywithCode(id: string, code : string): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/companies/withcode`,{
      id : id,
      code : code
    });
  }

  searchCompany(searchText): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/companies?name=${searchText}`, this.AS.addAuth());
  }

  addCompany(company: Companies): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/companies`, company, this.AS.addAuth());
  }

  deleteCompany(company: Companies): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/companies/${company._id}`, this.AS.addAuth());
  }

  editCompany(company: Companies): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/companies/${company._id}`, company, this.AS.addAuth());
  }

  addCompanyQuestion(question: Question, companyId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/companies/questions/${companyId}`, question, this.AS.addAuth());
  }

  editCompanyQuestion(question: Question, companyId: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/companies/questions/${companyId}`, question, this.AS.addAuth());
  }

  addCompanyCVariable(variable: Variable, companyId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/companies/cvariables/${companyId}`, variable, this.AS.addAuth());
  }

  editCompanyCVariable(variable: Variable, companyId: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/companies/cvariables/${companyId}`, variable, this.AS.addAuth());
  }

  getUsersForCompany(company): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/companies/users`,company, this.AS.addAuth());
  }

}
