import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from './user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, public AS : AuthenticationService ) { }

  getUsers(): Observable<any> {  
    return this.http.get(`${this.apiUrl}/api/users`, this.AS.addAuth());
  }
  allUsers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/users/allusers`, this.AS.addAuth());
  }
  searchUser(searchText): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/users?name=${searchText}`, this.AS.addAuth());
  }

  deleteUser(user: User): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/users/${user._id}`, this.AS.addAuth());
  }

  addUser(user: User): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/users`, user, this.AS.addAuth());
  }

  editUser(user: User): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/users/${user._id}`, user, this.AS.addAuth());
  }

  getSalesUsers(rvp): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/users/salesreps/${rvp}`, this.AS.addAuth());
  }

  getRvpSurveys(data): Observable<any> {
    
    return this.http.post(`${this.apiUrl}/api/users/rvp`, data, this.AS.addAuth());
  }

  verifyCode(code : string) : Observable <any> {
    return Observable.create(observer => {
      this.http.post(`${this.apiUrl}/api/users/verifycode`, {
        "code" : code
      }).subscribe((data : any) => { 
            if(data) { 
              observer.next(data);
            } else {
              observer.next(undefined);
              observer.complete();
            }
        }, error => {
          observer.error(error.error);
        });
    });
  }
 

}
