
import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Survey } from '../../survey';
import { Router } from '@angular/router';
import { User } from '../../user';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service' 
import { ParamService } from '../../param.service'


@Component({
  selector: 'app-viewsurvey',
  templateUrl: './viewsurvey.component.html',
  styleUrls: ['./viewsurvey.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ViewsurveyComponent implements OnInit {

  survey: Survey;
  salesRep: User;
  question1 = false;
  question2 = false;
  over54 = false;
  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private router: Router) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }

  ngOnInit() {
    if (localStorage.getItem('SURVEY')) {
      debugger;
      this.survey = JSON.parse(localStorage.getItem('SURVEY'));
      this.salesRep = JSON.parse(localStorage.getItem('SALES_REP'));
      // for survey 15
      // if (!this.survey.questions['investments'] || this.survey.questions['investments'].rothira || this.survey.questions['investmentsspouse'].rothiraspouse ||
      //   this.survey.questions['investments'].traditionalira || this.survey.questions['investmentsspouse'].traditionaliraspouse ||
      //   this.survey.questions['investments'].annuity || this.survey.questions['investmentsspouse'].annuityspouse) {
        this.question1 = true;
      // }
      if (this.survey.questions['investments'].rothira || this.survey.questions['investments'].traditionalira || this.survey.questions['investments'].trust ||
        this.survey.questions['lifeinsurance'].term || this.survey.questions['lifeinsurance'].group || this.survey.questions['lifeinsurance'].cashvalue) {
        this.question2 = true;
      }
      // for survey 19
      if (parseInt(this.survey.questions['age'].answer) > 54) {
        this.over54 = true;
      } else if (this.survey.questions['spouseage'] && parseInt(this.survey.questions['spouseage'].answer) > 54) {
        this.over54 = true;
      }
    } else {
      this.router.navigate(['salesreps']);
    }
  }
  
  back() {
    this.router.navigate(['salesreps']);
  }
}
