import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
import { VariablesService } from '../../variables.service';
import { Variable } from '../../variable';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

 

@Component({
  selector: 'app-survey3',
  templateUrl: './survey3.component.html',
  styleUrls: ['./survey3.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey3Component implements OnInit {

  questions;
  showError: boolean = false;
  isLoading: boolean = true;
  selectedLanguage: string = '';
  clientName: string = '';
  loggedInUser = new User(); 
  taxVariables;
  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService, private variablesService: VariablesService) {
       // Configure the layout
       this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          toolbar: {
            hidden: false, position : 'above'
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: true
          }
        }
      }
   }


  /*
  * IMPORTANT AGI Variables related information: 

    Variable used originally / not anymore
      Single/hoh ==> this.getVariableValueByName('agi_low_amt') 
      MFJ ==> this.getVariableValueByName('agi_high_amt'));
    
    New Variables  
      For single/head of house ==> this.getVariableValueByName('roth_sing_hoh_low_amt') 
      For Married jointly ==> this.getVariableValueByName('roth_mar_jnt_low_amt')
      For Married Filing Seperatly ==> NO AGI FOLLOW UP QUESTION
  * 
  * 
  * 
  * 
  */

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode(); // redirect user to Home
      
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.variablesService.getVariables().subscribe((variables) => {
        
        console.log('SURVEY 3 VARIABLES', variables)

        this.taxVariables = variables; 
        if (localStorage.getItem('QUESTIONS')) {
          this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
          if(this.questions.length == 0){
            this.ngZone.run(() => this.router.navigate(['home'])).then();
          }

          // moved the AGI questions from survey2page
          this.questions.agisingle.englishText = this.questions.agisingle.englishText.replace('{agi_low_amt}', this.getVariableValueByName('agi_low_amt'));
          this.questions.agimarried.englishText = this.questions.agimarried.englishText.replace('{agi_high_amt}', this.getVariableValueByName('agi_high_amt'));
          this.questions.agisingle.spanishText = this.questions.agisingle.spanishText.replace('{agi_low_amt}', this.getVariableValueByName('agi_low_amt'));
          this.questions.agimarried.spanishText = this.questions.agimarried.spanishText.replace('{agi_high_amt}', this.getVariableValueByName('agi_high_amt'));
          this.isLoading = false; 
           // if nothing to display, just go next
          // if (!this.questions['employmentspouse'].businessownerspouse && !this.questions['employment'].businessowner) {
          //   this.ngZone.run(() => this.router.navigate(['children'])).then();
          // }
        }
        else {
          this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
            this.questions = result;
            // moved the AGI questions from survey2page
            this.questions.agisingle.englishText = this.questions.agisingle.englishText.replace('{agi_low_amt}', this.getVariableValueByName('agi_low_amt'));
            this.questions.agimarried.englishText = this.questions.agimarried.englishText.replace('{agi_high_amt}', this.getVariableValueByName('agi_high_amt'));
            this.questions.agisingle.spanishText = this.questions.agisingle.spanishText.replace('{agi_low_amt}', this.getVariableValueByName('agi_low_amt'));
            this.questions.agimarried.spanishText = this.questions.agimarried.spanishText.replace('{agi_high_amt}', this.getVariableValueByName('agi_high_amt'));
            this.isLoading = false;

            // if nothing to display, just go next
            // if (!this.questions['employmentspouse'].answer3 && !this.questions['employment'].answer3) {
            //   this.ngZone.run(() => this.router.navigate(['children'])).then();
            // }
          });
        } 
      }, err=>{
        alert();
        this.isLoading = false;
      }); 
    } else {
      this.router.navigate(['login']);
    }

  }

  back() {
    this.ngZone.run(() => this.router.navigate(['employment'])).then();
  }

  next() {
    if (this.questions.employment.businessowner && !this.questions.companystructure.answer) {

      var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione la estructura de su empresa':'Please select your company structured';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      this.notifierService.notify('error', spoust_Msg);
    } else if (this.questions.employmentspouse.businessownerspouse && !this.questions.companystructurespouse.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione la estructura de la empresa de su cónyuge':'Please select your spouse\'s company structured';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      this.notifierService.notify('error', spoust_Msg);
    } else if (!this.questions.income.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, indique sus ingresos personales anuales antes de impuestos':'Please enter your personal before tax annual income';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      this.notifierService.notify('error', spoust_Msg);
    } else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && !this.questions.spouseincome.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, introduzca los ingresos anuales antes de impuestos de su cónyuge':'Please enter your spouse\'s before tax annual income';
      this.snackBar.openSnackBar(spoust_Msg, '', 'error');
      this.notifierService.notify('error', spoust_Msg);
    } 
    else if ((this.questions.filing.answer === 'single' || this.questions.filing.answer === 'MFS' || this.questions.filing.answer === 'headofhousehold') && !this.questions.agisingle.answer && (this.params.formattedCurrencyToNumber(this.questions.income.answer) > this.getAmountByName('roth_sing_hoh_low_amt'))) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si su ingreso bruto ajustado modificado (AGI) es inferior a':'Please select if your household Modified Adjusted Gross Income (AGI) is less than';
      this.snackBar.openSnackBar(spoust_Msg +'$'+ this.getVariableValueByName('roth_sing_hoh_low_amt'), '', 'error');
      this.notifierService.notify('error', spoust_Msg +'$'+ this.getVariableValueByName('roth_sing_hoh_low_amt'));
    } else if (this.questions.filing.answer === 'MFJ' && !this.questions.agimarried.answer && ( (this.params.formattedCurrencyToNumber(this.questions.income.answer) + this.params.formattedCurrencyToNumber(this.questions.spouseincome.answer) ) > this.getAmountByName('roth_mar_jnt_low_amt'))) {
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione si su ingreso bruto ajustado modificado (AGI) es inferior a':'Please select if your household Modified Adjusted Gross Income (AGI) is less than';

      this.snackBar.openSnackBar(spoust_Msg +'$'+ this.getVariableValueByName('roth_mar_jnt_low_amt'), '', 'error');
      this.notifierService.notify('error', spoust_Msg +'$'+ this.getVariableValueByName('roth_mar_jnt_low_amt'));
    }  
    else { 
     
      if((this.questions.filing.answer === 'single' || this.questions.filing.answer === 'headofhousehold') && this.params.formattedCurrencyToNumber(this.questions.income.answer) <= this.getAmountByName('roth_sing_hoh_low_amt')){
        delete this.questions.agisingle.answer;
      }

      if((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS')  && (this.params.formattedCurrencyToNumber(this.questions.income.answer) + this.params.formattedCurrencyToNumber(this.questions.spouseincome.answer) ) <= this.getAmountByName('roth_mar_jnt_low_amt')){
        console.log("HERE");
        delete this.questions.agimarried.answer;
      }
     
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      this.ngZone.run(() => this.router.navigate(['children'])).then();
    }
  }
  getVariableValueByName(name: string) {
    let result: Variable;
    for (let i = 0; i < this.taxVariables.length; i++) {
      const element = this.taxVariables[i];
      if (element.name === name) {
        result = element;
      }
    }
    return result.value.toLocaleString('en-US', {minimumFractionDigits: 0});
  }
  getAmountByName(name : string){ 
    var amount = this.getVariableValueByName(name)
    amount = amount.replace(/,/g, "");
    return parseInt(amount)
  }
  
}
