import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { HomeComponent } from '../project/home/home.component';
import { SalesRepComponent } from '../project/home/salesRep-details/salesRep.component';

import { LoginComponent } from '../project/login/login.component';
import { UsersComponent } from '../project/users/users.component';
import { CompaniesComponent } from '../project/companies/companies.component';
import { ProfileComponent } from '../project/profile/profile.component';
import { VariablesComponent } from '../project/variables/variables.component';
import { QuestionsComponent } from '../project/questions/questions.component';
import { CompanyQuestionsComponent } from '../project/company-questions/company-questions.component';
import { Survey1Component } from '../project/survey1/survey1.component';
import { Survey2Component } from '../project/survey2/survey2.component';
import { Survey3Component } from '../project/survey3/survey3.component';
import { Survey4Component } from '../project/survey4/survey4.component';
import { Survey5Component } from '../project/survey5/survey5.component';
import { Survey6Component } from '../project/survey6/survey6.component';
import { Survey7Component } from '../project/survey7/survey7.component';
import { Survey8Component } from '../project/survey8/survey8.component';
import { Survey9Component } from '../project/survey9/survey9.component';
import { Survey10Component } from '../project/survey10/survey10.component';
import { SalserepresentiveComponent } from '../project/salserepresentive/salserepresentive.component';
import { SurveyContributionComponent } from '../project/survey-contribution/survey-contribution.component';
import { Survey11Component } from '../project/survey11/survey11.component';
import { Survey12Component } from '../project/survey12/survey12.component';
import { Survey13Component } from '../project/survey13/survey13.component';
import { Survey14Component } from '../project/survey14/survey14.component';
import { Survey15Component } from '../project/survey15/survey15.component';
import { Survey16Component } from '../project/survey16/survey16.component';
import { Survey17Component } from '../project/survey17/survey17.component';
import { Survey18Component } from '../project/survey18/survey18.component';
import { Survey19Component } from '../project/survey19/survey19.component';
import { SurveyCompleteComponent } from '../project/survey-complete/survey-complete.component';
import { CustomizableVariablesComponent } from '../project/customizable-variables/customizable-variables.component';
import { CompanyCvariablesComponent } from '../project/company-cvariables/company-cvariables.component';
import { SurveysComponent } from '../project/surveys/surveys.component';
import { ViewsurveyComponent } from '../project/viewsurvey/viewsurvey.component';
import { TermsComponent } from "../project/terms/terms.component";
import { RemoteSurveyComponent } from '../project/remote-survey/remote-survey.component';
import { SurveyReportsComponent } from '../project/survey-reports/survey-reports.component';
import { LifeComponent } from '../project/life/life.component';

import { LifeLicenseAssistBDComponent } from '../project/home/life-license-assist-bd/life-license-assist-bd.component';
import { LifeSecurityComponent } from '../project/home/life-security/life-security.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'remote', component: RemoteSurveyComponent },
  { path: 'home/saleRep', component: SalesRepComponent },
  { path: 'users', component: UsersComponent },
  { path: 'companies', component: CompaniesComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'variables', component: VariablesComponent },
  { path: 'questions', component: QuestionsComponent },
  { path: 'companyQuestions', component: CompanyQuestionsComponent },
  { path: 'general', component: Survey1Component },
  { path: 'employment', component: Survey2Component },
  { path: 'company', component: Survey3Component },
  { path: 'children', component: Survey4Component },
  { path: 'survey5', component: Survey5Component },
  { path: 'survey6', component: Survey6Component },
  { path: 'survey7', component: Survey7Component },
  { path: 'survey8', component: Survey8Component },
  { path: 'survey9', component: Survey9Component },
  { path: 'salesreps', component: SalserepresentiveComponent },
  { path: 'investments-roth', component: Survey10Component },
  { path: 'contribution', component: SurveyContributionComponent },
  { path: 'investments-trad', component: Survey11Component },
  { path: 'investments-ann', component: Survey12Component },
  { path: 'investments-cd', component: Survey13Component },
  { path: 'investments-mf', component: Survey14Component },
  { path: 'investments-cs', component: Survey15Component },
  { path: 'debt', component: Survey16Component },
  { path: 'taxrefund', component: Survey17Component },
  { path: 'inheritance', component: Survey18Component },
  { path: 'misc', component: Survey19Component },
  { path: 'complete', component: SurveyCompleteComponent },
  { path: 'cvariables', component: CustomizableVariablesComponent },
  { path: 'companyCvariables', component: CompanyCvariablesComponent },
  { path: 'surveys', component: SurveysComponent },
  { path: 'viewsurvey', component: ViewsurveyComponent },
  { path: 'termsandconditions', component: TermsComponent },
  { path: 'usage-reports', component: SurveyReportsComponent },

  { path: 'life-license', component: LifeLicenseAssistBDComponent },
  { path: 'life-security', component: LifeSecurityComponent },

  { path: 'life', component: LifeComponent },
  {
    path        : 'contacts',
      loadChildren: '../project/contacts/contacts.module#ContactsModule'
  },
  {
    path: '**',
    redirectTo: 'home'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
  declarations: []
})

export class AppRoutingModule { }
