import { Component, OnInit,ViewEncapsulation, Inject } from '@angular/core';
import { UserService } from '../../user.service';
import { Router } from '@angular/router';
import { User } from '../../user';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { FormControl, FormGroup } from '@angular/forms';
import { SurveyService } from 'app/survey.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
 
@Component({
  selector: 'app-salserepresentive',
  templateUrl: './salserepresentive.component.html',
  styleUrls: ['./salserepresentive.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // animations: 
  animations: [fuseAnimations,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SalserepresentiveComponent implements OnInit {


  // ends
  constructor(private _matDialog : MatDialog, private _fuseSidebarService: FuseSidebarService,public surveyService : SurveyService ,public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private userService: UserService, private router: Router) {
     // Configure the layout
    //  this._fuseConfigService.config = {
    //   layout: {
    //     navbar: {
    //       hidden: true
    //     },
    //      toolbar: {
    //       hidden: false,
    //       position:'above'
    //     },
    //     footer: {
    //       hidden: true
    //     },
    //     sidepanel: {
    //       hidden: true
    //     }
    //   }
    // }
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: false, //false,
          position : 'top'
        },
         toolbar: {
          hidden: false,
          position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }

    this.searchInput = new FormControl('');
    this.searchInput.valueChanges 
      .subscribe(searchText => {
          this.filterSurvey(searchText);
      });
   }

  isLoading = false;
  users = [];
  loggedInUser = new User();
  displayedColumns: string[] = ['clientInitials', 'createdAt', 'action'];
  displayedColumns_all: string[] = ['sr_info','clientInitials', 'createdAt', 'action'];  
  filterBy = 'all'
  searchInput: FormControl;
  selectedSalesRep: User; 
  surveys: any=[];
  originalArray: any=[];
 
  //not using right now
  hasSelectedSurveys = false;

  //expanded feature
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;


  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
    this.surveyService.getRVPSurveys(this.loggedInUser._id).subscribe((result) =>{
      this.surveys = result;
      this.selectedSalesRep = this.surveys[0].sr_info
      this.originalArray = result;
    })
    this.userService.getSalesUsers(this.loggedInUser._id).subscribe((result) => {
      this.users = result;
    }); 
  }

  ngOnDestroy() { 
    this.updateFuseConfig(true);
  }

  updateFuseConfig(status){
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: status,
          position : 'top'
        },
         toolbar: {
          hidden: false, 
          position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
  }
  // viewSurvey(survey) { 
  //   console.log('survey', survey) 
  //   if(survey.sr_info){
  //     this.selectedSalesRep = survey.sr_info
  //   } 
  //   localStorage.setItem('SALES_REP', JSON.stringify(this.selectedSalesRep));
  //   localStorage.setItem('SURVEY', JSON.stringify(survey));
  //   this.router.navigate(['viewsurvey']);
  // }
 
  open(survey) { 

    if (survey.sr_info) {
      this.selectedSalesRep = survey.sr_info
    }
    this.expandedElement = this.expandedElement == survey._id ? this.expandedElement = '' : this.expandedElement = survey._id;
  }

  changeFilter(filterBy){
    this.filterBy = filterBy;  
    this.surveyService.getRVPSurveys(this.loggedInUser._id).subscribe((result) =>{
      this.surveys = result;
      this.originalArray = result;
    })
  }

  fetchSurveys(user) {
    this.filterBy = user._id
    this.selectedSalesRep = user;
    this.surveyService.getSalesRepSurveys(this.selectedSalesRep._id).subscribe((result) => {
      console.log(result)
      this.surveys = result;
      this.originalArray = result; 
    });
  }
  
  filterSurvey(text){
    this.surveys = JSON.parse(JSON.stringify(this.originalArray)) 
    if(text == ''){
      return;
    } 
    var toFilterArray = this.surveys;
    this.surveys = toFilterArray.filter(x => { 
      if(x.clientInitials.toLowerCase().startsWith(text.toLowerCase())){
        console.log('match')
        return true
      }
      return false
    });
    console.log(this.surveys);
  }

  toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    } 

    viewSurvey(survey): void {
      
      if(survey.sr_info){
        this.selectedSalesRep = survey.sr_info
      } 
      localStorage.setItem('SALES_REP', JSON.stringify(this.selectedSalesRep));
      localStorage.setItem('SURVEY', JSON.stringify(survey));

      // const dialogRef = this._matDialog.open(ViewsurveyDialogComponent, {
      //   panelClass: 'date-form-dialog',
      //   data: {
      //     survey:survey,
      //     selectedSalesRep : this.selectedSalesRep
      //   }
      // });
      // dialogRef.afterClosed()
      //   .subscribe((response: any) => {
          
      //   });
    }
}


// @Component({
//   selector: 'app-viewsurveydialog-dialog',
//   templateUrl: 'viewsurveydialog.html',
//   styleUrls: ['viewsurveydialog.css']

// })
// export class ViewsurveyDialogComponent implements OnInit {

//     // survey related 
//     question1: boolean=true;
//     question2: boolean;
//     over54: boolean;
 
//   constructor(  public dialogRef: MatDialogRef<ViewsurveyDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any) { 
//       console.log(this.data)
//     }
//     ngOnInit() {
//       var survey = this.data.survey;
//       if (survey.questions['investments'].rothira || survey.questions['investments'].traditionalira || survey.questions['investments'].trust ||
//         survey.questions['lifeinsurance'].term || survey.questions['lifeinsurance'].group || survey.questions['lifeinsurance'].cashvalue) {
//         this.question2 = true;
//       }
//       // for survey 19
//       if (parseInt(survey.questions['age'].answer) > 54) {
//         this.over54 = true;
//       } else if (survey.questions['spouseage'] && parseInt(survey.questions['spouseage'].answer) > 54) {
//         this.over54 = true;
//       }
  
//     }
//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }