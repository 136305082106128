import { Component, OnInit, Inject, ViewChild,ViewEncapsulation, NgZone } from '@angular/core';
import { CompanyService } from '../../company.service';
import { Companies } from '../../companies';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalDirective } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import { DateTimeService } from './../../date-time.service';
import {CustomDateAdapter} from './../../customDateAdapter';
import { SurveyService } from './../../survey.service';

const moment = _moment;
export const MY_FORMATS = {
	parse: {
	  dateInput: 'MMM/DD/YYYY',
	},
	display: {
	  dateInput: 'MMM/DD/YYYY',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
  };
  
@Component({
  	selector: 'app-survey-reports',
  	templateUrl: './survey-reports.component.html',
  	styleUrls: ['./survey-reports.component.scss'],
  	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	providers : [ 
		DateTimeService,
		CustomDateAdapter, // so we could inject services to 'CustomDateAdapter'
		{ provide: DateAdapter, useClass: CustomDateAdapter }, // Parse MatDatePicker 
	]
})
export class SurveyReportsComponent implements OnInit {
	onlyMonth : boolean = true;
	startDate : any;
	endDate : any;
	date = moment();
	reports : any = [];
	survey_owner : string = 'all';
	isLoading : boolean = false;
	noDataFound : boolean = false;
	displayedColumns: string[] = ['companyInfo.name', 'rvpInfo.name', 'name', 'count'];

  	constructor(public snackBar: SnackbarService, public _fuseConfigService : FuseConfigService,private commpanyService: CompanyService, public dialog: MatDialog, private _dateTimeService : DateTimeService, private _suveryService : SurveyService, private _cd : NgZone) { 
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: false, position : 'above'
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

 	ngOnInit() {
		if(this.onlyMonth) {
			this._dateTimeService.format = 'MM-YYYY';
		} else {
			this._dateTimeService.format = 'MM-DD-YYYY';
		}
		this.fetchReports();
	}

	
	chosenYearHandler(normalizedYear: any) {
		const ctrlValue = this.date;
		ctrlValue.year(normalizedYear.year());
		this.date = ctrlValue
	}
	
	chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
		this._cd.run(()=>{
			let ctrlValue = this.date;
			ctrlValue.month(normalizedMonth.month());
			this.date = ctrlValue;
			datepicker.select(ctrlValue);
			datepicker.close();
		})
	}

	selectionChanged() {
		if(this.onlyMonth) {
			this._dateTimeService.format = 'MM-YYYY';
		} else {
			this._dateTimeService.format = 'MM-DD-YYYY';
		}
	}
	sortArrayOfObjects = (arr, key) => {
	    return arr.sort((a, b) => {

			// if(a.companyInfo != undefined && b.companyInfo != undefined && a.companyInfo.name != undefined && b.companyInfo.name != undefined) {
			// 	if (a.companyInfo.name < b. companyInfo.name) return -1;
			// 	if (a.companyInfo.name > b. companyInfo.name) return 1;
			// }

			// if(a.rvpInfo != undefined && b.rvpInfo != undefined && a.rvpInfo.name != undefined && b.rvpInfo.name != undefined) {
			// 	if (a.rvpInfo.name < b. rvpInfo.name) return -1;
			// 	if (a.rvpInfo.name > b. rvpInfo.name) return 1;
			// }

			if (a.count > b.count) return -1;
			if (a.count < b.count) return 1;

			// if (a.name < b.name) return -1;
			// if (a.name > b.name) return 1;

	    });
	};

	fetchReports() {
		this.noDataFound = false;
		this.isLoading = true;
		this._suveryService.getUsagesReports({
			"monthWise" :  (this.onlyMonth)?"yes":"no",
			"selectedMonth" : this.date,
			"startDate" :  this.startDate,
			"endDate" : this.endDate
		}).subscribe(data=>{
			this.isLoading = false;
			if(data && data.length>0) {

				if(this.survey_owner == 'all') {
					this.reports = data.map(item=>{
						//console.log("item :",item);
						item['count'] = item.surveys.length
						return item;
					});
				} else if(this.survey_owner=='without0') {
					this.reports = data.filter(x=>x.surveys.length>0).map(item=>{
						item['count'] = item.surveys.length
						return item;
					});
				} else if(this.survey_owner=='with0') {
					this.reports = data.filter(x=>x.surveys.length<1).map(item=>{
						item['count'] = item.surveys.length
						return item;
					});
				}
				this.reports = this.sortArrayOfObjects(this.reports, "count");

				debugger
			} else {
				this.reports = [];
				this.noDataFound = true;
			}
		}, error => {
			this.isLoading = false;
			console.log('Data Not found', error);
		})
	}

}
