import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-life-license-assist-bd',
  templateUrl: './life-license-assist-bd.component.html',
  styleUrls: ['./life-license-assist-bd.component.scss']
})
export class LifeLicenseAssistBDComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
