import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class ParamService {

  dataToPass:any;
  constructor(private router: Router) { }

  formatAmount(amount){
    if(amount){
      amount = this.formatAmountWithSign(amount)
      amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return '$'+amount
    } else {
      if(amount == 0){
        return '$ '+amount
      }
    }
  }
  formatAmountWithCommaOnly(amount){
    if(amount){ 
      amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return '$'+amount
    } else {
      if(amount == 0){
        return '$ '+amount
      }
    }
  }

  formatAmountWithSign(number:any){
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    if(number){ 
       // what tier? (determines SI symbol)
       var tier = Math.log10(number) / 3 | 0;
    
       // if zero, we don't need a suffix
       if(tier == 0) return number;
   
       // get suffix and determine scale
       var suffix = SI_SYMBOL[tier];
       var scale = Math.pow(10, tier * 3);
   
       // scale the number
       var scaled = number / scale;
   
       // format number and add suffix
       if(suffix == 'k'){
         return parseInt(scaled.toFixed(0)) + suffix;
       }
       return parseFloat(scaled.toFixed(1)) + suffix;
      // var x:any = ((Math.abs(num)/1000)).toFixed(1);
      // return Math.abs(num) > 999 ? (Math.sign(num)*(x)) + 'k' : Math.sign(num)*Math.abs(num)
    } 
  }
  

  formatDate(date){
    // date = new Date(date);
    // var day = date.getDate();
    // var monthIndex = date.getMonth()+1;
    // var year = date.getFullYear();
  
    // return  monthIndex + '/' +day  + '/' + year;

    let timezone =  localStorage.getItem('TIME_ZONE') ? JSON.parse(localStorage.getItem('TIME_ZONE')) : 'America/Chicago'
    let newZoneDate = "";
    if (date) {
      newZoneDate = moment(date).tz(timezone).format('MM/DD/YYYY hh:mm a')
    } 
     return newZoneDate;
  }

  validate(event){ 
    const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      } 
  }

  parseItem(amount){
    return parseInt(amount)
  }


  formattedCurrencyToNumber(x) {
    if(x && typeof(x)=='string'){ 
      x = x.replace (/,/g, ""); 
      return parseInt(x)
    } else {
      return x
    }
  }
 
  checkSurveyMode() {
    if (localStorage.getItem('SURVEY_MODE')) {
      var surveyModeActive = JSON.parse(localStorage.getItem('SURVEY_MODE'));
      if (surveyModeActive == false) {
        this.router.navigate(['home']);
      } else {
        if(localStorage.getItem('LANG') && localStorage.getItem('CLIENT_INITIALS')){
            // unfinished survey
        } else {
          this.router.navigate(['home']);
        }
      }
    }
  }
}
