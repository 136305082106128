import { Injectable, Inject, OnInit, Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service'

import { SwUpdate } from '@angular/service-worker';
import { SnackbarService } from './snackbar.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { HeaderService } from './header/header.service';

@Injectable({ providedIn: 'root' })

export class VersionCheckService {

  apiUrl = environment.apiUrl;
  currentVersion = environment.version;

  disabled: boolean = false;
  httpOptions = {
    headers: new HttpHeaders({

    })
  };
  constructor(private http: HttpClient, public headerService: HeaderService, public AS: AuthenticationService, public snacker: SnackbarService, private swUpdate: SwUpdate, public dialog: MatDialog) {

    console.log('VersionCheckService', this.swUpdate.isEnabled, this.currentVersion);
    if (!this.swUpdate.isEnabled) {
      console.log(' NOT ENABLED ')
    }

    this.swUpdate.available.subscribe(evt => {
      this.openDialog()
    });
    this.headerService.change.subscribe((surveyMode) => {
      console.log(surveyMode);
      if (surveyMode == true) {
        // Don't show update bar
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    })

  }


  public initVersionCheck(frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }

  checkVersion() {
    this.http.get(`${this.apiUrl}/version.json`, this.httpOptions)
      .subscribe((res: any) => {
        //this.openDialog()
        if (res) {
          if (res.version) {
            if (res.version != this.currentVersion) {
              if (this.disabled != true) {
                this.openDialog();
              }
            }
          }
        }
      },
        (err) => {
          console.log(err)
          // console.error(err, 'Could not get version');
        });
  }

  checkVersion2() {
    this.http.get(`${this.apiUrl}/version.json`, this.httpOptions)
      .subscribe((res: any) => {
        //this.openDialog()
        if (res) {
          if (res.version) {
            if (res.version != this.currentVersion) {
              if (this.disabled != true) {
                this.openDialog();
              }
            } else {
              this.snacker.openSnackBar('You have latest version!','','info');
            }
          }
        }
      },
        (err) => {
          console.log(err)
          // console.error(err, 'Could not get version');
        });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UpdateComponent, {
      width: '550px',
      data: ''
    });
    dialogRef.afterClosed().subscribe(result => {
      window.location.reload(true)
    });
  }

}


@Component({
  selector: 'update-app-dialog',
  templateUrl: 'update-dialog.html',
  styleUrls: ['update-dialog.css']

})
export class UpdateComponent implements OnInit {

  ngOnInit() {

  }
  constructor(
    public dialogRef: MatDialogRef<UpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close();
  }
}