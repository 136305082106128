import { Component, OnInit, ViewChild,ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../user.service';
import { User } from '../../user';
import { ModalDirective } from 'angular-bootstrap-md'
import { HeaderService } from '../../header/header.service';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'

//time zone
import * as moment from 'moment-timezone';
import * as ct from 'countries-and-timezones';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProfileComponent implements OnInit {
  @ViewChild('errorModal', { static: false }) public errorModal: ModalDirective;
  userDisplayName: string = '';
  isLoading = true;
  userName: string;
  email: string;
  initMaxLength = 3;
  pinMaxLength = 4;
  password: string;
  confirmPassword: string;
  editable: boolean = false;
  errorMsg: string = '';
  modalHeader: string = '';
  loggedInUser = new User();
  user: any = {}
  timeZones: any[];
  timeZone:any=''

  constructor(private cd: ChangeDetectorRef,public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService, private userService: UserService, private headerService: HeaderService) {
   
    
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')) {
      this.headerService.gearBox.emit(false); 
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
      this.userDisplayName = this.loggedInUser.name;
      // this.email = this.loggedInUser.email;
      // this.userName = this.loggedInUser.name;
      this.user = this.loggedInUser // assign to new object to avoid messing old code
      delete this.user.password;
      this.isLoading = false;
      this.getTimeZones()
    }
  } 
 
  ngOnDestroy(){ 
    this.headerService.gearBox.emit(true);
    // isHidden = true
  }

  changeTimeZone(newZone) {
    console.log(newZone);
    console.log(this.timeZone)
    //localStorage.setItem('TIME_ZONE',JSON.stringify(newZone))
  }

  getTimeZones() {
    this.timeZones = ct.getTimezonesForCountry('US'); 
   
    if(localStorage.getItem('TIME_ZONE')){
      
        this.timeZone = JSON.parse(localStorage.getItem('TIME_ZONE'));
       // this.cd.detectChanges();
      } else {
      this.timeZone = this.timeZones[0].name || moment.tz.guess();
    }
  }

  changeUserName() {
    var msg = ""
    if (!this.user.pincode) { msg = 'Pincode' }
    else {
      if (this.user.pincode.length < 4) {
        this.snackBar.openSnackBar('Pincode must have 4 digits', 'Okay', 'error');
        return false
      }
    }
    if (!this.user.initials) { msg = 'initials' }
    // if (!this.user.lastname) { msg = 'Last name' }
    if (!this.user.name) { msg = 'Full name' }
    if (msg) {
      this.snackBar.openSnackBar('Please enter your ' + msg + '.', 'Okay', 'error')
      return false;
    }
    this.isLoading = true;
    this.loggedInUser.name = this.user.name;
    this.loggedInUser.pincode = this.user.pincode;
    // this.loggedInUser.lastname = this.user.lastname;
    this.loggedInUser.initials = this.user.initials;
    localStorage.setItem('TIME_ZONE',JSON.stringify(this.timeZone))
    this.userService.editUser(this.loggedInUser).subscribe((result) => {
      localStorage.setItem('LOGGED_IN_USER', JSON.stringify(this.loggedInUser));
      this.isLoading = false;
      this.snackBar.openSnackBar('Your profile has been updated successfully.', 'Okay', 'info')
      this.headerService.login();
    });
  }

  changePassword() {
    if (!this.password) {
      this.snackBar.openSnackBar('Please enter your password.', 'Okay', 'error')
      return false;
    } else if (this.password && this.password.length < 6) {
      this.snackBar.openSnackBar('Password must be at least 6 characters length.', 'Okay', 'error')
      return false;
    } else if (this.password && this.password != this.confirmPassword) {
      this.snackBar.openSnackBar('Password and confirm password do not match.', 'Okay', 'error')
      return false;
    }
    if (this.password) {
      this.isLoading = true;
      this.loggedInUser.password = this.password;

      this.userService.editUser(this.loggedInUser).subscribe((result) => {
        localStorage.setItem('LOGGED_IN_USER', JSON.stringify(this.loggedInUser));
        this.isLoading = false;
        // this.modalHeader = 'Success';
        // this.errorMsg = 'Your password has been changed successfully';
        // this.errorModal.show();
        this.snackBar.openSnackBar('Your password has been changed successfully.', 'Okay', 'success')
      });
    }
  }

  keyPress(event: any, type: any) {
    if (type == 'name') {
      const pattern = /[A-Za-z]/
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
      this.user.initials = this.user.initials.toUpperCase();
    } else {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
}
