import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgOtpInputModule } from  'ng-otp-input';
import { ToolbarComponent,ConfirmationDialogComponent } from 'app/layout/components/toolbar/toolbar.component';
// Import your AvatarModule
import { AvatarModule } from 'ngx-avatar';
@NgModule({
    declarations: [
        ToolbarComponent,
        ConfirmationDialogComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatInputModule,
        MatToolbarModule,
        MatFormFieldModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,

        AvatarModule,
        NgOtpInputModule
        
    ],
    exports     : [
        ToolbarComponent
    ],
    entryComponents :[
        ConfirmationDialogComponent
    ]
})
export class ToolbarModule
{
}
