import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { Variable } from '../../variable';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../user';
import { Router } from '@angular/router';
import { CompanyService } from '../../company.service';
import { Companies } from '../../companies';
import { CvariablesService } from '../../cvariables.service';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service' 
import { ParamService } from '../../param.service'
 
@Component({
  selector: 'app-company-cvariables',
  templateUrl: './company-cvariables.component.html',
  styleUrls: ['./company-cvariables.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CompanyCvariablesComponent implements OnInit {

  isLoading = true;
  variable = new Variable();
  variables: Variable[] = [];
  searchText = '';
  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private cvariablesService: CvariablesService, private companyService: CompanyService, public dialog: MatDialog, private router: Router) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
          toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }
  displayedColumns: string[] = ['name', 'description', 'value', 'action'];
  loggedInUser = new User();
  company = new Companies();

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
    if (this.loggedInUser.companyKey) {
      this.companyService.getCompany(this.loggedInUser.companyKey).subscribe((comp) => {
        this.company = comp;
        this.getVariables();
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  reset() {
    this.variable = new Variable();
  }

  getVariables() {
    this.isLoading = true;
    this.cvariablesService.getCompanyCVariables(this.loggedInUser.companyKey).subscribe(result => {
      this.variables = result;
      this.isLoading = false;
    });
  }

  search() {
    this.isLoading = true;
    this.cvariablesService.searchCompanyCVariables(this.searchText, this.loggedInUser.companyKey).subscribe(result => {
      this.variables = result;
      this.isLoading = false;
    });
  }

  keyPressed(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.search();
    }
  }

  openToEdit(v) {
    const dialogRef = this.dialog.open(CompanyCVariablesDialogComponent, {
      width: '550px',
      data: v
    });

    dialogRef.afterClosed().subscribe(result => {
      this.variable = result;
      if (this.variable) {
        this.isLoading = true;
        let found = false;
        if (this.company.cvariables && this.company.cvariables.length > 0) {
          for (let i = 0; i < this.company.cvariables.length; i++) {
            if (this.company.cvariables[i] && this.company.cvariables[i]['name'] === this.variable.name) {
              found = true;
            }
          }
        }
        if (found) {
          this.companyService.editCompanyCVariable(this.variable, this.loggedInUser.companyKey).subscribe(c => {
            this.reset();
            this.getVariables();
            this.isLoading = false;
          });
        }
        else {
          this.company.cvariables.push({
            name: this.variable.name,
            description: this.variable.description,
            value: this.variable.value
          });
          this.companyService.addCompanyCVariable(this.variable, this.loggedInUser.companyKey).subscribe(c => {
            this.reset();
            this.getVariables();
            this.isLoading = false;
          });
        }
      }
    });
  }

}

@Component({
  selector: 'app-company-cvariables-dialog',
  templateUrl: 'companyCVariables-dialog.html',
  styleUrls: ['companyCVariables-dialog.css']

})
export class CompanyCVariablesDialogComponent implements OnInit {
  ngOnInit() {

  }
  constructor(
    public dialogRef: MatDialogRef<CompanyCVariablesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public variable: Variable) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.variable.name) {
      return false;
    }
    if (!this.variable.description) {
      return false;
    }
    if (!this.variable.value) {
      return false;
    }
    this.dialogRef.close(this.variable);
  }
}
