import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Cvariable } from '../../cvariables';
import { CvariablesService } from '../../cvariables.service';


// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'


@Component({
  selector: 'app-customizable-variables',
  templateUrl: './customizable-variables.component.html',
  styleUrls: ['./customizable-variables.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
}) 

export class CustomizableVariablesComponent implements OnInit {
  cvariable = new Cvariable();
  variables: Cvariable[] = [];
  searchText = '';
  isLoading = true;
  constructor(public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService,public dialog: MatDialog, private variablesService: CvariablesService) {
     // Configure the layout
     this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
   }
  displayedColumns: string[] = ['name', 'description', 'value', 'action'];
  ngOnInit() {
    this.getCvariables();
  }
  openDialog(): void {
    this.cvariable = new Cvariable();
    const dialogRef = this.dialog.open(CvariablesDialogComponent, {
      width: '550px',
      data: { variable: this.cvariable, variables: this.variables }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.cvariable = result;
      if (this.cvariable) {
        this.isLoading = true;
        this.variablesService.addCvariable(this.cvariable).subscribe(u => {
          this.isLoading = false;
          this.reset();
          this.getCvariables();
        });
      }
    });
  }
  openToEdit(cvariable: Cvariable) {
    const dialogRef = this.dialog.open(CvariablesDialogComponent, {
      width: '550px',
      data: cvariable
    });

    dialogRef.afterClosed().subscribe(result => {
      this.cvariable = result;
      if (this.cvariable) {
        this.isLoading = true;
        this.variablesService.editCvariable(this.cvariable).subscribe(c => {
          this.reset();
          this.getCvariables();
          this.isLoading = false;
        });
      }
    });
  }
  getCvariables() {
    this.isLoading = true;
    this.variablesService.getCvariables().subscribe(result => {
      this.variables = result;
      this.isLoading = false;
    });
  }
  saveVariable() {
    if (!this.cvariable.name) {
      alert('Please enter tax variable name');
      return false;
    }
    if (!this.cvariable.description) {
      alert('Please enter tax variable description');
      return false;
    }
    if (!this.cvariable.value) {
      alert('Please enter tx variable value');
      return false;
    }
    if (!this.cvariable._id) {
      this.variablesService.addCvariable(this.cvariable).subscribe(variable => {
        this.reset();
        this.getCvariables();
      });
    } else {
      if (this.cvariable) {
        this.variablesService.editCvariable(this.cvariable).subscribe(variable => {
          this.reset();
          this.getCvariables();
        });
      }
    }
  }

  addAllVars(){
    var all = [{"_id":"5cd489fb06214876cf13f4fe","name":"var_insurance_multiplier","description":"Insurance Multiplier","value":10,"createdAt":"2019-05-09T20:13:47.219Z"},{"_id":"5cd489eb06214876cf13f4fd","name":"var_number_of_debts","description":"Number of Debts","value":3,"createdAt":"2019-05-09T20:13:31.340Z"},{"_id":"5cd489ad06214876cf13f4f9","value":10000,"description":"Roth IRA - appropriate allocation - Spouse","name":"var_roth_ira_amt_spouse","createdAt":"2019-05-09T20:12:29.727Z"},{"_id":"5cd489a006214876cf13f4f8","name":"var_roth_ira_amt_you","description":"Roth IRA - appropriate allocation","value":10000,"createdAt":"2019-05-09T20:12:16.379Z"},{"_id":"5cd489df06214876cf13f4fc","name":"var_savings_account_balance","description":"Savings Account Balance","value":10000,"createdAt":"2019-05-09T20:13:19.077Z"},{"_id":"5cd4898c06214876cf13f4f7","name":"var_tax_refund_amt","description":"Tax Refund - Amount High","value":4000,"createdAt":"2019-05-09T20:11:56.929Z"},{"_id":"5cd489cf06214876cf13f4fb","name":"var_trad_ira_amt_spouse","description":"Traditional IRA - appropriate allocation amount - Spouse","value":10000,"createdAt":"2019-05-09T20:13:03.816Z"},{"_id":"5cd489be06214876cf13f4fa","name":"var_trad_ira_amt_you","description":"Traditional IRA - appropriate allocation amount","value":10000,"createdAt":"2019-05-09T20:12:46.058Z"}]
    for(let i in all){
      all[i]._id = null; 
        this.variablesService.addCvariable(all[i]).subscribe((res)=>{

        }) 
    }
  }
  reset() {
    this.cvariable = new Cvariable();
  }

  search() {
    this.isLoading = true;
    this.variablesService.searchCvariables(this.searchText).subscribe(result => {
      this.cvariable = result;
      this.isLoading = false;
    });
  }

  remove(cvariable: Cvariable) {
    if (confirm('Are you sure you want to delete this variable ')) {
      this.isLoading = true;
      this.variablesService.deleteCvariable(cvariable).subscribe((res) => {
        this.getCvariables();
      });
    }
  }

}
@Component({
  selector: 'app-cvariables-dialog',
  templateUrl: 'cvariables-Dialog.html',
})
export class CvariablesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CvariablesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public cvariable: Cvariable) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  validate() {
    if (!this.cvariable.name) {
      alert('Please enter variable name');
      return false;
    }
    if (!this.cvariable.description) {
      alert('Please enter variable description');
      return false;
    }
    if (!this.cvariable.value) {
      alert('Please enter variable number');
      return false;
    }
    this.dialogRef.close(this.cvariable);
  }

}