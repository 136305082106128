import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button'; 
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

/// imported from project
import { MatToolbarModule } from '@angular/material/toolbar';
import {
    MatSelectModule, MatCheckboxModule, MatDividerModule,MatSortModule,MatListModule, MatCardModule, MatInputModule,
    MatTableModule, MatChipsModule, MatProgressBarModule, MatDialogModule, MatSnackBarModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule
} from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginComponent } from 'app/project/login/login.component';
import { HomeComponent,TermsDialogComponent,ViewsurveyDialogComponent, RemotesurveyDialogComponent } from 'app/project/home/home.component';
import { CalendarEventFormDialogComponent } from 'app/project/home/date-form/date-form.component';
import { SalesRepComponent } from 'app/project/home/salesRep-details/salesRep.component';
import { UsersComponent, UsersDialogComponent, ResetDialogComponent } from './project/users/users.component';
import { CompaniesComponent, CompaniesDialogComponent, CompanyDetailsComponent } from 'app/project/companies/companies.component';
import { ProfileComponent } from 'app/project/profile/profile.component';
import { ProfileService } from 'app/project/profile/profile.service'
import { QuestionsComponent, QuestionsDialogComponent } from 'app/project/questions/questions.component';
import { VariablesComponent, VariablesDialogComponent } from 'app/project/variables/variables.component';
import { CustomizableVariablesComponent, CvariablesDialogComponent } from 'app/project/customizable-variables/customizable-variables.component';
import { SalserepresentiveComponent } from 'app/project/salserepresentive/salserepresentive.component';
import { CompanyQuestionsComponent } from 'app/project/company-questions/company-questions.component';
import { Survey1Component } from 'app/project/survey1/survey1.component'; 
import { Survey2Component } from 'app/project/survey2/survey2.component';
import { Survey3Component } from 'app/project/survey3/survey3.component';
import { Survey4Component } from 'app/project/survey4/survey4.component';
import { Survey5Component } from 'app/project/survey5/survey5.component';
import { Survey6Component } from 'app/project/survey6/survey6.component';
import { Survey7Component } from 'app/project/survey7/survey7.component';
import { Survey8Component } from 'app/project/survey8/survey8.component';
import { Survey9Component } from 'app/project/survey9/survey9.component';
import { Survey10Component } from 'app/project/survey10/survey10.component';
import { SurveyContributionComponent } from 'app/project/survey-contribution/survey-contribution.component';
import { Survey11Component } from 'app//project/survey11/survey11.component';
import { Survey12Component } from 'app/project/survey12/survey12.component';
import { Survey13Component } from 'app/project/survey13/survey13.component';
import { Survey14Component } from 'app/project/survey14/survey14.component';
import { Survey15Component } from 'app/project/survey15/survey15.component';
import { Survey16Component } from 'app/project/survey16/survey16.component';
import { Survey17Component } from 'app/project/survey17/survey17.component';
import { Survey18Component } from 'app/project/survey18/survey18.component';
import { Survey19Component } from 'app/project/survey19/survey19.component';
import { SurveyCompleteComponent } from 'app/project/survey-complete/survey-complete.component';  
import { CompanyCvariablesComponent } from 'app/project/company-cvariables/company-cvariables.component';
import { CompanyQuestionsDialogComponent } from 'app/project/company-questions/company-questions.component'
import { CompanyCVariablesDialogComponent } from 'app/project/company-cvariables/company-cvariables.component'
import { SurveysComponent, LocationDialogComponent } from 'app/project/surveys/surveys.component';
import { ViewsurveyComponent } from 'app/project/viewsurvey/viewsurvey.component';
import { ProfileAboutComponent1 } from 'app/project/profile/tabs/about/about.component';
import { TermsComponent } from "../app/project/terms/terms.component" 
import { HeaderComponent } from 'app/header/header.component';
import { FooterComponent } from 'app/footer/footer.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { NotifierModule } from 'angular-notifier';
// Import your AvatarModule
import { AvatarModule } from 'ngx-avatar';
import { AgmCoreModule } from '@agm/core';
import { MatRadioModule } from '@angular/material';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { ProjectDashboardService } from 'app/main/apps/dashboards/project/project.service';
import { AgGridModule } from 'ag-grid-angular';
import { OrderByPipe } from './rvp-order.pipe';
import { TimezonePipe } from './timezone.pipe'
import { FormatPipe } from './formatAmount.pipe'
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'
import { PwaService } from './pwa.service';
import { AuthModule } from './auth/auth.module';
import { UpdateComponent } from "../app/version-check.service"
import { RequestCache, RequestCacheWithMap } from './interceptors/request-cache.service';
import { AuthHeaderInterceptor } from './interceptors/header.interceptor';
import {MatGridListModule} from '@angular/material/grid-list';
import { RemoteSurveyComponent } from './project/remote-survey/remote-survey.component';
import { FuseConfirmDialogModule } from '@fuse/components';
import { SurveyReportsComponent } from './project/survey-reports/survey-reports.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTableExporterModule } from 'mat-table-exporter';
import { LifeComponent } from './project/life/life.component';
import { LifeLicenseAssistBDComponent } from './project/home/life-license-assist-bd/life-license-assist-bd.component';
import { LifeSecurityComponent } from './project/home/life-security/life-security.component';

// import { DateTimeService } from './date-time.service';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        OrderByPipe,
        TimezonePipe,
        FormatPipe,
        HomeComponent,
        TermsDialogComponent,
        SalesRepComponent,
        CalendarEventFormDialogComponent,
        UsersComponent,
        UsersDialogComponent,
        SalserepresentiveComponent,
        CompanyQuestionsComponent,
        Survey1Component,
        Survey2Component,
        Survey3Component,
        Survey4Component,
        Survey5Component,
        Survey6Component,
        Survey7Component,
        Survey8Component,
        Survey9Component,
        Survey10Component, 
        Survey11Component,
        Survey12Component,
        Survey13Component,
        Survey14Component,
        Survey15Component,
        Survey16Component,
        Survey17Component,
        Survey18Component, 
        Survey19Component,
        SurveyContributionComponent,
        SurveyCompleteComponent,
        CompanyCvariablesComponent,
        SurveysComponent,
        ViewsurveyComponent,
        LocationDialogComponent,
        ResetDialogComponent,
        CompaniesComponent,
        CompaniesDialogComponent,
        CompanyDetailsComponent,
        ProfileComponent,
        QuestionsComponent,
        QuestionsDialogComponent,
        VariablesComponent,
        VariablesDialogComponent,
        CustomizableVariablesComponent,
        CvariablesDialogComponent,
        ViewsurveyDialogComponent,
        RemotesurveyDialogComponent,
        CompanyQuestionsDialogComponent, 
        ProfileAboutComponent1,
        HeaderComponent,
        FooterComponent,
        CompanyCVariablesDialogComponent,
        UpdateComponent,
        TermsComponent,
        RemoteSurveyComponent,
        SurveyReportsComponent,
        LifeComponent,
        LifeLicenseAssistBDComponent,
        LifeSecurityComponent,
        LifeLicenseAssistBDComponent,
        LifeSecurityComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatGridListModule,
        FuseConfirmDialogModule,
        MatSlideToggleModule,
        MatTableExporterModule,
        // RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatSelectModule,
        MatFormFieldModule,
        MatCardModule,
        MatCheckboxModule,
        MatInputModule,
        MatChipsModule,
        MatProgressBarModule,
        MatTableModule,
        MatDialogModule,
        MatListModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMenuModule,
        MatTabsModule,
        MatDividerModule,
        MatSortModule,
        MatRadioModule,
        //MDB MODULE 
        MDBBootstrapModule.forRoot(),
        AgGridModule.withComponents([]),
        // routing
        AppRoutingModule, 
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBq1ZOesFBg8m-hHQyjJdRUG8xqz-fMieU'
        }),

        //avatar
        AvatarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        NotifierModule.withConfig({
            theme: 'material',
            position: {
                horizontal: {
                    position: 'left',
                    distance: 10
                },
                vertical: {
                    position: 'bottom',
                    distance: 65
                }
            },
            behaviour: {
                autoHide: 2000,
                stacking: 1
            }
        }),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseWidgetModule,
        FuseThemeOptionsModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        NgxChartsModule,
        ChartsModule,
        // App modules
        LayoutModule,
        AppStoreModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        AuthModule
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        UsersDialogComponent,
        ResetDialogComponent,
        CompaniesDialogComponent,
        CompanyDetailsComponent,
        QuestionsDialogComponent,
        VariablesDialogComponent,
        CvariablesDialogComponent,
        CompanyQuestionsDialogComponent,
        CalendarEventFormDialogComponent,
        TermsDialogComponent,
        CompanyCVariablesDialogComponent,
        ViewsurveyDialogComponent,
        RemotesurveyDialogComponent,
        LocationDialogComponent,
        UpdateComponent

    ],
    providers: [
        ProfileService,
        PwaService,
        ProjectDashboardService,
        OrderByPipe,
        TimezonePipe,
        FormatPipe, 
        // DateTimeService,
        AuthHeaderInterceptor, 
        [
            { provide: RequestCache, useClass: RequestCacheWithMap },
            { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true }
        ]
    ]
})
export class AppModule {
}
