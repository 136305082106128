import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { HeaderService } from 'app/header/header.service';
import { environment } from '../../environments/environment';
export interface RequestCacheEntry {
  url: string;
  response: HttpResponse<any>;
  lastRead: number;
}

export abstract class RequestCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
  abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void
}

 const maxAge = 3600000; // maximum cache age (ms) 60 mins
// const maxAge = 0; // maximum cache age (ms) 60 mins


@Injectable({
  providedIn: 'root'
})
export class RequestCacheWithMap implements RequestCache {
  apiUrl = environment.apiUrl;
  cache = new Map<string, RequestCacheEntry>();

  constructor(private headerService : HeaderService) {
      this.headerService.bustCache.subscribe((specificUrl)=>{
        if(specificUrl){
          this.removeCatchedApi(specificUrl)
        } else {
          this.deleteCache();
        }
          
      })
   }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const id_url ='';
    const url = req.urlWithParams;
    const cached = this.cache.get(url);
    if(this.apiUrl+'/api/users/rvp'==url) {
      return undefined;
    }

    if(this.apiUrl+'/api/surveys/byrvp/' ==url) {
      return undefined;
    }

    if (!cached) {
      return undefined;
    }

    // apiUrl

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired = isExpired ? 'expired ' : '';
    // return isExpired ? undefined : cached.response;
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    // remove expired cache entries
    // this code open ak
    // const expired = Date.now() - maxAge;
    // this.cache.forEach(entry => {
    //   if (entry.lastRead < expired) {
    //     this.cache.delete(entry.url);
    //   }
    // });
  }

  deleteCache(){
    'Fetching latest data'
    this.cache.forEach(entry => { 
        this.cache.delete(entry.url); 
    });
  }

  public removeCatchedApi(url){ 
     this.cache.forEach(entry => { 
       console.log(entry.url);
       if(entry.url == url){
        this.cache.delete(entry.url); 
       } 
    });
  }

}