import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service'; 
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
import {MatGridListModule} from '@angular/material/grid-list';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey2',
  templateUrl: './survey2.component.html',
  styleUrls: ['./survey2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey2Component implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = '';
  clientName = '';
  loggedInUser = new User();
  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    
    // Configure the layout
    this._fuseConfigService.config = {
     layout: {
       navbar: {
         hidden: true
       },
       toolbar: {
         hidden: false, position : 'above'
       },
       footer: {
         hidden: true
       },
       sidepanel: {
         hidden: true
       }
     }
   }
   }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode(); // redirect user to Home
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        })
      }
    } else {
      this.isLoading = false;
      this.router.navigate(['login']);
    }
  }

  back() {
    this.ngZone.run(() => this.router.navigate(['general'])).then();
  }

  next() {
    if (!this.questions.employment.employee && !this.questions.employment.selfemployed && !this.questions.employment.businessowner
      && !this.questions.employment.retired && !this.questions.employment.homemakerunemployed) {
        var spoust_Msg= this.selectedLanguage=='SP'?'Seleccione su situación de empleo':'Please select your employment status';

        this.snackBar.openSnackBar(spoust_Msg, '', 'error');
        // this.notifierService.notify('error', 'Please select your employment status');
    } else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && !this.questions.employmentspouse.employeespouse
      && !this.questions.employmentspouse.selfemployedspouse && !this.questions.employmentspouse.businessownerspouse &&
      !this.questions.employmentspouse.retiredspouse && !this.questions.employmentspouse.homemakerunemployedspouse) {
        var Msg= this.selectedLanguage=='SP'?'Por favor, seleccione el estatus de empleo de su cónyuge ':'Please select your spouse\'s employment status';
        this.snackBar.openSnackBar(Msg, '', 'error');
        // this.notifierService.notify('error', 'Please select your spouse\'s employment status');
    } else if ((this.questions.employment.employee || this.questions.employment.selfemployed || this.questions.employment.businessowner) && !this.questions.retiring.answer) {
      var Msg= this.selectedLanguage=='SP'?'Seleccione si se va a jubilar en los próximos 12 meses':'Please select if you are retiring within the next 12 months';
      this.snackBar.openSnackBar(Msg, '', 'error');
      // this.notifierService.notify('error', 'Please select if you are retiring within the next 12 months');
    } else if ((this.questions.employmentspouse.employeespouse || this.questions.employmentspouse.selfemployedspouse || this.questions.employmentspouse.businessownerspouse) && !this.questions.retiringspouse.answer) {
      var Msg= this.selectedLanguage=='SP'?'Seleccione si su cónyuge se va a jubilar en los próximos 12 meses':'Please select if your spouse is retiring within the next 12 months';
      this.snackBar.openSnackBar(Msg, '', 'error');
      // this.notifierService.notify('error', 'Please select if your spouse is retiring within the next 12 months');
    } else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      this.ngZone.run(() => this.router.navigate(['company'])).then();
    }
  }
 
}
