import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, NgZone, Optional, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionService } from '../../questions.service';
import { User } from '../../user';
import { UserService } from '../../user.service';
import { HeaderService } from '../../header/header.service';
import { SurveyService } from '../../survey.service';
import { NotifierService } from 'angular-notifier';
import * as zipcodes from 'zipcodes';
import { MouseEvent, GoogleMapsAPIWrapper, MarkerManager } from '@agm/core'


// Theme integration 
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { ParamService } from '../../param.service'

// table  
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CalendarEventFormDialogComponent } from 'app/project/home/date-form/date-form.component';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Sort } from '@angular/material/sort'
import { VariablesService } from 'app/variables.service';
import { CvariablesService } from 'app/cvariables.service';
import { PwaService } from 'app/pwa.service';
import { VersionCheckService } from 'app/version-check.service';

// term and condition 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [fuseAnimations,
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
			state('expanded', style({ height: '*', visibility: 'visible' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		])]
})
export class HomeComponent implements OnInit {
	@ViewChild('myGrid', { static: false }) eGridDiv: ElementRef;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;


	selectedday = JSON.parse(localStorage.getItem('filterByDate'));
	questions = [];
	isLoading: boolean = true;
	started: boolean = false;
	reporting: any = null;
	question: any;
	answer: string = '';
	current: string = '';
	type: string = '';
	options = [];
	selectedLanguage: string = '';
	hasNext: boolean = true;
	hasBack: boolean = false;
	showError: boolean = false;
	name: string;
	CiLength = 3
	zip: string;
	zipMaxLength = 5;
	unfinishedSurvey = false;
	userRole = '';
	loggedInUser = new User();
	columnDefsWidth: any = window.innerWidth >= 768 ? (window.innerWidth / 8) : 768 / 8;
	filterByDate = 'month' //'today'
	dateRange = {
		from: new Date(),
		to: new Date()
	}

	activeTab = 0;
	available_assets_rowData_footerRow: any;
	life_reports_rowDataFooterData: any;
	recruit_reports_rowData_FooterRow: any;
	// 
	// table 
	// 
	columnDefs: any =
		[
			{ headerName: 'Sales Rep Name', field: 'name', sortable: true, filter: true, width: this.columnDefsWidth, },
			{ headerName: 'No. of Apps', field: 'count', sortable: true, filter: true, width: this.columnDefsWidth, suppressSizeToFit: true },
			{ headerName: 'Income', field: 'income', sortable: true, filter: true, width: this.columnDefsWidth },
			{ headerName: 'IRA', field: 'ira', sortable: true, filter: true, width: this.columnDefsWidth },
			{ headerName: 'APE', field: 'ape', sortable: true, filter: true, width: this.columnDefsWidth },
			{ headerName: 'Inheritance', field: 'inheritance', sortable: true, width: this.columnDefsWidth, filter: true },
			{ headerName: 'Savings', field: 'savings', sortable: true, filter: true, width: this.columnDefsWidth },
			{ headerName: 'Annuity', field: 'annuity', sortable: true, filter: true, width: this.columnDefsWidth },
		]

	// OPPORTUNITY REPORTS 
	// Assets Available 
	available_assets_top_header = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];
	available_assets_table = ['date', 'agent', 'count', 'client', 'age', 'spouseage', 'kids', 'income', 'spouseincome', 'mortgage', 'savings', 'ape', 'ira', 'annuity', 'inheritance'];
	available_assets_rowData: any = []

	//LiFe Reports
	life_reports_top_header = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
	life_reports_table = ['date', 'agent', 'count', 'client', 'age', 'spouseage', 'kids', 'income', 'spouseincome', 'mortgage', 'li_client', 'li_spouse', 'cv_client', 'cv_spouse', 'wi_client', 'wi_spouse'];
	life_reports_rowData: any = []

	//Recruit Reports
	recruit_reports_top_header = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
	recruit_reports_table = ['date', 'agent', 'count', 'client', 'age', 'spouseage', 'kids', 'income', 'spouseincome', 'mortgage', 'interest']
	recruit_reports_rowData: any = []
	// OPPORTUNITY REPORTS ENDS

	rowData: any = []
	displayedColumns = ['sr', 'image', 'name', 'survey_count']//,'sr','image', 'count', 'income', 'ira', 'ape', 'inheritance', 'savings', 'annuity', 'action'];
	displayedColumns_total = ['date', 'agent', 'client', 'age', 'spouseage', 'income', 'spouseincome', 'savings', 'ape', 'ira', 'annuity', 'inheritance']


	dataSource: any = [];
	totalStats: any = [{}];
	dataTop5Source: any = [];
	barChartMessage: string = '';

	// all Surveys
	surveysDefs: any = [
		{
			headerName: 'Sales Rep', field: 'sr', sortable: true, filter: true, width: window.innerWidth / 4,
		},
		{
			headerName: 'Client Initials', field: 'ci', sortable: true, filter: true, width: window.innerWidth / 4,
		},
		{
			headerName: 'Submited at', field: 'date', sortable: true, filter: true, width: window.innerWidth / 4,
		},
		{
			headerName: 'Actions', field: 'action', sortable: true, filter: true, width: window.innerWidth / 4,
		}
	]

	surveys: any = [];
	// map styles
	mapStyle = []

	// date picker
	dialogRef: any;

	// charts
	projects: any[] = [];
	selectedProject: any;
	widgets: any;
	widget5: any = {};
	ch_data = []
	widget2: any = {
		chartType: 'bar',
		datasets: [
			{
				label: '',
				data: [0, 0, 0, 0, , 0]
			}
		],
		labels: ['IRA', 'APE', 'Savings', 'Annuity', 'Inheritance'],
		colors: [
			{
				borderColor: '#42a5f5',
				backgroundColor: '#42a5f5'
			}
		],
		options: {
			spanGaps: false,
			legend: {
				display: false
			},
			maintainAspectRatio: true,
			layout: {
				padding: {
				}
			},
			tooltips: {
				callbacks: {
					label: (tooltipItem, data) => {
						const value =
							data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
						return this.params.formatAmount(value);
					}
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						gridLines: {
							display: false,
						}
					}
				],
				yAxes: [
					{
						display: true,
						ticks: {
							min: 0,
							max: 50000,
							// callback : (()=>{  }),
							userCallback: (label, index, labels) => {
								// when the floored value is the same as the value we have a whole number
								return '$' + label
							},
						},
						gridLines: {
							display: false,
						},

					}
				],
				scaleLabel: {

				}
			}
		}
	};
	top5 = {
		options: {
			spanGaps: false,
			legend: {
				display: false
			},
			maintainAspectRatio: false,
			layout: {
				padding: {
					// top   : 24,
					// left  : 16,
					// right : 16,
					// bottom: 16
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						gridLines: {
							display: false,
						}
					}
				],
				yAxes: [
					{
						display: false,
						ticks: {
							min: 0,
							max: 50
						}
					}
				]
			}
		}
	}
	allmarkers: any = [];
	setSurveyMaxLimit: any = 5;

	//  NEW NAVIGATION RELATED VARS

	activeReport: any = 'activityReports';

	// ENDS
	// SalesRepresentatives
	users = [];
	sr_displayedColumns: string[] = ['clientInitials', 'createdAt', 'action'];
	sr_displayedColumns_all: string[] = ['sr_info', 'clientInitials', 'createdAt', 'action'];
	filterBy = 'all'
	searchInput: FormControl;
	selectedSalesRep: User;
	allSurveys: any = [];
	originalArray: any = [];
	//not using right now
	hasSelectedSurveys = false;
	// SalesRepresentatives ENDS

	//expanded feature
	isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
	expandedElement: any;


	constructor(private _fuseSidebarService: FuseSidebarService, public versionCheck: VersionCheckService, public pwaService: PwaService, public cvariablesService: CvariablesService, public variablesService: VariablesService, public location: LocationStrategy, private _formBuilder: FormBuilder, public headerService: HeaderService, public el: ElementRef, public params: ParamService, public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService, private questionService: QuestionService, private userService: UserService, private surveyService: SurveyService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
					position: 'top'
				},
				toolbar: {
					hidden: false,
					position: 'above'
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		}
		// SR List
		this.searchInput = new FormControl('');
		this.searchInput.valueChanges
			.subscribe(searchText => {
				this.filterSurvey(searchText);
			});

		// SR ENDs
		this.headerService.activeSection.subscribe((value) => {
			this.activeReport = value;
		})

		this.pwaService.onlineStatus.subscribe((status) => {
			if (status == true) {
				this.isLoading = true;
				if (this.loggedInUser.role == 'RVP') {
					this.headerService.bustCache.emit();
				}
				this.ngOnInit();
			}
		})

	}

	checkValue() {
		this.getRvpReports(this.selectedday);
		this.monthlyTop5List(this.selectedday)
	}

	surveyStartForm: FormGroup;
	ngOnInit() {
		
		localStorage.setItem('REMOTE_SURVEY', 'false');
		localStorage.setItem('SURVEY_MODE', 'false');
		this.headerService.surveyMode(false);
		this.headerService.remoteSurvey(false);
		if (navigator.onLine) {
			this.headerService.bustCache.emit();

		}

		this.surveyStartForm = this._formBuilder.group({
			name: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(/[a-zA-Z]+$/)]],
			zip: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(4), Validators.pattern(/^-?(0|[0-9]\d*)?$/), CustomValidators.validateZipcode]],
			terms: [false, [Validators.required, Validators.pattern('true')]]
		});
		if (localStorage.getItem('LOGGED_IN_USER')) {

			if (localStorage.getItem('SURVEY_MODE')) {
				var surveyModeActive = JSON.parse(localStorage.getItem('SURVEY_MODE'));
				if (surveyModeActive == true) {
					this.activeReport = 'activityReports';
					this.headerService.surveyMode(true);
					this.reporting = null;
				} else {
					this.reporting = false;
					localStorage.removeItem('SURVEY_MODE');
					this.headerService.surveyMode(false);
				}
			} else {
				this.reporting = false;
				this.headerService.surveyMode(false);
			}

			this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
			
			if (this.loggedInUser.role === 'SR') {
				
				this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe(result => {
					this.questions = result;
					this.isLoading = false;
					if (localStorage.getItem('LANG') && localStorage.getItem('CLIENT_INITIALS')) {
					
						this.unfinishedSurvey = true;
					}
				});
				if (localStorage.getItem('filterByDate')) {
					this.filterByDate = JSON.parse(localStorage.getItem('filterByDate'));
					if (localStorage.getItem('filterByDateRange')) {
						this.dateRange = JSON.parse(localStorage.getItem('filterByDateRange'));
					}
				};
				this.monthlyTop5List(this.filterByDate);
				this.getRvpReports(this.filterByDate);

			} else {
				if (this.loggedInUser.role == 'RVP') {
					/**
					 * New section to give RVP the option to conduct surveys
					 */

					if (!navigator.onLine) {
						this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe(result => {

							this.questions = result;
							this.isLoading = false;
							if (localStorage.getItem('LANG') && localStorage.getItem('CLIENT_INITIALS')) {
								this.unfinishedSurvey = true;
							}
						}, err => {
							console.log('Failed to get Questions ')
						});
					} else {
						this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe(result => {

							this.questions = result;
							this.isLoading = false;
							if (localStorage.getItem('LANG') && localStorage.getItem('CLIENT_INITIALS')) {
								this.unfinishedSurvey = true;
							}
						}, err => {
							console.log('Failed to get Questions ')
						});
					}
					// this.reporting = true; 
					/**
					*  Ends
					*/


					if (localStorage.getItem('filterByDate')) {
						this.filterByDate = JSON.parse(localStorage.getItem('filterByDate'));
						if (localStorage.getItem('filterByDateRange')) {
							this.dateRange = JSON.parse(localStorage.getItem('filterByDateRange'));
						}
					};
					this.monthlyTop5List(this.filterByDate)
					this.getRvpReports(this.filterByDate);
					this.userService.getSalesUsers(this.loggedInUser._id).subscribe(response => {
						this.totalStats[0].saleReps = {
							count: response.length
						}
					})

				} else {
					this.isLoading = false;
				}
			}
			this.mapStyle = [
				{
					'featureType': 'administrative',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#444444'
						}
					]
				},
				{
					'featureType': 'landscape',
					'elementType': 'all',
					'stylers': [
						{
							'color': '#f2f2f2'
						}
					]
				},
				{
					'featureType': 'poi',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'road',
					'elementType': 'all',
					'stylers': [
						{
							'saturation': -100
						},
						{
							'lightness': 45
						}
					]
				},
				{
					'featureType': 'road.highway',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'simplified'
						}
					]
				},
				{
					'featureType': 'road.arterial',
					'elementType': 'labels.icon',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'transit',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'water',
					'elementType': 'all',
					'stylers': [
						{
							'color': '#039be5'
						},
						{
							'visibility': 'on'
						}
					]
				}]
		} else {
			this.ngZone.run(() => this.router.navigate(['login'])).then();
		}

		// Check if User has come just after completing a survey
		// disable going back
		if (localStorage.getItem('preventBack')) {
			this.preventBackButton()
		}


		/**
		 * Subscribe to Survey Mode exit
		 */
		this.headerService.change.subscribe((surveyMode) => {

			if (surveyMode == false) // exiting survey mode
			{
				this.started = false;
				this.reporting = false;
				if (localStorage.getItem('LANG') && localStorage.getItem('CLIENT_INITIALS')) {
					this.unfinishedSurvey = true;
				} else {
					this.unfinishedSurvey = false;
				}
			} else {
				this.activeReport = 'activityReports';
			}

			this.updateFuseConfig(true) //hidden:true
		})

		/**
		 *  Start / Continue Survey from HEADER / MENU
		 */
		this.headerService.newSurvey.subscribe((continueSurvey) => {
			// in case user is on a different page
			this.router.navigate(['home']).then()
			this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
			if (continueSurvey == true) // continue survey
			{
				this.continue();
			} else {
				this.start();
			}

		})

		// cache (API) Variables for Offline Feature
		this.variablesService.getVariables().subscribe((variables) => { })
		if (this.loggedInUser.companyKey) {
			this.cvariablesService.getCompanyCVariables(this.loggedInUser.companyKey).subscribe((cvariables) => { })
		}


		//salesReps
		if (this.loggedInUser.role == 'RVP' || this.loggedInUser.role == 'SR') {
			if (this.loggedInUser.role == 'SR') {
				this.surveyService.getRVPSurveys(this.loggedInUser._id).subscribe((result) => {
					this.allSurveys = result;
					if (this.allSurveys[0] != undefined) {
						this.selectedSalesRep = this.allSurveys[0].sr_info
					}
					this.originalArray = result;
					this.userService.getSalesUsers(this.loggedInUser._id).subscribe((result) => {
						this.users = result;
						if (this.loggedInUser.role == 'SR') {
							this.changeFilter(null);
							this.fetchSurveys(this.users[0])
							// this.filterSurvey(null);
						}
					});
				});
			} else {
				this.surveyService.getRVPSurveys(this.loggedInUser._id).subscribe((result) => {
					this.allSurveys = result;
					this.selectedSalesRep = this.allSurveys[0].sr_info
					this.originalArray = result;
				});
				this.userService.getSalesUsers(this.loggedInUser._id).subscribe((result) => {
					this.users = result;
					if (this.loggedInUser.role == 'SR') {
						this.changeFilter(null);
						this.fetchSurveys(this.users[0])
						// this.filterSurvey(null);
					}
				});
			}

		}
	}


	updateFuseConfig(status) {
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: status,
					position: 'top'
				},
				toolbar: {
					hidden: false,
					position: 'above'
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		}
	}


	sortData(sort: Sort) {
		const data = this.dataTop5Source.slice();
		if (!sort.active || sort.direction === '') {
			this.dataTop5Source = data;
			return;
		}
	}

	sortRowData(sort: Sort, table) {
		const data = this[table].slice();
		if (!sort.active || sort.direction === '') {
			this[table] = data;
			return;
		}

		if (table == 'rowData') {
			this.rowData = data.sort((a, b) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'agent': return this.compare(a.count, b.count, isAsc);
					case 'date': return this.compare(a.survey.survey_info.date, b.survey.survey_info.date, isAsc);
					case 'age': return this.compare(a.survey.survey_info.age, b.survey.survey_info.age, isAsc);
					case 'spouseage': return this.compare(a.survey.survey_info.spouseage, b.survey.survey_info.spouseage, isAsc);
					case 'income': return this.compare(a.survey.income, b.survey.income, isAsc);
					case 'spouseincome': return this.compare(a.survey.spouseincome, b.survey.spouseincome, isAsc);
					case 'savings': return this.compare(a.survey.totalSavings, b.survey.totalSavings, isAsc);
					case 'ape': return this.compare(a.survey.totalAPE, b.survey.totalAPE, isAsc);
					case 'ira': return this.compare(a.survey.totalIRA, b.survey.totalIRA, isAsc);
					case 'annuity': return this.compare(a.survey.totalAnnuity, b.survey.totalAnnuity, isAsc);
					case 'inheritance': return this.compare(a.survey.inheritance, b.survey.inheritance, isAsc);
					default: return 0;
				}
			});


		} else if (table == 'recruit_reports_rowData') {


			this.recruit_reports_rowData = data.sort((a, b) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'agent': return this.compare(a.count, b.count, isAsc);
					case 'count': return this.compare(a.count, b.count, isAsc);
					case 'date': return this.compare(a.survey.survey_info.date, b.survey.survey_info.date, isAsc);
					case 'age': return this.compare(a.survey.survey_info.age, b.survey.survey_info.age, isAsc);
					case 'spouseage': return this.compare(a.survey.survey_info.spouseage, b.survey.survey_info.spouseage, isAsc);
					case 'income': return this.compare(a.survey.income, b.survey.income, isAsc);
					case 'spouseincome': return this.compare(a.survey.spouseincome, b.survey.spouseincome, isAsc);
				}
			})

		} else if (table == 'available_assets_rowData') {

			this.available_assets_rowData = data.sort((a, b) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'agent': return this.compare(a.count, b.count, isAsc);
					case 'count': return this.compare(a.count, b.count, isAsc);
					case 'date': return this.compare(a.survey.survey_info.date, b.survey.survey_info.date, isAsc);
					case 'age': return this.compare(a.survey.survey_info.age, b.survey.survey_info.age, isAsc);
					case 'spouseage': return this.compare(a.survey.survey_info.spouseage, b.survey.survey_info.spouseage, isAsc);
					case 'income': return this.compare(a.survey.income, b.survey.income, isAsc);
					case 'spouseincome': return this.compare(a.survey.spouseincome, b.survey.spouseincome, isAsc);
					case 'savings': return this.compare(a.survey.totalSavings, b.survey.totalSavings, isAsc);
					case 'ape': return this.compare(a.survey.totalAPE, b.survey.totalAPE, isAsc);
					case 'ira': return this.compare(a.survey.totalIRA, b.survey.totalIRA, isAsc);
					case 'annuity': return this.compare(a.survey.totalAnnuity, b.survey.totalAnnuity, isAsc);
					case 'inheritance': return this.compare(a.survey.inheritance, b.survey.inheritance, isAsc);
					default: return 0;
				}
			});

		} else if (table == 'life_reports_rowData') {


			this.life_reports_rowData = data.sort((a, b) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'agent': return this.compare(a.count, b.count, isAsc);
					case 'count': return this.compare(a.count, b.count, isAsc);
					case 'date': return this.compare(a.survey.survey_info.date, b.survey.survey_info.date, isAsc);
					case 'age': return this.compare(a.survey.survey_info.age, b.survey.survey_info.age, isAsc);
					case 'spouseage': return this.compare(a.survey.survey_info.spouseage, b.survey.survey_info.spouseage, isAsc);
					case 'income': return this.compare(a.survey.income, b.survey.income, isAsc);
					case 'spouseincome': return this.compare(a.survey.spouseincome, b.survey.spouseincome, isAsc);
					case 'li_client': return this.compare(a.survey.survey_info.termcoverageyou, b.survey.survey_info.termcoverageyou, isAsc);
					case 'li_spouse': return this.compare(a.survey.survey_info.termcoveragespouse, b.survey.survey_info.termcoveragespouse, isAsc);
					case 'wi_client': return this.compare(a.survey.survey_info.groupcoverageyou, b.survey.survey_info.groupcoverageyou, isAsc);
					case 'wi_spouse': return this.compare(a.survey.survey_info.groupcoverageyouspouse, b.survey.survey_info.groupcoverageyouspouse, isAsc);
					default: return 0;
				}
			});

		} else { // dataTop5Source
			this[table] = data.sort((a, b) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'name': return this.compare(a.sr_total.SR.name, b.sr_total.SR.name, isAsc);
					case 'survey_count': return this.compare(a.current.length, b.current.length, isAsc);
					default: return 0;
				}
			});
		}
	}

	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}


	defaultSort(prop: string = 'count') {
		// SRs with most number of Surveys at the top
		this.available_assets_rowData.sort((a, b) => { return b[prop] - a[prop] });
		this.rowData.sort((a, b) => { return b[prop] - a[prop] })
		this.recruit_reports_rowData.sort((a, b) => { return b[prop] - a[prop] })
		this.life_reports_rowData.sort((a, b) => { return b[prop] - a[prop] })
	}

	reportingMode() {
		this.updateFuseConfig(false) //hidden:false
		this.reporting = true;
		this.headerService.handleReporting(true, this.unfinishedSurvey);
	}
	preventBackButton() {
		history.pushState(null, null, location.href);
		this.location.onPopState(() => {
			history.pushState(null, null, location.href);
		})
	}

	ngOnDestroy() {
		localStorage.removeItem('preventBack');
		this.updateFuseConfig(true);
	}

	renderChart(data) {
		var limit = Math.max(...data)

		this.widget2 = {
			chartType: 'bar',
			datasets: [
				{
					label: '',
					data: data
				}
			],
			labels: ['IRA', 'Rollover', 'Savings', 'Annuity', 'Inheritance'],
			// labels: ['IRA', 'APE', 'SAV', 'ANN', 'INH'],
			colors: [
				{
					borderColor: '#42a5f5',
					backgroundColor: '#42a5f5'
				}
			],
			options: {
				spanGaps: false,
				legend: {
					display: false
				},
				maintainAspectRatio: true,
				layout: {
					padding: {
					}
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							const value =
								data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
							return this.params.formatAmountWithCommaOnly(value);
						}
					}
				},
				scales: {
					xAxes: [
						{
							display: true,
							gridLines: {
								display: false,
							}
						}
					],
					yAxes: [
						{
							display: true,
							ticks: {
								min: 0,
								max: limit,
								callback: (label, index, labels) => {
									return this.params.formatAmount(label);
								},
							},
							gridLines: {
								display: false,
							},

						}
					]
				}
			}
		};

		this.top5 = {
			options: {
				spanGaps: false,
				legend: {
					display: false
				},
				maintainAspectRatio: false,
				layout: {
					padding: {
						// top   : 24,
						// left  : 16,
						// right : 16,
						// bottom: 16
					}
				},
				scales: {
					xAxes: [
						{
							display: true,
							gridLines: {
								display: false,
							}
						}
					],
					yAxes: [
						{
							display: false,
							ticks: {
								min: 0,
								max: limit
							}
						}
					]
				}
			}
		}
	}

	startNow() {
		if (!this.name) {
			var _smsg=this.selectedLanguage=='SP'?'Por favor, indique los iniciales del cliente':'Please enter client initials';
			this.snackBar.openSnackBar(_smsg, '', 'error');
			this.notifierService.notify('error', _smsg);
			return false;
		}
		if (!this.zip) {
			var _smsg=this.selectedLanguage=='SP'?'No se encuentra el código postal indicado':'Cannot find entered Zipcode';
			this.snackBar.openSnackBar(_smsg, '', 'error');
			this.notifierService.notify('error', _smsg);
			return false;
		}
		if (this.zip.toString().length < 4) {
			var _smsg=this.selectedLanguage=='SP'?'El código postal debe tener al menos 4 dígitos':'Zip code should have at least 4 digits';
			this.snackBar.openSnackBar(_smsg, '', 'error');
			this.notifierService.notify('error', _smsg);
			return false;
		}

		var loc = zipcodes.lookup(this.zip);
		let addrs: any = '';
		if (loc) {
			addrs = {
				lat: loc.latitude,
				lng: loc.longitude
			}
			localStorage.setItem('CLIENT_LOC', JSON.stringify(addrs));
		}


		localStorage.setItem('CLIENT_INITIALS', this.name);
		localStorage.setItem('CLIENT_ZIP', this.zip);
		localStorage.setItem('LANG', this.selectedLanguage);
		

		// save brand new questions to local storage
		localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
		/*
		Survey mode removed from here
		*/
		this.ngZone.run(() => this.router.navigate(['general'])).then();
	}

	continue() {
		//set surveyMode 
		// localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
		this.checkQuestionforChange();
		this.activeReport = 'activityReports';
		this.headerService.surveyMode(true);
		localStorage.setItem('SURVEY_MODE', 'true');
		this.ngZone.run(() => this.router.navigate(['general'])).then();
	}

	start() {
		//this.selectLanguage('EN')
		
		this.versionCheck.checkVersion();

		if (!this.loggedInUser.pincode) {
			this.snackBar.openSnackBar('Please set a PINCODE from your profile before starting the survey', '', 'info')
			this.headerService.surveyMode(false);
			localStorage.removeItem('SURVEY_MODE');
			return false;
		};

		this.selectedLanguage='';

		localStorage.setItem('CLIENT_INITIALS', '');
		localStorage.setItem('CLIENT_LOC', '');
		localStorage.setItem('LANG', '');
		this.started = true;
		this.reporting = false;
		this.activeReport = 'activityReports';
		//set surveyMode 
		this.headerService.surveyMode(true);
		localStorage.setItem('SURVEY_MODE', 'true');

		this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));

		if (this.loggedInUser.companyKey) {
				
			this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe(result => {
				this.questions = result;
			});
		}
	
	}

	checkQuestionforChange()
	{
		this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
		if (this.loggedInUser.companyKey) {
		   var newQuest:any;
		   if (this.loggedInUser.companyKey) {
			    this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe(result => {
				   newQuest = result;
			    });
		    }
		   var oldQues=JSON.parse(localStorage.getItem('QUESTIONS'));
		   Object.keys(newQuest).map(function(key){  

			    oldQues[key].englishText= newQuest[key].englishText
			    oldQues[key].spanishText= newQuest[key].spanishText
		    }); 

		   if(oldQues){
		       localStorage.removeItem('QUESTIONS')

		       localStorage.setItem('QUESTIONS', JSON.stringify(oldQues));
		}
	}



	}





	selectLanguage(lang: string) {
		this.selectedLanguage = lang;
		
	}

	closeAlert() {
		this.showError = false;
	}

	keyPress(event: any, type: any, ev: any) {
		if (type == 'name') {
			this.name = event.toUpperCase();
			this.surveyStartForm.patchValue({ name: this.name });
		} else {
			if (event.errors) {
				if (event.errors.pattern) {
					ev.preventDefault()
				}
			} else {
				this.zip = event.value;
			}
		}
	}

	getSrReports(date) {

		if (navigator.onLine) {
			this.headerService.bustCache.emit('users/rvp');
		}

		localStorage.setItem('filterByDate', JSON.stringify(date));
		var postdata: any = {
			date: date,
			rvpId: this.loggedInUser._id,
			role: 'SR'
		}

		if (date == 'custom') {
			localStorage.setItem('filterByDateRange', JSON.stringify(this.dateRange));
			postdata.range = this.dateRange;
		}

		this.surveyService.getSRDialyStat(postdata).subscribe((res) => {
			this.totalStats[0].today = {
				count: res.length
			};
		}, (e) => {
			this.isLoading = false;
		});

		// For current week always 
		this.surveyService.getSRDialyStat({
			rvpId: this.loggedInUser._id,
			date: 'week'
		}).subscribe((res) => {
			this.totalStats[0].this_week = {
				count: res.length
			};
		}, (e) => {
			this.isLoading = false;
		});

		this.surveyService.getSalesRepSurveys(this.loggedInUser._id).subscribe((result) => {
			this.surveys = [];
			this.totalStats[0].overall = {
				count: result.length
			};

			for (let i in result) {
				this.surveys.push({
					ci: result[i].clientInitials,
					date: this.params.formatDate(result[i].createdAt),
					action: 'View Survey',
					sr: this.loggedInUser.name,
					allData: result[i]
				});
			}
		});

		this.userService.getRvpSurveys(postdata).subscribe((res) => {
			
			this.dataSource = [];
			this.rowData = [];
			if (res[0]) {
				this.totalStats[0] = {
					today: this.totalStats[0].today,
					this_week: this.totalStats[0].this_week,
					overall: this.totalStats[0].overall,
					saleReps: this.totalStats[0].saleReps,
					count: res[0].count,
					totalAPE: res[0].totalAPE,
					totalAnnuity: res[0].totalAnnuity,
					totalIRA: res[0].totalIRA,
					totalIncome: res[0].totalIncome,
					totalSpouseIncome: res[0].totalSpouseIncome,
					totalInheritance: res[0].totalInheritance,
					totalSavings: res[0].totalSavings,
				}
				var data = res[0].info;

				var source = [];
				for (let i in data) {
					if (data[i]._id) {
						var index = source.findIndex(x => x.sr_total._id == data[i]._id);
						if (index == -1) {
							source.push({
								sr_total: data[i],
								current: JSON.parse(JSON.stringify([data[i]]))
							});
						} else {
							source[index].sr_total.income = source[index].sr_total.income + data[i].income;
							source[index].sr_total.inheritance = source[index].sr_total.inheritance + data[i].inheritance;
							source[index].sr_total.spouseincome = source[index].sr_total.spouseincome + data[i].spouseincome;
							source[index].sr_total.totalAPE = source[index].sr_total.totalAPE + data[i].totalAPE;
							source[index].sr_total.totalAnnuity = source[index].sr_total.totalAnnuity + data[i].totalAnnuity;
							source[index].sr_total.totalIRA = source[index].sr_total.totalIRA + data[i].totalIRA;
							source[index].sr_total.totalSavings = source[index].sr_total.totalSavings + data[i].totalSavings;
							source[index].sr_total.totalincome = source[index].sr_total.totalincome + data[i].totalincome;
							source[index].current.push(JSON.parse(JSON.stringify(data[i])))
						}
					}
				}
				this.dataSource = source;

				for (let i in source) {

					for (let j in source[i].current) {
						this.rowData.push({
							name: source[i].sr_total.SR.name,
							count: source[i].current.length,
							survey: source[i].current[j],
							income: this.params.formatAmount(source[i].sr_total.income),
							ira: this.params.formatAmount(source[i].sr_total.totalIRA),
							ape: this.params.formatAmount(source[i].sr_total.totalAPE),
							inheritance: this.params.formatAmount(source[i].sr_total.inheritance),
							savings: this.params.formatAmount(source[i].sr_total.totalSavings),
							annuity: this.params.formatAmount(source[i].sr_total.totalAnnuity)
						})
					}
				}

				this.rowData.push({
					name: 'Total',
					allData: null,
					survey: { survey_info: { date: new Date() } },
					count: res[0].count,
					ape: this.params.formatAmount(res[0].totalAPE),
					annuity: this.params.formatAmount(res[0].totalAnnuity),
					ira: this.params.formatAmount(res[0].totalIRA),
					income: this.params.formatAmount(res[0].totalIncome),
					inheritance: this.params.formatAmount(res[0].totalInheritance),
					savings: this.params.formatAmount(res[0].totalSavings),
					spouseincome: this.params.formatAmount(res[0].totalSpouseIncome)
				})


				this.barChartMessage = '';
				//  ['IRA', 'APE', 'Savings', 'Annuity', 'Inheritance']
				this.renderChart([
					res[0].totalIRA,
					res[0].totalAPE,
					res[0].totalSavings,
					res[0].totalAnnuity,
					res[0].totalInheritance
				])
			} else {
				this.totalStats[0] = {
					this_week: this.totalStats[0].this_week,
					today: this.totalStats[0].today,
					overall: this.totalStats[0].overall,
					saleReps: this.totalStats[0].saleReps,
				}
				this.renderChart([0, 0, 0, 0, 0])
				this.barChartMessage = 'No data is available for this time period.'
			}
			this.isLoading = false;

		}, (e) => {
			this.isLoading = false;
		})
	}
	getRvpReports(date = 'month') {
		
		var d = new Date();
        var n = d.getMonth();

		if (this.loggedInUser.role == 'SR') {
			this.getSrReports(date)
			return false;
		}

		localStorage.setItem('filterByDate', JSON.stringify(date));
		var postdata: any = {
			date: date,
			rvpId: this.loggedInUser._id
		}

		if (date == 'custom') {
			localStorage.setItem('filterByDateRange', JSON.stringify(this.dateRange));
			postdata.range = this.dateRange
		}

		this.totalStats[0] = {
			today: 0,
			overall: 0,
			saleReps: 0,
			this_week: 0,
		}
		// selected Date/Range
		this.surveyService.getRvpDialyStat(postdata).subscribe((res) => {
			this.totalStats[0].today = {
				count: res.length
			}
		}, (e) => {
			this.isLoading = false;
		});

		// For current week always 
		this.surveyService.getRvpDialyStat({
			rvpId: this.loggedInUser._id,
			date: 'week'
		}).subscribe((res) => {
			this.totalStats[0].this_week = {
				count: res.length
			}
		}, (e) => {
			this.isLoading = false;
		});

		this.surveyService.getRVPSurveys(this.loggedInUser._id).subscribe((result) => {

			this.surveys = [];

			this.totalStats[0].overall = {
				count: result.length
			}
			for (let i in result) {
				this.surveys.push({
					ci: result[i].clientInitials,
					date: this.params.formatDate(result[i].createdAt),
					action: 'View Survey',
					sr: result[i].sr_info.name,
					allData: result[i]
				})
				if (result[i].lat) {
					this.allmarkers.push({
						lat: result[i].lat,
						long: result[i].long,
						clientInitials: result[i].clientInitials
					})
				}
			}
		});
		this.userService.getRvpSurveys(postdata).subscribe((res) => {

			this.dataSource = [];
			this.available_assets_rowData = [];
			this.life_reports_rowData = []
			this.recruit_reports_rowData = []
			this.rowData = [];
			if (res[0]) {
				this.totalStats[0] = {
					today: this.totalStats[0].today,
					this_week: this.totalStats[0].this_week,
					overall: this.totalStats[0].overall,
					saleReps: this.totalStats[0].saleReps,
					count: res[0].count,
					totalAPE: res[0].totalAPE,
					totalAnnuity: res[0].totalAnnuity,
					totalIRA: res[0].totalIRA,
					totalIncome: res[0].totalIncome,
					totalSpouseIncome: res[0].totalSpouseIncome,
					totalInheritance: res[0].totalInheritance,
					totalSavings: res[0].totalSavings,
					lifeInsuranceClient: res[0].totalTermcoverageyou,
					lifeInsuranceSpouse: res[0].totalTermcoveragespouse,
					workInsuranceClient: res[0].totalGroupcoverageyou,
					workInsuranceSpouse: res[0].totalGroupcoveragespouse,
					cashValue: res[0].totalCashvaluecoverageyou,
					cashValueSpouse: res[0].totalCashvaluecoveragespouse,
				}
				var data = res[0].info;

				var source = [];
				for (let i in data) {
					if (data[i]._id) {
						var index = source.findIndex(x => x.sr_total._id == data[i]._id);
						if (index == -1) {
							source.push({
								sr_total: data[i],
								current: JSON.parse(JSON.stringify([data[i]]))
							});
						} else {
							source[index].sr_total.income = source[index].sr_total.income + data[i].income;
							source[index].sr_total.inheritance = source[index].sr_total.inheritance + data[i].inheritance;
							source[index].sr_total.spouseincome = source[index].sr_total.spouseincome + data[i].spouseincome;
							source[index].sr_total.totalAPE = source[index].sr_total.totalAPE + data[i].totalAPE;
							source[index].sr_total.totalAnnuity = source[index].sr_total.totalAnnuity + data[i].totalAnnuity;
							source[index].sr_total.totalIRA = source[index].sr_total.totalIRA + data[i].totalIRA;
							source[index].sr_total.totalSavings = source[index].sr_total.totalSavings + data[i].totalSavings;
							source[index].sr_total.totalincome = source[index].sr_total.totalincome + data[i].totalincome;
							source[index].current.push(JSON.parse(JSON.stringify(data[i])))
							//  NEW FIELDS
							source[index].sr_total.groupcoveragespouse = source[index].sr_total.survey_info.groupcoveragespouse + data[i].survey_info.groupcoveragespouse;

						}
					}
				}
				this.dataSource = source
				for (let i in source) {

					for (let j in source[i].current) {
						this.available_assets_rowData.push({
							name: source[i].sr_total.SR.name,
							count: source[i].current.length,
							survey: source[i].current[j],
							income: this.params.formatAmount(source[i].sr_total.income),
							ira: this.params.formatAmount(source[i].sr_total.totalIRA),
							ape: this.params.formatAmount(source[i].sr_total.totalAPE),
							inheritance: this.params.formatAmount(source[i].sr_total.inheritance),
							savings: this.params.formatAmount(source[i].sr_total.totalSavings),
							annuity: this.params.formatAmount(source[i].sr_total.totalAnnuity)
						})

						this.life_reports_rowData.push({
							name: source[i].sr_total.SR.name,
							count: source[i].current.length,
							survey: source[i].current[j],
							income: this.params.formatAmount(source[i].sr_total.income),
							lifeInsuranceClient: '',
							lifeInsuranceSpouse: '',
							workInsuranceClient: '',
							workInsuranceSpouse: '',
							cashValue: '',
							cashValueSpouse: ''
						})

						this.recruit_reports_rowData.push({
							name: source[i].sr_total.SR.name,
							count: source[i].current.length,
							survey: source[i].current[j],
							income: this.params.formatAmount(source[i].sr_total.income)
						})

						this.rowData.push({
							name: source[i].sr_total.SR.name,
							count: source[i].current.length,
							survey: source[i].current[j],
							income: this.params.formatAmount(source[i].sr_total.income),
							ira: this.params.formatAmount(source[i].sr_total.totalIRA),
							ape: this.params.formatAmount(source[i].sr_total.totalAPE),
							inheritance: this.params.formatAmount(source[i].sr_total.inheritance),
							savings: this.params.formatAmount(source[i].sr_total.totalSavings),
							annuity: this.params.formatAmount(source[i].sr_total.totalAnnuity)
							// allData: source[i]
						})
					}
				}
				// To sort the SRs with most surveys and keep them at top (descending order)
				this.defaultSort();

				// Delay Pushing 'Total' to avoid 'Sorting' it at the top, as it will always have the
				// highest 'count' of survey
				setTimeout(() => {
					this.rowData.push({
						name: 'Total',
						allData: null,
						survey: { survey_info: { date: new Date() } },
						count: res[0].count,
						ape: this.params.formatAmount(res[0].totalAPE),
						annuity: this.params.formatAmount(res[0].totalAnnuity),
						ira: this.params.formatAmount(res[0].totalIRA),
						income: this.params.formatAmount(res[0].totalIncome),
						spouseincome: this.params.formatAmount(res[0].totalSpouseIncome),
						inheritance: this.params.formatAmount(res[0].totalInheritance),
						savings: this.params.formatAmount(res[0].totalSavings),
					})

					// this.available_assets_rowData.push({
					//   name: 'Total',
					//   allData: null,
					//   survey: { survey_info: { date: new Date() } },
					//   count: res[0].count,
					//   ape: this.params.formatAmount(res[0].totalAPE),
					//   annuity: this.params.formatAmount(res[0].totalAnnuity),
					//   ira: this.params.formatAmount(res[0].totalIRA),
					//   income: this.params.formatAmount(res[0].totalIncome),
					//   spouseincome: this.params.formatAmount(res[0].totalSpouseIncome),
					//   inheritance: this.params.formatAmount(res[0].totalInheritance),
					//   savings: this.params.formatAmount(res[0].totalSavings),
					// });

					this.available_assets_rowData_footerRow = {
						name: 'Total',
						allData: null,
						survey: { survey_info: { date: new Date() } },
						count: res[0].count,
						ape: this.params.formatAmount(res[0].totalAPE),
						annuity: this.params.formatAmount(res[0].totalAnnuity),
						ira: this.params.formatAmount(res[0].totalIRA),
						income: this.params.formatAmount(res[0].totalIncome),
						spouseincome: this.params.formatAmount(res[0].totalSpouseIncome),
						inheritance: this.params.formatAmount(res[0].totalInheritance),
						savings: this.params.formatAmount(res[0].totalSavings),
					};

					// this.life_reports_rowData.push({
					//   name: 'Total',
					//   allData: null,
					//   survey: {survey_info : {date : new Date()}},
					//   count: res[0].count, 
					//   income: this.params.formatAmount(res[0].totalIncome),
					//   spouseincome : this.params.formatAmount(res[0].totalSpouseIncome) ,
					//   lifeInsuranceClient : this.params.formatAmount(res[0].totalTermcoverageyou),
					//   lifeInsuranceSpouse : this.params.formatAmount(res[0].totalTermcoveragespouse),
					//   workInsuranceClient : this.params.formatAmount(res[0].totalGroupcoverageyou),  
					//   workInsuranceSpouse : this.params.formatAmount(res[0].totalGroupcoveragespouse),
					//   cashValue : this.params.formatAmount(this.totalStats[0].cashValue), 
					//   cashValueSpouse : this.params.formatAmount(this.totalStats[0].cashValueSpouse),
					// })

					this.life_reports_rowDataFooterData = {
						name: 'Total',
						allData: null,
						survey: { survey_info: { date: new Date() } },
						count: res[0].count,
						income: this.params.formatAmount(res[0].totalIncome),
						spouseincome: this.params.formatAmount(res[0].totalSpouseIncome),
						lifeInsuranceClient: this.params.formatAmount(res[0].totalTermcoverageyou),
						lifeInsuranceSpouse: this.params.formatAmount(res[0].totalTermcoveragespouse),
						workInsuranceClient: this.params.formatAmount(res[0].totalGroupcoverageyou),
						workInsuranceSpouse: this.params.formatAmount(res[0].totalGroupcoveragespouse),
						cashValue: this.params.formatAmount(this.totalStats[0].cashValue),
						cashValueSpouse: this.params.formatAmount(this.totalStats[0].cashValueSpouse),
					};



					this.recruit_reports_rowData_FooterRow = {
						name: 'Total',
						allData: null,
						survey: { survey_info: { date: new Date() } },
						count: res[0].count,
						income: this.params.formatAmount(res[0].totalIncome),
						spouseincome: this.params.formatAmount(res[0].totalSpouseIncome)
					};

					// this.recruit_reports_rowData.push({
					//   name: 'Total',
					//   allData: null,
					//   survey: {survey_info : {date : new Date()}},
					//   count: res[0].count, 
					//   income: this.params.formatAmount(res[0].totalIncome),
					//   spouseincome : this.params.formatAmount(res[0].totalSpouseIncome)
					// })

				})

				this.barChartMessage = ''
				//  ['IRA', 'APE', 'Savings', 'Annuity', 'Inheritance']
				this.renderChart([
					res[0].totalIRA,
					res[0].totalAPE,
					res[0].totalSavings,
					res[0].totalAnnuity,
					res[0].totalInheritance
				])
			} else {
				this.totalStats[0] = {
					today: this.totalStats[0].today,
					overall: this.totalStats[0].overall,
					saleReps: this.totalStats[0].saleReps,
					this_week: this.totalStats[0].this_week,
				}
				this.renderChart([0, 0, 0, 0, 0])
				this.barChartMessage = 'No data is available for this time period.'
			}
			this.isLoading = false;

		}, (e) => {
			this.isLoading = false;
		})

		// this.monthlyTop5List();
	}

	gotoSurvey(res) {
		console.log('res', res);
		this.activeReport = 'salesreps';
		let user = this.users.find(x => x.email == res.sr_total.SR.email);
		this.filterBy = user._id;
		this.changeFilter(null);
		this.fetchSurveys(user)
	}


	monthlyTop5List(date = 'month') {

		var postdata: any = {
			date: date,
			rvpId: this.loggedInUser._id
		}
		this.userService.getRvpSurveys(postdata).subscribe((res) => {
			
			this.dataTop5Source = [];
			if (res[0]) {
				var data = res[0].info;
				var source = [];
				for (let i in data) {
					if (data[i]._id) {
						var index = source.findIndex(x => x.sr_total._id == data[i]._id);
						if (index == -1) {
							source.push({
								sr_total: data[i],
								current: JSON.parse(JSON.stringify([data[i]]))
							});
						} else {
							source[index].sr_total.income = source[index].sr_total.income + data[i].income;
							source[index].sr_total.inheritance = source[index].sr_total.inheritance + data[i].inheritance;
							source[index].sr_total.spouseincome = source[index].sr_total.spouseincome + data[i].spouseincome;
							source[index].sr_total.totalAPE = source[index].sr_total.totalAPE + data[i].totalAPE;
							source[index].sr_total.totalAnnuity = source[index].sr_total.totalAnnuity + data[i].totalAnnuity;
							source[index].sr_total.totalIRA = source[index].sr_total.totalIRA + data[i].totalIRA;
							source[index].sr_total.totalSavings = source[index].sr_total.totalSavings + data[i].totalSavings;
							source[index].sr_total.totalincome = source[index].sr_total.totalincome + data[i].totalincome;
							source[index].current.push(JSON.parse(JSON.stringify(data[i])))
						}
					}
				}
				this.setSurveyMaxLimit = Math.max.apply(Math, source.map(function (o) { return o.current.length; })) + 5;
				this.dataTop5Source = source;
				this.sortRowData({ active: "count", direction: "desc" }, 'dataTop5Source');
			} else {
				
				this.dataTop5Source = []

			}

		}, (e) => {
			this.isLoading = false;
		})
	}


	pushData(data) {
		const rows = [];
		data.forEach(element => rows.push(element, { detailRow: true, element }));
		return rows;
	}

	addEvent(type, date) { // not used right now
		if (this.dateRange.to < this.dateRange.from) {
			this.dateRange.to = this.dateRange.from
		}
		this.dateRange = {
			from: type == 'from' ? date.value : this.dateRange.from,
			to: type == 'to' ? date.value : this.dateRange.to
		}
		if (type == 'to') {
			this.getRvpReports('custom');
		}
	}

	getDetails(details) {
		if (details.data.allData == null) {
			return false
		}
		this.params.dataToPass = details.data.allData;
		localStorage.setItem('rvpReports', JSON.stringify(details.data.allData));
		this.navigateTo('home/saleRep')
	}

	viewSurvey(salesRep) {
		if (salesRep.data.allData == null) {
			return false
		}

		var sr_info = salesRep.data.allData.sr_info ? salesRep.data.allData.sr_info : this.loggedInUser
		localStorage.setItem('SALES_REP', JSON.stringify(sr_info));
		localStorage.setItem('SURVEY', JSON.stringify(salesRep.data.allData));
		this.router.navigate(['viewsurvey']);
	}

	testFn(event) {

	}

	openModal(): void {
		this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
			panelClass: 'date-form-dialog',
			data: {
				action: 'new',
				date: this.dateRange.from
			}
		});
		this.dialogRef.afterClosed()
			.subscribe((response: any) => {
				if (!response) {
					return;
				}
				const newRange = response.getRawValue();
				this.dateRange = {
					from: newRange.start,
					to: newRange.end
				}
				this.isLoading = true;
				this.getRvpReports('custom');
			});
	}
	navigateTo(page) {
		this.ngZone.run(() => this.router.navigate([page])).then();
	}

	openTermsDialog(): void {
		this.dialogRef = this._matDialog.open(TermsDialogComponent, {
			panelClass: 'date-form-dialog',
			data: {
				action: 'new',
				date: this.dateRange.from
			}
		});
		this.dialogRef.afterClosed()
			.subscribe((response: any) => {

				this.surveyStartForm.patchValue({ terms: response });
			});
	}



	// Sales Representatives
	changeFilter(filterBy) {


		this.filterBy = filterBy;
		this.surveyService.getRVPSurveys(this.loggedInUser._id).subscribe((result) => {
			this.allSurveys = result;
			this.originalArray = result;

		})
	}

	fetchSurveys(user) {
		
		this.filterBy = user._id
		this.selectedSalesRep = user;
		this.surveyService.getSalesRepSurveys(this.selectedSalesRep._id).subscribe((result) => {

			this.allSurveys = result;
			this.originalArray = result;
		});
	}

	filterSurvey(text) {
		this.allSurveys = JSON.parse(JSON.stringify(this.originalArray))
		if (text == '') {
			return;
		}
		var toFilterArray = this.allSurveys;
		this.allSurveys = toFilterArray.filter(x => {
			if (x.clientInitials.toLowerCase().startsWith(text.toLowerCase())) {

				return true
			}
			return false
		});
	}

	toggleSidebar(name): void {
		this._fuseSidebarService.getSidebar(name).toggleOpen();
	}

	viewSurveyModal(survey): void {

		if (survey.sr_info) {
			this.selectedSalesRep = survey.sr_info
		}
		localStorage.setItem('SALES_REP', JSON.stringify(this.selectedSalesRep));
		localStorage.setItem('SURVEY', JSON.stringify(survey));

		const dialogRef = this._matDialog.open(ViewsurveyDialogComponent, {
			panelClass: 'date-form-dialog',
			// maxWidth: '100vw',
			// maxHeight: '100vh',
			// height: '100%',
			// width: '100%'
			data: {
				survey: survey,
				selectedSalesRep: this.selectedSalesRep
			}
		});
		dialogRef.afterClosed()
			.subscribe((response: any) => {

			});
	}

	remoteSurvey() {
		const dialogRef = this._matDialog.open(RemotesurveyDialogComponent, {
			panelClass: 'date-form-dialog',
			data: {
				user: this.loggedInUser
			}
		});
		dialogRef.afterClosed().subscribe((response: any) => {
		});
	}

	lifeLicensedAssist(){
		this.router.navigate(['life-license']);
     }

	lifeSecuritiesAssist(){this.router.navigate(['life-security']);}

}

export class CustomValidators extends Validators {

	// create a static method for your validation
	static validateZipcode(control: AbstractControl) {

		// first check if the control has a value
		if (control.value && control.value.length > 3 && control.value.length < 6) {

			// match the control value against the regular expression
			const matches = zipcodes.lookup(control.value);

			// if there are matches return an object, else return null.
			return matches ? null : { zipcode: "Can't find entered zipcode" };
		} else {
			return null;
		}
	}
}



@Component({
	selector: 'app-terms-dialog',
	templateUrl: 'terms.html',
	styleUrls: ['terms.css']

})
export class TermsDialogComponent implements OnInit {
	ngOnInit() {

	}
	constructor(public dialogRef: MatDialogRef<TermsDialogComponent>) { }

	onNoClick(): void {
		this.dialogRef.close(false);
	}
	agree() {
		this.dialogRef.close(true);
	}
}


@Component({
	selector: 'app-viewsurveydialog-dialog',
	templateUrl: 'view-survey/viewsurveydialog.html',
	styleUrls: ['view-survey/viewsurveydialog.css']

})
export class ViewsurveyDialogComponent implements OnInit {

	// survey related 
	question1: boolean = true;
	question2: boolean;
	over54: boolean;

	constructor(public dialogRef: MatDialogRef<ViewsurveyDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		console.log(this.data)
	}
	ngOnInit() {
		var survey = this.data.survey;
		if (survey.questions['investments'].rothira || survey.questions['investments'].traditionalira || survey.questions['investments'].trust ||
			survey.questions['lifeinsurance'].term || survey.questions['lifeinsurance'].group || survey.questions['lifeinsurance'].cashvalue) {
			this.question2 = true;
		}
		// for survey 19
		if (parseInt(survey.questions['age'].answer) > 54) {
			this.over54 = true;
		} else if (survey.questions['spouseage'] && parseInt(survey.questions['spouseage'].answer) > 54) {
			this.over54 = true;
		}

	}

	onNoClick(): void {
		this.dialogRef.close();
	}

}

@Component({
	selector: 'app-remotesurveydialog-dialog',
	templateUrl: 'remote-survey/remotesurveydialog.html',
	styleUrls: ['remote-survey/remotesurveydialog.css']

})
export class RemotesurveyDialogComponent implements OnInit {

	// survey related 
	question1: boolean = true;
	question2: boolean;
	over54: boolean;

	constructor(public dialogRef: MatDialogRef<RemotesurveyDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: SnackbarService) {
		console.log(this.data)
	}

	ngOnInit() {
		console.log('data', this.data);
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	copyText() {
		this.copyTextToClipboard('go.bdollarsmart.com');
	}

	fallbackCopyTextToClipboard(text) {
		var textArea = document.createElement("textarea");
		textArea.value = text;

		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
			console.log('Fallback: Copying text command was ' + msg);
		} catch (err) {
			console.error('Fallback: Oops, unable to copy', err);
		}
		document.body.removeChild(textArea);
	}

	copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text);
			return;
		}
		navigator.clipboard.writeText(text).then(() => {
			this.snackBar.openSnackBar('Link Copied', '', 'success');
		}, (err) => {
			this.snackBar.openSnackBar('Unable to copy text.', '', 'error');
		});
	}

}