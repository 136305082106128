import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey19',
  templateUrl: './survey19.component.html',
  styleUrls: ['./survey19.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey19Component implements OnInit {

  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = '';
  clientName = '';
  loggedInUser = new User();
  over54 = false;

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
     layout: {
       navbar: {
         hidden: true
       },
       toolbar: {
         hidden: false, position : 'above'
       },
       footer: {
         hidden: true
       },
       sidepanel: {
         hidden: true
       }
     }
   }
  }
  
  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER') || localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        if (parseInt(this.questions['age'].answer) > 54) {
          this.over54 = true;
        } else if (this.questions['spouseage'] && parseInt(this.questions['spouseage'].answer) > 54) {
          this.over54 = true;
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        })  ;
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'misc');
    this.ngZone.run(() => this.router.navigate(['inheritance'])).then();
  }
  
  changed(event){
    
    if(event.value == 'lottery'){
      var _smsg=  this.selectedLanguage=='SP'?"Eso no va ser una realidad 😎 Por favor, elige A o B arriba.":"That\'s not gonna happen 😎 Please choose either A or B above";
      this.snackBar.openSnackBar(_smsg,'','error');
      return
    }
    this.questions.shortfall[event.value] = true;
    if(event.value == 'reducefinancialgoals'){
      this.questions.shortfall.increasemyincome = false;
      this.questions.shortfall.lottery = false;
    } else {
      this.questions.shortfall.reducefinancialgoals = false;
      this.questions.shortfall.lottery = false;
    }
    
  }
  
  next() {
    if (!this.questions.powerofattorney.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, indique si actualmente tiene una Carta Poder sobre el patrimonio de alguien":"Please select if you currently have power of attorney over anyone\'s estate";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (!this.questions.healthinsurance.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si tiene seguro médico":"Please select if you have health insurance";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    }
    
    // else if (this.over54 && !this.questions.ltc.answer) {
    //   this.snackBar.openSnackBar('Please select if you have Long Term Care Insurance','','error');
    // } 
    
    else if (!this.questions.will.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, indique si tiene un testamento":"Please select if you have a will";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (this.questions.will.answer === '1' && !this.questions.willupdate.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si su testamento ha sido actualizado en los últimos 3 años":"Please select if your will has been updated in the past 3 years";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (!this.questions.compare_prices_ins.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, indique si a comparado precios en las polizas de seguro de casa y auto en los ultimo dos años":"Please select if you have compared prices on your auto and/or home insurance premiums in the past 2 years";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } else if (!this.questions.idt_protection.answer) {
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, seleccione si tiene protección contra el robo de identidad":"Please select if you have Identity Theft Protection";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    } 
    // else if(this.questions.employment.businessowner && !this.questions.liability_insurance_for_your_business.answer) {
    //   this.snackBar.openSnackBar('Please select if you have property and liability insurance for your Business','','error');
    // }
    // else if((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && this.questions.employmentspouse.businessownerspouse && !this.questions.liability_insurance_for_your_business_spouse.answer) {
    //   this.snackBar.openSnackBar('Please select if your spouse have property and liability insurance for your Business','','error');
    // } 
    // else if(this.questions.debt.mortgage && !this.questions.homeowner_insurance_premium_by_smart_security_system.answer) {
    //   this.snackBar.openSnackBar('Please select if your home protected by a smart security system.','','error');
    // }
    
    else if ((this.questions.shortfall.answer != 'reducefinancialgoals' &&  this.questions.shortfall.answer != 'increasemyincome') || this.questions.shortfall.answer == 'lottery') { 
      // && !this.questions.shortfall.lottery
      var spoust_Msg= this.selectedLanguage=='SP'?"Por favor, indique qué preferiria hacer si nuestro analisis identifica un déficit entre sus ingresos actualesy los objetivos financieros de su familia.":"Please select what would you rather do if our expanded analysis identifies a shortfall between your current income and your family\'s financial goals.";
      this.snackBar.openSnackBar(spoust_Msg,'','error');
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'misc');
      this.ngZone.run(() => this.router.navigate(['complete'])).then();
    }

  }

  IDTheftChange(event) {
    if(event.value) {
      // this.snackBar.openSnackBar('ID Theft Protection: Confirm the best value','','info');
    }
    // alert(event.value);
  }

}
