import { Component } from '@angular/core';

@Component({
    selector   : 'terms-and-conditions',
    templateUrl: './terms.component.html',
    styleUrls  : ['./terms.component.scss']
})
export class TermsComponent
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
