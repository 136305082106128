import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SurveyService } from '../../survey.service';
import { Router } from '@angular/router';
import { User } from '../../user';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service' 
import { ParamService } from '../../param.service'


@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SurveysComponent implements OnInit {

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private router: Router, private surveyService: SurveyService, public dialog: MatDialog) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }

  surveys = [];
  loggedInUser: User;
  selectedSalesRep: User;
  displayedColumns: string[] = ['clientInitials', 'createdAt', 'action'];

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
    this.selectedSalesRep = JSON.parse(localStorage.getItem('SALES_REP'));
    this.surveyService.getSalesRepSurveys(this.selectedSalesRep._id).subscribe((result) => {
         this.surveys = result;
    });
  }

  openDialog(survey): void {
    this.dialog.open(LocationDialogComponent, {
      width: '550px',
      data: {
        lat: survey.lat,
        long: survey.long
      }
    });
  }

  viewSurvey(survey) {
    localStorage.setItem('SURVEY', JSON.stringify(survey));
    this.router.navigate(['viewsurvey']);
  }

}

@Component({
  selector: 'app-location-dialog',
  templateUrl: 'location-Dialog.html',
})
export class LocationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}