import { Injectable, Output, EventEmitter } from '@angular/core'
import { PwaService } from 'app/pwa.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() remote: EventEmitter<boolean> = new EventEmitter();
  @Output() newSurvey: EventEmitter<boolean> = new EventEmitter();
  @Output() reporting: EventEmitter<boolean> = new EventEmitter();
  @Output() continueSurvey: EventEmitter<boolean> = new EventEmitter();
  @Output() gearBox: EventEmitter<boolean> = new EventEmitter();
  @Output() activeSection: EventEmitter<boolean> = new EventEmitter();
  @Output() bustCache: EventEmitter<any> = new EventEmitter();

  // added here cuz Header Service is available on almost all pages
  isOnline = true;
  //this.isOnline = navigator.onLine;

  constructor(public pwa : PwaService) {
      this.pwa.onlineStatus.subscribe((status)=>{ 
          this.isOnline = status;
          console.log(status)
      })
   }

  login() {
    this.change.emit(false); 
    // false for SurveyMode
  }

  surveyMode(enable) {
    this.change.emit(enable);
  }

  remoteSurvey(enable) {
    this.remote.emit(enable);
  }

  gearBoxSettings(enable){
    this.gearBox.emit(enable);
  }

  handleSurveys(data){
    this.newSurvey.emit(data);
  }

  handleReporting(startNew, continueSurvey){ 
    // option to start NEW SURVEY based on whether user is viewing reports or not
    this.reporting.emit(startNew); 

    // option to continue a SURVEY based on whether user is viewing reports or not
    this.continueSurvey.emit(continueSurvey)
  }

}
