import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service';
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-life',
  templateUrl: './life.component.html',
  styleUrls: ['./life.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LifeComponent implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  //selectedLanguage = 'EN';
  selectedLanguage = '';
  clientName = '';
  loggedInUser = new User();

  constructor(public params: ParamService, public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService, private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: false, position: 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
  }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();

      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');
      if (localStorage.getItem('QUESTIONS')) {
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if (this.questions.length == 0) {
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        } else {
          this.check();
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
          this.check();
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  check() {
    // alert(localStorage.getItem('PREV_URL'));
    // if ((this.questions.employment.employee && (this.questions.worksponsoredplan.answer === '1')) || (this.questions.employmentspouse.employeespouse && this.questions.worksponsoredplanspouse.answer === '1')) {
     
    // } else {
    //   if (localStorage.getItem('PREV_URL') === 'debt') {
    //     this.back();
    //   } else { 
    //     this.next(1);
    //   }
    // } 
  }

  back() {
    localStorage.setItem('PREV_URL', 'life');
    this.ngZone.run(() => this.router.navigate(['investments-cs'])).then();
    // survey7
  }

  next(bit=null) {
    if(bit==1) {
        localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
        localStorage.setItem('PREV_URL', 'life'); 
        this.ngZone.run(() => this.router.navigate(['debt'])).then();
    }

    // return false
    if (!this.questions.lifeinsurance.term && !this.questions.lifeinsurance.group && !this.questions.lifeinsurance.cashvalue && !this.questions.lifeinsurance.none) {
      var res_msg= this.selectedLanguage=='SP'? "Por favor, seleccione el tipo de seguro de vida que tiene":"Please select type of life insurance you have";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    } 
    else if (
      (this.questions.lifeinsurance.term || this.questions.lifeinsurance.group || this.questions.lifeinsurance.cashvalue) && this.questions.lifeinsurance.none) {
        var res_msg= this.selectedLanguage=='SP'? "Por favor, no seleccione 'Ninguno' para su seguro de vida":"Please unselect 'None' for your life insurance";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    } 
    else if (this.questions.filing.answer === 'MFJ' && !this.questions.lifeinsurancespouse.term && !this.questions.lifeinsurancespouse.group && !this.questions.lifeinsurancespouse.cashvalue && !this.questions.lifeinsurancespouse.none) {
      var res_msg= this.selectedLanguage=='SP'? "Por favor, seleccione el tipo de seguro de vida que tiene su cényuge":"Please select type of life insurance your spouse have";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    }
    else if ( this.questions.filing.answer === 'MFJ' &&
      (this.questions.lifeinsurancespouse.term || this.questions.lifeinsurancespouse.group || this.questions.lifeinsurancespouse.cashvalue) && this.questions.lifeinsurancespouse.none) {
        var res_msg= this.selectedLanguage=='SP'? "Por favor, desmarque 'Ninguno' para el seguro de vida de su coényuge":"Please unselect 'None' for your spouse's life insurance";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    }
   
    else if (this.questions.lifeinsurance.cashvalue && !this.questions.cashvaluecoverageyou.answer) {
      var res_msg= this.selectedLanguage=='SP'? "Por favor, indique cudnta cobertura tiene su péliza de su seguro de vida con ahorro":"Please enter how much coverage you have with your insurance with the savings account";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    } else if (this.questions.filing.answer === 'MFJ' && this.questions.lifeinsurancespouse.cashvalue && !this.questions.cashvaluecoveragespouse.answer) {
      var res_msg= this.selectedLanguage=='SP'? "Por favor, indique cudnta cobertura tiene la pdliza de seguro de vida con ahorro de su cényuge":"Please enter how much coverage your spouse has with his/her insurance with the savings account";
      this.snackBar.openSnackBar(res_msg, '', 'error');;
    } else if (this.questions.lifeinsurance.term && !this.questions.termcoverageyou.answer) {
      var res_msg= this.selectedLanguage=='SP'? "Por favor, indique cudnta cobertura tiene en su seguro vida a plazo":"Please enter how much coverage you have with your Term insurance";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    } else if (this.questions.lifeinsurancespouse.term && this.questions.filing.answer === 'MFJ' && !this.questions.termcoveragespouse.answer) {

      var res_msg= this.selectedLanguage=='SP'? "Por favor, indique cudnta cobertura tiene su cényuge en su seguro de vida a plazo":"Please enter how much coverage your spouse has with his/her Term insurance";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    } else if (this.questions.lifeinsurance.group && !this.questions.groupcoverageyou.answer) {
      var res_msg= this.selectedLanguage=='SP'? "Indique el monto de cobertura que su cényuge tiene en su seguro de vida de grupo (de trabajo)":"Please enter how much coverage you have with your Group (Work) insurance";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    } else if (this.questions.filing.answer === 'MFJ' && this.questions.lifeinsurancespouse.group && !this.questions.groupcoveragespouse.answer) {
      var res_msg= this.selectedLanguage=='SP'? "Indique el monto de cobertura que su cényuge tiene en su seguro de vida de grupo (de trabajo)":"Please enter how much coverage your spouse has with his/her Group (Work) insurance";
      this.snackBar.openSnackBar(res_msg, '', 'error');
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'life');

      // this.ngZone.run(() => this.router.navigate(['contribution'])).then();
      this.ngZone.run(() => this.router.navigate(['debt'])).then();
    }

  }
}
