import { Component, OnInit, NgZone } from '@angular/core';
import { VersionCheckService } from 'app/version-check.service';
import { HeaderService } from '../../header/header.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormBuilder, FormGroup,FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../user.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { SnackbarService } from '../../snackbar.service' 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TermsDialogComponent } from '../home/home.component';
import * as zipcodes from 'zipcodes';
export class CustomValidators extends Validators {

	// create a static method for your validation
	static validateZipcode(control: AbstractControl) {
  
	  // first check if the control has a value
	  if (control.value && control.value.length > 3 && control.value.length < 6) {
  
		// match the control value against the regular expression
		const matches = zipcodes.lookup(control.value);
  
		// if there are matches return an object, else return null.
		return matches ? null : { zipcode: "Can't find entered zipcode" };
	  } else {
		return null;
	  }
	}
}

@Component({
    selector: 'app-remote-survey',
    templateUrl: './remote-survey.component.html',
    styleUrls: ['./remote-survey.component.scss'],
})
export class RemoteSurveyComponent implements OnInit {
	selectedLanguage: string = '';
	confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
	surveyStartForm : FormGroup;
	surveyStartForm2 : FormGroup;
	questions  : any = [];
	showZipCodes : boolean = false;
	started: boolean = false;
	name: string;
	CiLength = 3
	zip: string;
	zipMaxLength = 5;
	private dialogRef: any;
	user : any;
	// Configure the layout
	  constructor(private versionCheck : VersionCheckService, private headerService : HeaderService, private _fuseConfigService : FuseConfigService, private _formBuilder : FormBuilder, private _user : UserService, private ngZone : NgZone, private router : Router, private location : LocationStrategy, private _snackBar : SnackbarService, public _matDialog: MatDialog) { }
  	ngOnInit() {
		this.surveyStartForm = this._formBuilder.group({
			remoteCode: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]]
		});
		this._fuseConfigService.config = {
			layout: {
			  	navbar: {
					hidden: true,
					position : 'top'
			  	},
			   	toolbar: {
					hidden: false,
					position : 'above'
			  	},
			  	footer: {
					hidden: true
			  	},
			  	sidepanel: {
					hidden: true
			  	}
			}
		}
		this.start();
		this.surveyStartForm2 = this._formBuilder.group({
			name: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(/[a-zA-Z]+$/)]],
			zip: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(4), Validators.pattern(/^-?(0|[0-9]\d*)?$/), CustomValidators.validateZipcode]],
			terms: [false,[Validators.required,Validators.pattern('true')]]
		});
	}
	  
	start() {
		//this.selectLanguage('EN')
		this.versionCheck.checkVersion();	
		localStorage.setItem('CLIENT_INITIALS', '');
		localStorage.setItem('CLIENT_LOC', '');
		localStorage.setItem('LANG', '');
		localStorage.setItem('REMOTE_SURVEY', 'true');
		this.headerService.surveyMode(true);
		localStorage.setItem('SURVEY_MODE', 'true');
	}

	selectLanguage(lang: string) {
		this.selectedLanguage = lang;
		localStorage.setItem('LANG', this.selectedLanguage);
	    this.showZipCodes = true;
	}

	itsValue(event) {
		this.surveyStartForm.controls.remoteCode.setValue(this.surveyStartForm.controls.remoteCode.value.toUpperCase())
	}

	exitApp() {
		this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to exit the survey? <br/>
		Your results will be lost.`;
		this.confirmDialogRef.componentInstance.confirmButtonText = 'Yes';
		this.confirmDialogRef.componentInstance.confirmCancelButton = 'No';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
				localStorage.setItem('REMOTE_SURVEY','false');
				localStorage.setItem('SURVEY_MODE','false');
				this.headerService.surveyMode(false);
				this.headerService.remoteSurvey(false);
				window.location.href = 'http://www.google.com';
            }
            this.confirmDialogRef = null;
		});
	}

	startNow() {
		if(this.surveyStartForm.valid) {
			this._user.verifyCode(this.surveyStartForm.value.remoteCode).subscribe(data=> {
				this.questions = data.questions;
				const questionsMap = {};
				for (let x = 0; x < data.questions.length; x++) {

					if(data.companyInfo != null && data.companyInfo.questions != undefined)
					{
					    for (let i = 0; i < data.companyInfo.questions.length; i++) {
						    const companyQuestion = data.companyInfo.questions[i];
						   if (companyQuestion.name === this.questions[x].name) {
							this.questions[x].englishText = companyQuestion.englishText;
							this.questions[x].spainText = companyQuestion.spainText;
						   }
					    }
				    }

					questionsMap[this.questions[x].name] = {
						name: this.questions[x].name,
						englishText: this.questions[x].englishText,
						spanishText: this.questions[x].spainText
					};
				}
				this.questions = questionsMap;
				localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
				this.started = true;
				//localStorage.setItem('LANG', this.selectedLanguage);
				//this.showZipCodes = true;
				this.user = data.user;
			}, error => {
				this._snackBar.openSnackBar(error.message, '', 'error');
			})
		}
	}

	startRemoteSurvey(){	
      localStorage.setItem('LANG', this.selectedLanguage);
	  this.showZipCodes = true;
   }

	preventBackButton() {
		history.pushState(null, null, location.href);
		this.location.onPopState(() => {
		  history.pushState(null, null, location.href);
		})
	}

	keyPress(event: any, type: any, ev: any) {
		if (type == 'name') {
		  this.name = event.toUpperCase();
		  this.surveyStartForm.patchValue({ name: this.name });
		} else {
		  if (event.errors) {
			if (event.errors.pattern) {
			  ev.preventDefault()
			}
		  } else {
			this.zip = event.value;
		  }
		}
	}

	openTermsDialog(): void {
		this.dialogRef = this._matDialog.open(TermsDialogComponent, {
		  panelClass: 'date-form-dialog',
		  data: {
			action: 'new',
			// date: this.dateRange.from
		  }
		});
		this.dialogRef.afterClosed()
		  .subscribe((response: any) => {
			
			 this.surveyStartForm.patchValue({ terms: response });
		  });
	  }

	startNow2() {
		if (!this.name) {
			var _smsg=this.selectedLanguage=='SP'?'Por favor, indique los iniciales del cliente':'Please enter client initials';
		  this._snackBar.openSnackBar(_smsg, '', 'error');
		//   this.notifierService.notify('error', 'Please enter client initials');
		  return false;
		}
		if (!this.zip) {
			var _smsg=this.selectedLanguage=='SP'?'No se encuentra el código postal indicado':'Cannot find entered Zipcode';
		  this._snackBar.openSnackBar(_smsg, '', 'error');
		//   this.notifierService.notify('error', 'Please enter zip code');
		  return false;
		}
		if (this.zip.toString().length < 4) {
			var _smsg=this.selectedLanguage=='SP'?'El código postal debe tener al menos 4 dígitos':'Zip code should have at least 4 digits';
		  this._snackBar.openSnackBar(_smsg, '', 'error');
		//   this.notifierService.notify('error', 'Zip code should have at least 4 digits');
		  return false;
		}
	
		var loc = zipcodes.lookup(this.zip);
		let addrs: any = '';
		if (loc) {
		  addrs = {
			lat: loc.latitude,
			lng: loc.longitude
		  }
		  localStorage.setItem('CLIENT_LOC', JSON.stringify(addrs));
		}
		localStorage.setItem('CLIENT_INITIALS', this.name);
		localStorage.setItem('CLIENT_ZIP', this.zip);
		localStorage.setItem('LANG', this.selectedLanguage);
		localStorage.setItem('REMOTE_USER', JSON.stringify(this.user));
		this.ngZone.run(() => this.router.navigate(['general'])).then();
		this.preventBackButton();
	}
	
}
