import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../user';

// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../../snackbar.service'
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ParamService } from '../../../param.service'

@Component({
  selector: 'app-salesRep',
  templateUrl: './salesRep.component.html',
  styleUrls: ['./salesRep.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fuseAnimations,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class SalesRepComponent implements OnInit {

  isLoading: boolean = true;
  userRole = '';
  loggedInUser = new User(); 
  displayedColumns = ['name', 'date', 'next','zip', 'age', 'kids', 'income', 'spouseincome', 'life', 'lifes',  'savings', 'inheritance', 'annuity', 'ape'];
  dataSource: any = []
  totalStats: any = [{}] 

  // new ag-tables
  columnDefs:any= [
    {headerName: 'Client Initials', field:'name',sortable: true, filter: true,width: 150,},
    {headerName: 'Date', field:'date',sortable: true, filter: true,width: 100,},
    {headerName: 'Next Survey', field:'next',sortable: true, filter: true,width: 100,},
    {headerName: 'Zip', field:'zip',sortable: true, filter: true,width: 150,}, 
    {headerName: 'Age', field:'age',sortable: true, filter: true,width: 150,}, 
    {headerName: 'Children', field:'kids',sortable: true, filter: true,width: 100,}, 
    {headerName: 'Income', field:'income',sortable: true, filter: true,width: 150,},  
    {headerName: 'Spouse Income', field:'spouseincome',sortable: true, filter: true,width: 150,},  
    {headerName: 'Life (c)', field:'life',sortable: true, filter: true,width: 150,}, 
    {headerName: 'Life (Spouse)', field:'lifes',sortable: true, filter: true,width: 150,},  
    {headerName: 'Savings', field:'savings',sortable: true, filter: true,width: 150,},
    {headerName: 'Inheritance', field:'inheritance',sortable: true, filter: true,width: 150,},
    {headerName: 'Annuity', field:'annuity',sortable: true, filter: true,width: 150,},
    {headerName: 'APE', field:'ape',sortable: true, filter: true,width: 150,}
  ]  
rowData:any = []

  constructor(public params : ParamService, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService, private router: Router, private ngZone: NgZone) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
  }

  ngOnInit() { 
    if (localStorage.getItem('LOGGED_IN_USER')) {
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
      this.rowData = []
      if(!this.params.dataToPass){
          if(localStorage.getItem('rvpReports')){
              this.dataSource= JSON.parse(localStorage.getItem('rvpReports')).current;
              var source = JSON.parse(localStorage.getItem('rvpReports')).current;
              console.log('1',JSON.parse(localStorage.getItem('rvpReports')))
              console.log(source)
              for(let i in source){ 
                this.rowData.push({
                  name: source[i].survey_info.clientInitials,
                  date: this.params.formatDate(source[i].survey_info.date),
                  next: ' ',
                  zip: source[i].survey_info.zip,
                  age: source[i].survey_info.spouseage != 'N/A' ? (source[i].survey_info.age + ' / ' + source[i].survey_info.age) : source[i].survey_info.age,
                  kids: source[i].survey_info.children == 0 ? 'No' : 'Yes',
                  income: this.params.formatAmount(source[i].income),
                  spouseincome: this.params.formatAmount(source[i].spouseincome),
                  life: source[i].survey_info.cashvaluecoverageyou != 'N/A' ? (this.params.formatAmount(source[i].survey_info.cashvaluecoverageyou)) : source[i].survey_info.cashvaluecoverageyou,
                  lifes: source[i].survey_info.cashvaluecoveragespouse != 'N/A' ? (this.params.formatAmount(source[i].survey_info.cashvaluecoveragespouse)) : source[i].survey_info.cashvaluecoveragespouse,
                  ira: this.params.formatAmount(source[i].totalIRA),
                  ape: this.params.formatAmount(source[i].totalAPE),
                  inheritance: this.params.formatAmount(source[i].inheritance),
                  savings: this.params.formatAmount(source[i].totalSavings),
                  annuity: this.params.formatAmount(source[i].totalAnnuity),
                }) 
              }  
          }
      } else {
        this.dataSource = this.params.dataToPass.current;
        console.log('2')
        var source = JSON.parse(localStorage.getItem('rvpReports')).current;
              for(let i in source){ 
                this.rowData.push({
                  name: source[i].survey_info.clientInitials,
                  date: this.params.formatDate(source[i].survey_info.date),
                  next: ' ',
                  zip: source[i].survey_info.zip,
                  age: source[i].survey_info.spouseage != 'N/A' ? (source[i].survey_info.age + ' / ' + source[i].survey_info.age) : source[i].survey_info.age,
                  kids: source[i].survey_info.children == 0 ? 'No' : 'Yes',
                  income: this.params.formatAmount(source[i].income),
                  spouseincome: this.params.formatAmount(source[i].spouseincome),
                  life: source[i].survey_info.cashvaluecoverageyou != 'N/A' ? (this.params.formatAmount(source[i].survey_info.cashvaluecoverageyou)) : source[i].survey_info.cashvaluecoverageyou,
                  lifes: source[i].survey_info.cashvaluecoveragespouse != 'N/A' ? (this.params.formatAmount(source[i].survey_info.cashvaluecoveragespouse)) : source[i].survey_info.cashvaluecoveragespouse,
                  ira: this.params.formatAmount(source[i].totalIRA),
                  ape: this.params.formatAmount(source[i].totalAPE),
                  inheritance: this.params.formatAmount(source[i].inheritance),
                  savings: this.params.formatAmount(source[i].totalSavings),
                  annuity: this.params.formatAmount(source[i].totalAnnuity),
                }) 
              }
      } 
    } else {
      this.ngZone.run(() => this.router.navigate(['login'])).then();
    }

  } 
  navigateTo(page) {
    this.ngZone.run(() => this.router.navigate([page])).then();
  }
}
