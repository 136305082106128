import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
// MDB Angular Free
import { ModalDirective } from 'angular-bootstrap-md';
import { AuthenticationService } from '../../authentication.service';
import { HeaderService } from '../../header/header.service';
import { UserService } from '../../user.service';
import { User } from '../../user';

// Theme integration
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations'; 
import { SnackbarService } from '../../snackbar.service'
import { PwaService } from 'app/pwa.service'
// Actual Auth
import { AuthService } from '../../auth/auth.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  @ViewChild('resetModal', { static: false }) public resetModal: ModalDirective;
  @ViewChild('errorModal', { static: false }) public errorModal: ModalDirective;
  email: string;
  password: string;
  email2: string;
  isLoading: boolean = false;
  errorMsg: string = '';
  modalHeader: string = '';

  //fuse integration
  loginForm: FormGroup;

  //onLine Status
  onLine:Boolean = navigator.onLine;

  constructor(public auth : AuthService, public pwa : PwaService,public snackBar: SnackbarService, private _fuseConfigService: FuseConfigService, private _formBuilder: FormBuilder, private userService: UserService, private router: Router, private ngZone: NgZone, private authenticationService: AuthenticationService, private headerService: HeaderService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }
  installPwa(): void {
    console.log('called prompt')
    this.pwa.promptEvent.prompt();
    // Wait for the user to respond to the prompt
    this.pwa.promptEvent.userChoice
    .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.pwa.promptEvent = null;
    });
}
  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('LOGGED_IN_USER')) {
      this.router.navigate(['home']);
    }
  }

  login() { 
    this.onLine = navigator.onLine; 
    if(!navigator.onLine){ 
        return;
    }
    this.isLoading = true;
    this.auth.login(this.loginForm.value.email, this.loginForm.value.password).subscribe((result) => {
      if (result) {
        localStorage.setItem('LOGGED_IN_USER', JSON.stringify(result.user));
        var local = moment.tz.guess() || 'America/Chicago';
        localStorage.setItem('TIME_ZONE', JSON.stringify(local));

        this.isLoading = false;
       
        this.ngZone.run(() => this.router.navigate(['home'])).then();
        this.headerService.login();
      } else {
        this.isLoading = false;
        this.modalHeader = 'Login error!';
        this.errorMsg = 'Incorrect email and/or password.';
        this.snackBar.openSnackBar(this.errorMsg,'Close', 'error');
        this.headerService.bustCache.emit();
      }
    },err=>{
      this.headerService.bustCache.emit();
    });
  }
  
  resetPassword() {
    this.resetModal.show();
  }

  validateEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  keyPressed(event) {
    if (event.which == 13 || event.keyCode == 13) {
      this.login();
    }
  }


  createDemoUser() {
    // name: { //first name
    //   type: String,
    //   required: true
    // },
    // lastname: { //added later
    //   type: String 
    // },
    // initials: { //added later
    //   type: String 
    // },
    // pincode: { //added later
    //   type: String 
    // },
    // rvp: {
    //   type: Object_id
    // },
    // ca: {
    //   type: Object_id
    // },
    // email: {
    //   type: String,
    //   required: true,
    //   unique: true,
    //   // Regexp to validate emails with more strict rules as added in tests/users.js which also conforms mostly with RFC2822 guide lines
    //   //match: [/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter a valid email'],
    // },
    // password: {
    //   type: String,
    //   required: true
    // },
    // createdAt: {
    //   type: Date,
    //   default: Date.now
    // },
    // role: {
    //   type: String,
    //   required: true
    // },
    // companyKey: {
    //   type: Object_id//String
    // }
    var user = new User();
    user = {
      _id: null,
      name: 'honey_test',
      lastname: 'kumar', 
      pincode: '1234',
      initials : 'HK',
      email: 'honey.kumar@technocratshorizons.com',
      role: 'SA',
      password: '12345',
      companyKey: null,
      rvp: null,
      ca : null
    }
    this.authenticationService.register(user).subscribe(u => {
    });
  }

}


