export class User {
  _id: string;
  name: string;
  email: string;
  password: string;
  role: string;
  companyKey: string;
  rvp: string;
  ca : string; 
  // added later
  lastname: string;
  pincode: string;
  initials: string;
  // remoteCode: string;
}
