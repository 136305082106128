import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl = environment.apiUrl;
  options:any;
  constructor(private http: HttpClient) { }

  addAuth(){
    let headers = new HttpHeaders({'Authorization': JSON.parse(localStorage.getItem('AuthToken'))}) 
    return this.options = { headers : headers }
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/users/login`, {
      email,
      password
    });
  }

  // to by Pass in case
  register(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/users/login`, data);
  }
 
  logout(): void {
    localStorage.clear();
    //localStorage.removeItem('LANG');
  }
 
}
