import { Component, OnInit, NgZone,ViewEncapsulation } from '@angular/core';
import { QuestionService } from '../../questions.service'; 
import { Router } from '@angular/router';
import { User } from '../../user';
import { NotifierService } from 'angular-notifier';
 
// Theme integration 
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { SnackbarService } from '../../snackbar.service'
import { MatDialog } from '@angular/material/dialog';
import { ParamService } from '../../param.service'

@Component({
  selector: 'app-survey9',
  templateUrl: './survey9.component.html',
  styleUrls: ['./survey9.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Survey9Component implements OnInit {
  questions;
  showError = false;
  isLoading = true;
  selectedLanguage = 'EN';
  clientName = '';
  loggedInUser = new User();

  constructor(public params : ParamService,public _matDialog: MatDialog, public snackBar: SnackbarService, public _fuseConfigService: FuseConfigService,private questionService: QuestionService, private router: Router, private ngZone: NgZone, private notifierService: NotifierService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
         toolbar: {
          hidden: false, position : 'above'
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }
   }

  ngOnInit() {
    if (localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER')) {
      this.params.checkSurveyMode();
      
      this.loggedInUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER')|| localStorage.getItem('REMOTE_USER'));
      this.selectedLanguage = localStorage.getItem('LANG');
      this.clientName = localStorage.getItem('CLIENT_INITIALS');

     
      if (localStorage.getItem('QUESTIONS')) {
        
        this.questions = JSON.parse(localStorage.getItem('QUESTIONS'));
        if(this.questions.length == 0){
          this.ngZone.run(() => this.router.navigate(['home'])).then();
        }
        this.isLoading = false;
      } else {
        this.questionService.getQuestionsMap(this.loggedInUser.companyKey).subscribe((result) => {
          this.questions = result;
          this.isLoading = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  back() {
    localStorage.setItem('PREV_URL', 'survey9');
    this.ngZone.run(() => this.router.navigate(['contribution'])).then();
    // contribution
  }

  next() {
    if (!this.questions.investments.rothira && !this.questions.investments.traditionalira && !this.questions.investments.annuity
      && !this.questions.investments.cd && !this.questions.investments.trust && !this.questions.investments.stocks
      && !this.questions.investments.bonds && !this.questions.investments.other && !this.questions.investments.none && !this.questions.investments.noidea && !this.questions.investments.mutualfund) {
        var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione otras inversiones que tenga':'Please select other investments you have';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error');
      this.notifierService.notify('error', spoust_Msg);
    } else if ((this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') && !this.questions.investmentsspouse.rothiraspouse
      && !this.questions.investmentsspouse.traditionaliraspouse && !this.questions.investmentsspouse.annuityspouse
      && !this.questions.investmentsspouse.cdspouse && !this.questions.investmentsspouse.trustspouse && !this.questions.investmentsspouse.stocksspouse
      && !this.questions.investmentsspouse.bondsspouse && !this.questions.investmentsspouse.otherspouse && !this.questions.investmentsspouse.nonespouse
      && !this.questions.investmentsspouse.old401kspouse && !this.questions.investmentsspouse.noideaspouse && !this.questions.investmentsspouse.mutualfundspouse) {
        var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, seleccione otras inversiones que tenga su cónyuge':'Please select other investments your spouse has';
        this.snackBar.openSnackBar(spoust_Msg,'', 'error');
      this.notifierService.notify('error', spoust_Msg);
    } else if (
      this.questions.investments.none &&
     (this.questions.investments.rothira || this.questions.investments.traditionalira || this.questions.investments.annuity
      || this.questions.investments.cd || this.questions.investments.trust || this.questions.investments.stocks
      || this.questions.investments.bonds || this.questions.investments.other || this.questions.investments.noidea || this.questions.investments.mutualfund
    )
    ){
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, desmarque "Ninguno"':'Please unselect "None"';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error');
    }
    else if (
      (this.questions.filing.answer === 'MFJ' || this.questions.filing.answer === 'MFS') 
      &&
      this.questions.investmentsspouse.nonespouse &&
     (this.questions.investmentsspouse.traditionaliraspouse || this.questions.investmentsspouse.annuityspouse
      || this.questions.investmentsspouse.cdspouse|| this.questions.investmentsspouse.trustspouse || this.questions.investmentsspouse.stocksspouse
      || this.questions.investmentsspouse.bondsspouse || this.questions.investmentsspouse.otherspouse || this.questions.investmentsspouse.rothiraspouse
      || this.questions.investmentsspouse.old401kspouse || this.questions.investmentsspouse.mutualfundspouse)
    ){
      var spoust_Msg= this.selectedLanguage=='SP'?'Por favor, no seleccione "Ninguno” para su cOnyuge':'Please unselect "None" for your spouse';
      this.snackBar.openSnackBar(spoust_Msg,'', 'error');
    }
    else {
      // save it then go next
      localStorage.setItem('QUESTIONS', JSON.stringify(this.questions));
      localStorage.setItem('PREV_URL', 'survey9');
      this.ngZone.run(() => this.router.navigate(['investments-roth'])).then();
    }

  }
}
